export const DictCiStatus = {
    "id_cad":{
        "label":"ID DA Ci",
        "type":"text"
    },
    "id_status":{
        "label":"ID DO STATUS",
        "type":"text"
    },
    "status":{
        "label":"STATUS",
        "type":"text"
    },
    "responsavel":{
        "label":"RESPONSAVEL",
        "type":"text"
    },
    "obs":{
        "label":"OBS",
        "type":"text"
    },
    "dist_pb":{
        "label":"DISTANCIA PONTO A",
        "type":"text"
    },
    "dist_pa":{
        "label":"DISTANCIA PONTO B",
        "type":"text"
    },
    "data_ult_vist":{
        "label":"DATA ULTIMA VSITORIA",
        "type":"text"
    },
    "criticidade":{
        "label":"CRITICIDADE",
        "type":"text"
    },
    "updated_at":{
        "label":"ULTIMA MODIFICAÇÃO",
        "type":"text"
    },
}
