import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// PV_STATUS Redux States
import {
    GET_PV_STATUS,
    ADD_NEW_PV_STATUS,
    DELETE_PV_STATUS,
    UPDATE_PV_STATUS,
} from "./actionType";
import {
    PvStatusApiResponseSuccess, PvStatusApiResponseError,
    addPvStatusSuccess,
    addPvStatusFail,
    updatePvStatusSuccess,
    updatePvStatusFail,
    deletePvStatusSuccess,
    deletePvStatusFail,
} from "./action";

//Include Both Helper File with needed methods
import {
    getPvStatus as getPvStatusApi,
    addNewPvStatus,
    updatePvStatus,
    deletePvStatus,
} from "../../helpers/backend_helper";

function* getPvStatus({ payload: pvstatus }) {
    try {
        let allResults = [];
        let response = yield call(getPvStatusApi, pvstatus);
        allResults = allResults.concat(response.results);
        while (response.next){
            // Obtém o número da próxima página
            const nextPage = response.next.split('=').pop();
            // Faz uma nova chamada para a próxima página
            response = yield call(getPvStatusApi, { ...pvstatus, page: nextPage });
            // Adicionamos os resultados desta página à lista principal
            allResults = allResults.concat(response.results);
        }
        yield put(PvStatusApiResponseSuccess(GET_PV_STATUS, allResults));
    } catch (error) {
        //console.log(error);
        yield put(PvStatusApiResponseError(GET_PV_STATUS, error));
    }
}

function* onAddNewPvStatus({ payload: pvstatus }) {
    try {
        const response = yield call(addNewPvStatus, pvstatus);
        yield put(addPvStatusSuccess(response));
        toast.success("PvStatus Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(addPvStatusFail(error));
        toast.error("PvStatus Added Failed", { autoClose: 3000 });
    }
}

function* onDeletePvStatus({ payload: pvstatus }) {
    try {
        const response = yield call(deletePvStatus, pvstatus);
        yield put(deletePvStatusSuccess({ pvstatus, ...response }));
        toast.success("PvStatus Delete Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(deletePvStatusFail(error));
        toast.error("PvStatus Delete Failed", { autoClose: 3000 });
    }
}

function* onUpdatePvStatus({ payload: pvstatus }) {
    try {
        const response = yield call(updatePvStatus, pvstatus);
        yield put(updatePvStatusSuccess(response));
        toast.success("PvStatus Updated Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(updatePvStatusFail(error));
        toast.error("PvStatus Updated Failed", { autoClose: 3000 });
    }
}

export function* watchGetPvStatus() {
    yield takeEvery(GET_PV_STATUS, getPvStatus);
}

export function* watchAddNewPvStatus() {
    yield takeEvery(ADD_NEW_PV_STATUS, onAddNewPvStatus);
}

export function* watchUpdatePvStatus() {
    yield takeEvery(UPDATE_PV_STATUS, onUpdatePvStatus);
}

export function* watchDeletePvStatus() {
    yield takeEvery(DELETE_PV_STATUS, onDeletePvStatus);
}

function* pvstatusSaga() {
    yield all([
        fork(watchGetPvStatus),
        fork(watchAddNewPvStatus),
        fork(watchUpdatePvStatus),
        fork(watchDeletePvStatus)
    ]
    );
}

export default pvstatusSaga;
