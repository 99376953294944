import React from 'react';

//Import maps
import WebMap from "./WebMap";
import LeafletMap from "./LeafletMap";

const Home = () => {
    document.title = "WebMap Esgoto | SANapp";
    return (
        <>
            <WebMap />
        </>
    );
};

export default Home;