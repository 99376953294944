import {
  NEW_PASSWORD,
  NEW_PASSWORD_SUCCESS,
  NEW_PASSWORD_ERROR,
} from "./actionTypes";

const initialState = {
  newSuccessMsg: null,
  newError: null,
};

const newPassword = (state = initialState, action) => {
  switch (action.type) {
    case NEW_PASSWORD:
      state = {
        ...state,
        newSuccessMsg: null,
        newError: null,
      };
      break;
    case NEW_PASSWORD_SUCCESS:
      state = {
        ...state,
        newSuccessMsg: action.payload,
      };
      break;
    case NEW_PASSWORD_ERROR:
      state = { ...state, newError: action.payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default newPassword;
