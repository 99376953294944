import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardFooter, CardBody, Col, Row, Input, Label, Table, Button, Form, FormGroup, Alert,
    DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown  } from "reactstrap";
import { useDispatch } from "react-redux";
import ReactPaginate from 'react-paginate';
import * as XLSX from 'xlsx';
import FileSaver from "file-saver";

import { getPvServices as getPvServicesApi, updatePvServices } from "../../../helpers/backend_helper";

const OrderService = () => {
    const [formValues, setFormValues] = useState({
        id_pv: '',
        macro_status: '',
        opcao: '',
        prioridade: '',
        resolvida: '',
        data_resol: '',
        created_at: '',
        updated_at: '',
        id_pvstatu: '',
    });
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value === "Selecionar" ? "" : value });
    };
    const dispatch = useDispatch();


    //const { osPvstt } = useSelector((state) => ({ osPvstt: state.PvServices.osPvstt }));
    //useEffect(() => { if (osPvstt && !osPvstt.length) { dispatch(getPvServices(formValues)); } }, [dispatch, osPvstt]);
    //console.log(osPvstt)
    //const sendForm = () => { dispatch(getPvServices(formValues)); };


    const [osPvstt, setPvServices] = useState([]);
    const minhaPromise = new Promise((resolve, reject) => {
        const osPvstt = getPvServicesApi(formValues);
        resolve(osPvstt);
        reject("FALHOU DE NOVO");
    });
    //minhaPromise.then((valor) => setPvServices(valor.results))
    const sendForm = () => { minhaPromise.then((valor) => setPvServices(valor.results)) };
    
    const sendFormFinish = (item) => new Promise((resolve) => {
            const result = updatePvServices({
                id_dtStt:item.id, 
                id_pv: item.id_pv,
                macro_status: item.macro_status,
                opcao: item.opcao,
                prioridade:item.prioridade,
                resolvida:"true",
                data_resol: item.data_resol,
                created_at: item.created_at,
                updated_at: item.updated_at,
                id_pvstatu: item.id_pvstatu,
            }, item.id_dtStt);
            resolve(result);
        }).then((result)=> minhaPromise.then((valor) => setPvServices(valor.results)))
        //console.log(osPvstt);
    const finishFunction = (item) => {
        console.log(item);
        sendFormFinish(item)
    }

    const sendFormFinish2 = async (item) => {
        try {
            await updatePvServices({
                id_dtStt: item.id_dtStt,
                id_pv: item.id_pv,
                macro_status: item.macro_status,
                opcao: item.opcao,
                prioridade: item.prioridade,
                resolvida: "true",
                data_resol: item.data_resol,
                created_at: item.created_at,
                updated_at: item.updated_at,
                id_pvstatu: item.id_pvstatu,
            }, item.id_dtStt);

            // Atualiza os dados após a finalização
            sendForm();
        } catch (error) {
            console.error("Erro ao finalizar:", error);
        }
    };


    const wordFormated = (word) => {
        switch (word) {
            case "junt_tub_s":
                return "JUNTA TUDO SAIDA"
            default:
                return word;
        }
    };

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 15;
    const handlePageClick = ({ selected }) => { setCurrentPage(selected) };
    const paginatedData = osPvstt.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    const [selectAll, setSelectAll] = useState(false);
    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckAll = () => {
        setSelectAll(!selectAll);
        const newCheckedItems = {};
        osPvstt.forEach((item) => {
            newCheckedItems[item.id] = !selectAll;
        });
        setCheckedItems(newCheckedItems);
        //console.log(checkedItems);
    };

    const handleCheckboxChange = (id) => {
        const newCheckedItems = { ...checkedItems, [id]: !checkedItems[id] };
        setCheckedItems(newCheckedItems);
    };

    const handleExportExcel = () => {
        // Filtra os itens marcados
        const selectedItems = osPvstt.filter((item) => checkedItems[item.id]);
        // Cria um array de dados para a planilha
        const dataForExcel = selectedItems.map((item) => ({
            ID: item.id,
            ID_PV: item.id_pv,
            ID_PV_STATUS: item.id_pvstatu,
            ID_MACRO_STATUS: item.macro_status,
            OPCAO: item.opcao,
            PRIORIDADE: item.prioridade,
            RESOLVIDA: item.resolvida,
            DATA_RESOLVID: item.data_resol,
            CRIADA_EM: item.created_at,
            ATUALIZADA_EM: item.updated_at,

        }));
        const ws = XLSX.utils.json_to_sheet(dataForExcel);
        //const wb = XLSX.utils.book_new();
        const wb = XLSX.utils.book_new({ Sheets: { data: ws }, SheetsNames: ["data"] });
        XLSX.utils.book_append_sheet(wb, ws, 'Lista CiStatus');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: "xlsx" })
        FileSaver.saveAs(data, "Selecionados_Ci_Status" + ".xlsx")
    };

    return (
        <>
            <div className="content">

                <h1 class="card-title color_title">Lista de Ordens de Serviço</h1>

                <Card>
                    <CardHeader>
                        <form className="app-search d-none d-md-block">
                            <Row>
                                <Col xxl={12}>
                                    <Row className="gy-0">
                                        <Col xxl={1}>
                                            <Label className="form-label mt-0 fs-12 mb-0" for="id_dtStt">ID DETALHE:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="id_dtStt"
                                                id="id_dtStt" value={formValues.id_dtStt} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={1}>
                                            <Label className="form-label mt-0 fs-12 mb-0" for="id_pv">ID PV:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="id_pv"
                                                id="id_pv" value={formValues.id_pv} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={1}>
                                            <Label className="form-label mt-0 fs-12 mb-0" for="id_pvstatu">ID PV STATUS:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="id_pvstatu"
                                                id="id_pvstatu" value={formValues.id_pvstatu} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-0 fs-12 mb-0" for="macro_status">MACRO STATUS:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="macro_status"
                                                id="macro_status" value={formValues.macro_status} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={3}>
                                            <Label className="form-label mt-0 fs-12 mb-0" for="opcao">SITUAÇÃO:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="opcao"
                                                id="opcao" value={formValues.opcao} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-0 fs-12 mb-0" for="prioridade">PRIORIDADE:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="prioridade"
                                                id="prioridade" value={formValues.prioridade} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-0 fs-12 mb-0" for="resolvida">RESOLVIDA:</Label>
                                            <Input className="form-control-sm" type="select" name="resolvida" id="input_resolvida"
                                                value={formValues.resolvida} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="true" value="true">Sim</option>
                                                <option key="false" value="false">Não</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="data_resol">DATA RESOLUÇÃO:</Label>
                                            <Input type="date" className="form-control form-control-sm" name="data_resol"
                                                id="data_resol" value={formValues.data_resol} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="created_at">CRIADA EM:</Label>
                                            <Input type="date" className="form-control form-control-sm" name="created_at"
                                                id="created_at" value={formValues.created_at} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="updated_at">ATUALIZADA EM:</Label>
                                            <Input type="date" className="form-control form-control-sm" name="updated_at"
                                                id="updated_at" value={formValues.updated_at} onChange={handleInputChange} />
                                        </Col>


                                        <Col xxl={2}>
                                            <div className="text-end ms-auto end-0 hstack justify-content-end">
                                                <Button className="mt-4 shadow-lg p-2 px-5 ms-auto" color='secondary'
                                                    onClick={sendForm}
                                                > Buscar <i className="ri-arrow-right-line ms-1"></i> </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </form>
                    </CardHeader>



                    <CardBody>
                        <div className="tab-pane active" id="border-navs-home" role="tabpanel">
                            <Row>
                                <Col xl={12}>
                                    <div className="table-responsive">
                                        <Table className="table-hover align-middle table-nowrap mb-0 mt-5">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ "width": "25px" }}>
                                                        <div className="form-check">
                                                            <Input className="form-check-input" type="checkbox" id="checkAll" onChange={handleCheckAll} />
                                                        </div>
                                                    </th>
                                                    <th scope="col">Id</th>
                                                    <th scope="col">Id PV</th>
                                                    <th scope="col">Id PV STATUS</th>
                                                    <th scope="col">MACRO STATUS</th>
                                                    <th scope="col">SITUAÇÃO</th>
                                                    <th scope="col">PRIORIDADE</th>
                                                    <th scope="col">RESOLVIDA</th>
                                                    <th scope="col">DATA RESOLVIDA</th>
                                                    <th scope="col">CRIADA EM</th>
                                                    <th scope="col">ATUALIZADA EM</th>
                                                    <th scope="col">AÇÃO</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paginatedData.map((item, index) => (
                                                    <tr>
                                                        <th scope="row">
                                                            <div className="form-check">
                                                                <Input className="form-check-input" type="checkbox" id={`inlineCheckbox${item.id}`}
                                                                    checked={checkedItems[item.id] || false} onChange={() => handleCheckboxChange(item.id)}
                                                                />
                                                            </div>
                                                        </th>
                                                        <td>#{item.id_dtStt}</td>
                                                        <th scope="col">{item.id_pv}</th>
                                                        <th scope="col">{item.id_pvstatu}</th>
                                                        <th scope="col">{wordFormated(item.macro_status)}</th>
                                                        <th scope="col">{item.opcao}</th>
                                                        <th scope="col">{item.prioridade}</th>
                                                        <th scope="col"><span className={item.resolvida ? "badge bg-success" : "badge bg-danger"}>{item.resolvida ? "SIM" : "NÃO"}</span></th>
                                                        <th scope="col">{item.data_resol}</th>
                                                        <th scope="col">{item.created_at ? item.created_at.replace("T", "  ").split(".")[0] : null}</th>
                                                        <th scope="col">{item.updated_at}</th>
                                                        <th>
                                                            <UncontrolledDropdown className="dropdown d-inline-block">
                                                                <DropdownToggle className="btn btn-soft-secondary btn-sm" tag="button">
                                                                    <i className="ri-more-fill align-middle"></i>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-end">
                                                                    <DropdownItem href="#!"><i className="ri-eye-fill align-bottom me-2 text-muted"></i>Visualizar</DropdownItem>
                                                                    <DropdownItem className='edit-item-btn'><i className="ri-pencil-fill align-bottom me-2 text-muted"></i>Editar</DropdownItem>
                                                                    <DropdownItem className='edit-item-btn' onClick={() => finishFunction(item)}><i className="ri-check-fill align-bottom me-2 text-muted"></i>Finalizar</DropdownItem>
                                                                    <DropdownItem className='remove-item-btn'> <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </th>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                            <ReactPaginate previousLabel={'Anterior'} breakLabel={'...'} nextLabel={'Próximo'} activeClassName={'active'}
                                pageCount={Math.ceil(osPvstt.length / itemsPerPage)} marginPagesDisplayed={1} pageRangeDisplayed={3}
                                onPageChange={handlePageClick} containerClassName={'pagination'} subContainerClassName={'pages pagination'} />
                        </div>
                    </CardBody>
                </Card>

            </div>
        </>
    );
};

export default OrderService;