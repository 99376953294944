import React, { useState, useEffect } from 'react';
import proj4 from 'proj4';
import { Button, Row, Col, Label, Table, Modal, UncontrolledCarousel } from "reactstrap";
import { Popup, LayerGroup, Circle, LayersControl } from 'react-leaflet';
import { useSelector, useDispatch } from "react-redux";
import SimpleBar from 'simplebar-react';
import EditModalRedeStatus from "../../Helpers/Editar/EditModalRedeCad";
import { getRedeStatus, updateRedeStatus } from "../../../../Store/redestatusesgoto/action";

import img1 from "../../../../Assets/images/sanapp/CI01.jpeg";
import img2 from "../../../../Assets/images/sanapp/CI02.jpeg";
import img3 from "../../../../Assets/images/sanapp/CI03.jpeg";

import { Swiper, SwiperSlide } from "swiper/react";
//import "swiper/css";
//import "swiper/css/pagination";
//import "swiper/css/navigation";
//import "swiper/css/scrollbar";
//import "swiper/css/effect-fade";
//import "swiper/css/effect-flip";
//import { Pagination, Navigation, Scrollbar, EffectFade, EffectCreative, Mousewheel, EffectFlip, EffectCoverflow, Autoplay } from "swiper";

const { BaseLayer, Overlay } = LayersControl;

function useDataFetch(actionFunction, nameFunction, dataProperty) {
    const dispatch = useDispatch();
    const data = useSelector(state => state[nameFunction][dataProperty]);
    useEffect(() => {
        if (!data.length) {
            dispatch(actionFunction());
        }
    }, [dispatch, data]);
    return data;
};

function CamadaRedeStatus() {

    const redeStatus = useDataFetch(getRedeStatus, 'RedeStatus', 'redeStatus');

    const [modal_center_rede, setmodal_center_rede] = useState(false);
    const tog_center_rede = () => setmodal_center_rede(!modal_center_rede);

    const dispatch = useDispatch();
    const RedOptions = { color: 'red', fillColor: 'red' };
    const greenOptions = { color: 'green', fillColor: 'green' };
    const BlueOptions = { fillColor: 'blue' };
    const yellowOptions = { color: '#F2EA0C' }

    const saveEditDataRedeStatus = async (editedData) => {
        if (editedData.imagem) { delete editedData.imagem };
        const response = await dispatch(updateRedeStatus(editedData));
    };

    const transformCoord = (coord) => {
        try {
            const sourceEPSG = coord.split(';')[0].split('=')[1]
            proj4.defs("EPSG:" + sourceEPSG, "+proj=utm +zone=22 +south +ellps=GRS80 +units=m +no_defs");
            const coordArray = coord.split('(')[1].split(')')[0].split(' ');
            const coord_a = [parseFloat(coordArray[1]), parseFloat(coordArray[0])];

            return coord_a

            const transformed = proj4("EPSG:" + sourceEPSG, "EPSG:4326", coord_a);
            return [transformed[1], transformed[0]];
        }
        catch (err) { console.log(err); return [-27.612690, -48.598092] }
    };

    return (

        <Overlay checked name="REDE - STATUS" key={"REDE - STATUS"}>
            <LayerGroup>

                {redeStatus.map((item) => (
                    <Circle center={transformCoord(item.geom)} 
                        pathOptions={item.status === "Adequada" ||  item.status === "Adequado" ? greenOptions : 
                        item.status === "Outros" || item.status === "Outras" || item.status === "-" ? yellowOptions : 
                        RedOptions} radius={1} weight={1}
                        >
                        <Popup>
                            <Row>
                                <Col xl={12}>
                                    <SimpleBar autoHide={false} className="simplebar-track-secondary" style={{ maxHeight: "250px" }}>
                                        <div className="table-responsive mt-4 mt-xl-0">
                                            <Label className="text-secondary mt-1 fs-16 mb-0">REDE - STATUS</Label>
                                            <Table
                                                fixedHeaderScrollHeight="50px"
                                                className="
                                            table-sm
                                            table-hover 
                                            table-striped 
                                            align-middle 
                                            table-nowrap
                                            mb-0
                                            table-responsive 
                                            "
                                            >
                                                <thead>
                                                    <tr>
                                                        <th scope="col"></th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="text-secondary fs-10">ID STATUS:</td>
                                                        <td className="text-primary fs-10">{item.id_status}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-secondary fs-10">ID CADASTRO:</td>
                                                        <td className="text-primary fs-10">{item.id_cad}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-secondary fs-10">STATUS:</td>
                                                        <td className="text-primary fs-10">{item.status}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-secondary fs-10">DISTÂNCIA PONTO A:</td>
                                                        <td className="text-primary fs-10">{item.dist_pa}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-secondary fs-10">DISTÂNCIA PONTO B:</td>
                                                        <td className="text-primary fs-10">{item.dist_pb}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-secondary fs-10">RESPONSAVEL:</td>
                                                        <td className="text-primary fs-10">{item.responsavel}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-secondary fs-10">OBSERVAÇÃO:</td>
                                                        <td className="text-primary fs-10">{item.obs}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </SimpleBar>
                                </Col>
                            </Row>
                            <div className="hstack gap-3">
                                <Button
                                    className="mt-3 start-25 shadow-lg p-2 px-4"
                                    color='primary'
                                    onClick={() => tog_center_rede()}
                                >FOTOS</Button>
                                <EditModalRedeStatus data={item} onSave={saveEditDataRedeStatus} />
                                <Button className="mt-3 start-25 shadow-lg p-2 px-2" color='success'>
                                    ABRIR O.S.
                                </Button>
                            </div>
                            <div className="vstack gap-2">
                            </div>
                            <Modal
                                isOpen={modal_center_rede}
                                toggle={() => {
                                    tog_center_rede();
                                }}
                                size="md"
                                centered
                            >
                                <Row className="m-3">
                                    <h4 className="card-title mb-0">IMAGENS DA REDE</h4>
                                    <p className="text-muted">Abaixo o imagens disponíveis em nosso sistema:</p>
                                    <UncontrolledCarousel
                                interval={4000}
                                items={[
                                    {
                                        altText: " ",
                                        caption: " ",
                                        key: 1,
                                        src: img1,
                                    },
                                    {
                                        altText: " ",
                                        caption: " ",
                                        key: 2,
                                        src: img2,
                                    },
                                    {
                                        altText: " ",
                                        caption: " ",
                                        key: 3,
                                        src: img3,
                                    },
                                ]}
                            />
                                </Row>
                            </Modal>
                        </Popup>
                    </Circle>
                ))}
            
            </LayerGroup>
        </Overlay>
    );
};
export default CamadaRedeStatus;