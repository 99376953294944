import {
  GET_REDE_CADASTROS,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_NEW_REDE_CADASTROS,
  ADD_REDE_CADASTROS_SUCCESS,
  ADD_REDE_CADASTROS_FAIL,
  UPDATE_REDE_CADASTROS,
  UPDATE_REDE_CADASTROS_SUCCESS,
  UPDATE_REDE_CADASTROS_FAIL,
  DELETE_REDE_CADASTROS,
  DELETE_REDE_CADASTROS_SUCCESS,
  DELETE_REDE_CADASTROS_FAIL,
} from "./actionType";

// common success
export const RedeCadastrosApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const RedeCadastrosApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getRedeCadastros = redecadastros => ({
  type: GET_REDE_CADASTROS,
  payload: redecadastros,
});

export const updateRedeCadastros = redecadastros => ({
  type: UPDATE_REDE_CADASTROS,
  payload: redecadastros,
});

export const updateRedeCadastrosSuccess = redecadastros => ({
  type: UPDATE_REDE_CADASTROS_SUCCESS,
  payload: redecadastros,
});

export const updateRedeCadastrosFail = error => ({
  type: UPDATE_REDE_CADASTROS_FAIL,
  payload: error,
});

export const addNewRedeCadastros = redecadastros => ({
  type: ADD_NEW_REDE_CADASTROS,
  payload: redecadastros,
});

export const addRedeCadastrosSuccess = redecadastros => ({
  type: ADD_REDE_CADASTROS_SUCCESS,
  payload: redecadastros,
});

export const addRedeCadastrosFail = error => ({
  type: ADD_REDE_CADASTROS_FAIL,
  payload: error,
});
export const deleteRedeCadastros = redecadastros => ({
  type: DELETE_REDE_CADASTROS,
  payload: redecadastros,
});

export const deleteRedeCadastrosSuccess = redecadastros => ({
  type: DELETE_REDE_CADASTROS_SUCCESS,
  payload: redecadastros,
});

export const deleteRedeCadastrosFail = error => ({
  type: DELETE_REDE_CADASTROS_FAIL,
  payload: error,
});