import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label, Row, Col } from 'reactstrap';

const EditModalPvStatus = ({ data, onSave }) => {
    const [editedData, setEditedData] = useState(data);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openEditModal = () => setModalIsOpen(true);
    const toggleModal = () => setModalIsOpen(!modalIsOpen);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedData({ ...editedData, [name]: value });
    };

    const handleSave = () => {
        onSave(editedData);
        toggleModal();
    };

    return (
        <>
            <Button
                className="mt-3 start-25 shadow-lg p-2 px-4 ms-auto"
                color='secondary'
                onClick={openEditModal}
            >
                EDITAR
            </Button>
            <Modal isOpen={modalIsOpen} toggle={toggleModal} style={{ width: '1024px', maxWidth: '100vw' }} centered>
                <ModalHeader toggle={toggleModal}>
                    <div className="justify-content-center">
                        Editar dados do status do Poço de Visita
                    </div>
                </ModalHeader>
                <hr />
                <ModalBody>
                    <Row>
                        <Col xxl={12}>
                            <Row className="gy-0">
                                {/*ID PV STATUS*/}
                                <Col xxl={2}>
                                    <Label className="form-label mt-0 fs-12 mb-0" for="id_pvstatu">ID PV STATUS:</Label>
                                    <Input type="text" className="form-control form-control-sm" name="id_pvstatu"
                                        id="id_pvstatu" value={editedData.id_pvstatu} onChange={handleInputChange} />
                                </Col>
                                {/*ID PV*/}
                                <Col xxl={1}>
                                    <Label className="form-label mt-0 fs-12 mb-0" for="id_pv">ID PV:</Label>
                                    <Input type="text" className="form-control form-control-sm" name="id_pv"
                                        id="id_pv" value={editedData.id_pv} onChange={handleInputChange} />
                                </Col>
                                {/*LOGRADOURO*/}
                                <Col xxl={4}>
                                    <Label className="form-label mt-0 fs-12 mb-0" for="logradouro">LOGRADOURO:</Label>
                                    <Input type="text" className="form-control form-control-sm" name="logradouro"
                                        id="logradouro" value={editedData.logradouro} onChange={handleInputChange} />
                                </Col>
                                {/*BAIRRO*/}
                                <Col xxl={3}>
                                    <Label className="form-label mt-0 fs-12 mb-0" for="bairro">BAIRRO:</Label>
                                    <Input type="text" className="form-control form-control-sm" name="bairro"
                                        id="bairro" value={editedData.bairro} onChange={handleInputChange} />
                                </Col>
                                {/*CIDADE*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-0 fs-12 mb-0" for="cidade">CIDADE:</Label>
                                        <Input type="text" className="form-control form-control-sm" name="cidade"
                                            id="cidade" value={editedData.cidade} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*DATA DOS DADOS*/}
                                <Col xxl={3}>
                                    <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="data_dado">DATA DOS DADOS:</Label>
                                    <Input type="date" className="form-control form-control-sm" name="data_dado"
                                        id="data_dado" value={editedData.data_dado} onChange={handleInputChange} />
                                </Col>
                                {/*ABRASIVIDADE TAMPA*/}
                                <Col xxl={3}>
                                    <Label className="form-label mt-3 fs-12 mb-0" for="abras_tamp">ABRASIVIDADE TAMPA:</Label>
                                    <Input className="form-control-sm" type="select" name="abras_tamp" id="inputAbras_tamp" 
                                        value={editedData.abras_tamp} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Sim" value="Sim">Sim</option>
                                            <option key="Não" value="Não">Não</option>
                                    </Input>
                                </Col>
                                {/*ESTADO DA TAMPA*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0" for="est_tampa">ESTADO DA TAMPA:</Label>
                                        <Input className="form-control-sm" type="select" name="est_tampa" id="inputEst_tampa" 
                                            value={editedData.est_tampa} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Adequada" value="Adequada">Adequada</option>
                                            <option key="Necessidade de Manutenção" value="Necessidade de Manutenção">Necessidade de Manutenção</option>
                                            <option key="Necessidade de Substituição" value="Necessidade de Substituição">Necessidade de Substituição</option>
                                    </Input>
                                    </div>
                                </Col>
                                {/*ESTADO DA PAVIMENTAÇÂO*/}
                                <Col xxl={3}>
                                    <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="est_pav" >ESTADO DA PAVIMENTAÇÂO:</Label>
                                    <Input className="form-control-sm" type="select" name="est_pav" id="inputEst_pav" 
                                        value={editedData.est_pav} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Adequada" value="Adequada">Adequada</option>
                                            <option key="Pequenas Imperfeições" value="Pequenas Imperfeições">Pequenas Imperfeições</option>
                                            <option key="Necessidade de Manutenção Estrutural" value="Necessidade de Manutenção Estrutural">Necessidade de Manutenção Estrutural</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                    </Input>
                                </Col>
                                {/*NIVEL GREI*/}
                                <Col xxl={3}>
                                    <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="niv_greide">NIVEL GREIDE:</Label>
                                    <Input className="form-control-sm" type="select" name="niv_greide" id="inputNiv_greide" 
                                        value={editedData.niv_greide} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Sim" value="Sim">Sim</option>
                                            <option key="Não" value="Não">Não</option>
                                    </Input>
                                </Col>
                                {/*ESTADO DA LAJE*/}
                                <Col xxl={3}>
                                    <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="est_laje">ESTADO DA LAJE:</Label>
                                    <Input className="form-control-sm" type="select" name="est_laje" id="inputEst_laje" 
                                        value={editedData.est_laje} onChange={handleInputChange}>
                                        <option selected>Selecionar</option>
                                        <option key="Adequado" value="Adequado">Adequado</option>
                                        <option key="Não foi possível avaliar" value="Não foi possível avaliar">Não foi possível avaliar</option>
                                        <option key="Não tem laje" value="Não tem laje">Não tem laje</option>
                                        <option key="Necessidade de Manutenção Estrutural" value="Necessidade de Manutenção Estrutural">Necessidade de Manutenção Estrutural</option>
                                        <option key="Risco de Colapso da Estrutura" value="Risco de Colapso da Estrutura">Risco de Colapso da Estrutura</option>
                                        <option key="Pequenas Imperfeições" value="Pequenas Imperfeições">Pequenas Imperfeições</option>
                                        <option key="Outros" value="Outros">Outros</option>
                                    </Input>
                                </Col>
                                {/*ESTADO DE ATENÇÃO*/}
                                <Col xxl={2}>
                                    <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="est_atenca">ESTADO DE ATENÇÃO:</Label>
                                    <Input className="form-control-sm" type="select" name="est_atenca" id="inputEst_atenca" 
                                        value={editedData.est_atenca} onChange={handleInputChange}>
                                        <option selected>Selecionar</option>
                                        <option key="Sem Riscos Iminentes" value="Sem Riscos Iminentes">Sem Riscos Iminentes</option>
                                        <option key="Com Riscos Iminentes" value="Com Riscos Iminentes">Com Riscos Iminentes</option>
                                    </Input>
                                </Col>
                                {/*IMAGEM ACABAMENTO EXTERNO*/}


                                {/*STATUS ACESSO*/}
                                <Col xxl={3}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="stat_acess">STATUS ACESSO:</Label>
                                        <Input className="form-control-sm" type="select" name="stat_acess" id="inputStat_acess" 
                                            value={editedData.stat_acess} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Acessível" value="Acessível">Acessível</option>
                                            <option key="Acessível com dificuldades" value="Acessível com dificuldades">Acessível com dificuldades</option>
                                            <option key="Inacessível" value="Inacessível">Inacessível</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*C ACABAMENTO EXTERNO*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="c_acab_ext">C ACABAMENTO EXTERNO:</Label>
                                        <Input className="form-control-sm" type="select" name="c_acab_ext" id="inputC_acab_ext" 
                                            value={editedData.c_acab_ext} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Adequado" value="Adequado">Adequado</option>
                                            <option key="Pequenas Imperfeições" value="Pequenas Imperfeições">Pequenas Imperfeições</option>
                                            <option key="Necessidade de Manutenção Estrutural" value="Necessidade de Manutenção Estrutural">Necessidade de Manutenção Estrutural</option>
                                            <option key="Risco de Colapso da Estrutura" value="Risco de Colapso da Estrutura">Risco de Colapso da Estrutura</option>
                                            <option key="Colapsado" value="Colapsado">Colapsado</option>
                                            <option key="Não foi possível avaliar" value="Não foi possível avaliar">Não foi possível avaliar</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*C ACABAMENTO INTERNO*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="c_acab_int">C ACABAMENTO INTERNO:</Label>
                                        <Input className="form-control-sm" type="select" name="c_acab_int" id="inputC_acab_int" 
                                            value={editedData.c_acab_int} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Calha de Escoamento Bem Acabada" value="Calha de Escoamento Bem Acabada">Calha de Escoamento Bem Acabada</option>
                                            <option key="Calha de Escoamento com Imperfeições" value="Calha de Escoamento com Imperfeições">Calha de Escoamento com Imperfeições</option>
                                            <option key="Calha de Escoamento Irregular" value="Calha de Escoamento Irregular">Calha de Escoamento Irregular</option>
                                            <option key="Sem Calha de Escoamento" value="Sem Calha de Escoamento">Sem Calha de Escoamento</option>
                                            <option key="Fundo Quebrado" value="Fundo Quebrado">Fundo Quebrado</option>
                                            <option key="Sem Fundo" value="Sem Fundo">Sem Fundo</option>
                                            <option key="Não Foi Possível Avaliar" value="Não Foi Possível Avaliar">Não Foi Possível Avaliar</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*JUN EDIF PV*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="jun_tub_en">JUN EDIF PV:</Label>
                                        <Input className="form-control-sm" type="select" name="jun_tub_en" id="inputJun_tub_en" 
                                            value={editedData.jun_tub_en} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Adequada" value="Adequada">Adequada</option>
                                            <option key="Inadequada" value="Inadequada">Inadequada</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*JUN PV RAM*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="junt_tub_s">JUN PV RAM:</Label>
                                        <Input className="form-control-sm" type="select" name="junt_tub_s" id="inputJun_tub_ram" 
                                            value={editedData.junt_tub_s} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Adequada" value="Adequada">Adequada</option>
                                            <option key="Inadequada" value="Inadequada">Inadequada</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*INFILTRAÇÃO*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="infiltra">INFILTRAÇÃO:</Label>
                                        <Input className="form-control-sm" type="select" name="infiltra" id="inputInfiltra" 
                                            value={editedData.infiltra} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Sem Infiltração" value="Sem Infiltração">Sem Infiltração</option>
                                            <option key="Infiltração Parcial" value="Infiltração Parcial">Infiltração Parcial</option>
                                            <option key="Infiltração Total" value="Infiltração Total">Infiltração Total</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*INFILTRAÇÃO PARAS*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="infilt_par">INFILTRAÇÃO PARAS:</Label>
                                        <Input className="form-control-sm" type="select" name="infilt_par" id="inputInfilt_par" 
                                            value={editedData.infilt_par} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Sim" value="Sim">Sim</option>
                                            <option key="Não" value="Não">Não</option>
                                            <option key="Não Foi Possível Avaliar" value="Não Foi Possível Avaliar">Não Foi Possível Avaliar</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*STATUS ESCOAMENTO*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="stat_escoa">STATUS ESCOAMENTO:</Label>
                                        <Input className="form-control-sm" type="select" name="stat_escoa" id="inputStat_escoa" 
                                            value={editedData.stat_escoa} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Livre" value="Livre">Livre</option>
                                            <option key="Parcial por Detritos ou Sólidos" value="Parcial por Detritos ou Sólidos">Parcial por Detritos ou Sólidos</option>
                                            <option key="Parcial por Areia" value="Parcial por Areia">Parcial por Areia</option>
                                            <option key="Restrito por Detritos ou Sólidos" value="Restrito por Detritos ou Sólidos">Restrito por Detritos ou Sólidos</option>
                                            <option key="Restrito por Areia" value="Restrito por Areia">Restrito por Areia</option>
                                            <option key="Transbordando" value="Transbordando">Transbordando</option>
                                            <option key="Não Foi Possível Avaliar" value="Não Foi Possível Avaliar">Não Foi Possível Avaliar</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*STATUS DISP*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="stat_disp">STATUS DISP:</Label>
                                        <Input className="form-control-sm" type="select" name="stat_disp" id="inputStat_disp" 
                                            value={editedData.stat_disp} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Em Operação" value="Em Operação">Em Operação</option>
                                            <option key="Fora de Operação" value="Fora de Operação">Fora de Operação</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*IMAGEM ACABAMENTO INTERNO*/}


                                {/*FONTE COMP*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="font_comp">FONTE COMP:</Label>
                                        <Input type="text" className="form-control form-control-sm" name="font_comp"
                                            id="font_comp" value={editedData.font_comp} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*FONTE DA GEOMET*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="font_geom">FONTE DA GEOMET:</Label>
                                        <Input type="text" className="form-control form-control-sm" name="font_geom"
                                            id="font_geom" value={editedData.font_geom} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*ORIGEM DOS ARQUIVOS*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="orig_arq">ORIGEM DOS ARQUIVOS:</Label>
                                        <Input type="text" className="form-control form-control-sm" name="orig_arq"
                                            id="orig_arq" value={editedData.orig_arq} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*RESOLVIDA*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="resolvida">RESOLVIDA:</Label>
                                        <Input className="form-control-sm" type="select" name="resolvida" id="inputResolvida" 
                                            value={editedData.resolvida} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Sim" value="Sim">Sim</option>
                                            <option key="Não" value="Não">Não</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*DATA RESOLVIDA*/}
                                <Col xxl={3}>
                                    <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="data_resol">DATA RESOLVIDA:</Label>
                                    <Input type="date" className="form-control form-control-sm" name="data_resol"
                                        id="data_resol" value={editedData.data_resol} onChange={handleInputChange} />
                                </Col>
                                {/*PRIORIDADE*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="prioridade">PRIORIDADE:</Label>
                                        <Input className="form-control-sm" type="select" name="prioridade" id="inputPrioridade" 
                                            value={editedData.prioridade} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Adequado" value="Adequado">Adequado</option>
                                            <option key="Necessita de Atenção ou Limpeza" value="Necessita de Atenção ou Limpeza">Necessita de Atenção ou Limpeza</option>
                                            <option key="Manutenção Programada" value="Manutenção Programada">Manutenção Programada</option>
                                            <option key="Manutenção Emergencial" value="Manutenção Emergencial">Manutenção Emergencial</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*RESPONSÁVEL*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="responsave">RESPONSÁVEL:</Label>
                                        <Input type="text" className="form-control form-control-sm" name="responsave"
                                            id="responsave" value={editedData.responsave} onChange={handleInputChange} />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
                <hr />
                <ModalFooter>
                    <Button color="primary" onClick={handleSave}>
                        Salvar
                    </Button>{' '}
                    <Button color="danger" onClick={toggleModal}>
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default EditModalPvStatus;
