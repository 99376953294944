import React from 'react';
import IframeWithLoading from "../IframeWithLoading"

const DashboardRedeStatus = () => {
    document.title = "Dashboard Redes - Status | SANAPP";
    return (
        <React.Fragment>
            <IframeWithLoading
                src="https://metabase.apisanapp.com.br/public/dashboard/6ea147c2-a23b-482b-928f-d24f647e5d8b"
                title="Redes - Status"
                width="100%"
                height="910"
            />
        </React.Fragment>
    );
};

export default DashboardRedeStatus;