import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label, Row, Col } from 'reactstrap';

const EditModalCiCad = ({ coord, data, onSave }) => {
    const [editedData, setEditedData] = useState(data);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openEditModal = () => setModalIsOpen(true);
    const toggleModal = () => setModalIsOpen(!modalIsOpen);
    
    const input_coord = (e) => {
        const { name, value } = e.target;
        const coordenad = { 
            latitude:coord[0], 
            longitude:coord[1] 
        };
        setEditedData({ ...editedData, [name]: coordenad[`${name}`] });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedData({ ...editedData, [name]: value });
    };

    const handleSave = () => {
        onSave(editedData);
        toggleModal();
    };

    const ciCadastroDic = {
        "cidade": "CIDADE",
        "bairro": "BAIRRO",
        "logradouro": "LOGRADOURO",
        "n_edificac": "NÚMERO DO EDIFÍCIO",
        "mes": "MÊS",
        "ano": "ANO",
        "data_dado": "DATA DOS DADOS",
        "id_ci": "ID DO CI",
        "tipo_ci": "TIPO DO CI",
        "local_ci": "LOCAL DO CI",
        "mat_tampa": "MATERIAL DA TAMPA",
        "diam_tampa": "DIAMETRO DA TAMPA",
        "tipo_pav": "PAVIMENTAÇÂO DA CALÇADA",
        "prof_poco": "PROFUNDIDADE DO POÇO",
        "mat_poco": "MATERIAL DO POÇO",
        "form_poco": "FORMATO DO POÇO",
        "d_int_poco": "DIMENSÕES INTERNAS DO POÇO",
        "d_poco_ret": "DIMENSÕES DO POÇO",
        "mat_tubo_s": "MATERIAL DO TUBO",
        "dim_tubo_s": "DIMENSÕES TUBO",
        "font_comp": "FONTE COMP",
        "font_geom": "FONTE GEOMÉTRICA",
        "orig_arq": "ORIGEM DO ARQUIVO"
    }

    return (
        <>
            <Button
                className="mt-3 start-25 shadow-lg p-2 px-4 ms-auto"
                color='secondary'
                onClick={openEditModal}
            >
                EDITAR
            </Button>
            <Modal isOpen={modalIsOpen} toggle={toggleModal} style={{ width: '1024px', maxWidth: '100vw' }} centered>
                <ModalHeader toggle={toggleModal}>
                        <div className="justify-content-center">
                            Editar dados de cadastro da Caixa de Inspeção
                        </div>
                </ModalHeader>
                <hr />
                <ModalBody>
                    <Row>
                        <Col xxl={12}>
                            <Row className="gy-0">
                                {/*ID CI*/}
                                <Col xxl={1}>
                                    <Label className="form-label mt-0 fs-12 mb-0" for="id_ci">ID CI:</Label>
                                    <Input type="text" className="form-control form-control-sm" name="id_ci"
                                        id="id_ci" value={editedData.id_ci} onChange={handleInputChange} />
                                </Col>
                                {/*LOGRADOURO*/}
                                <Col xxl={4}>
                                    <Label className="form-label mt-0 fs-12 mb-0" for="logradouro">LOGRADOURO:</Label>
                                    <Input type="text" className="form-control form-control-sm" name="logradouro"
                                        id="logradouro" value={editedData.logradouro} onChange={handleInputChange} />
                                </Col>
                                {/*No. EDIFÍCIO*/}
                                <Col xxl={2}>
                                    <Label className="form-label mt-0 fs-12 mb-0" for="n_edificac">No. EDIFÍCIO:</Label>
                                    <Input type="text" className="form-control form-control-sm" name="n_edificac"
                                        id="n_edificac" value={editedData.n_edifica} onChange={handleInputChange} />
                                </Col>
                                {/*BAIRRO*/}
                                <Col xxl={3}>
                                    <Label className="form-label mt-0 fs-12 mb-0" for="bairro">BAIRRO:</Label>
                                    <Input type="text" className="form-control form-control-sm" name="bairro"
                                        id="bairro" value={editedData.bairro} onChange={handleInputChange} />
                                </Col>
                                {/*CIDADE*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-0 fs-12 mb-0" for="cidade">CIDADE:</Label>
                                        <Input type="text" className="form-control form-control-sm" name="cidade"
                                            id="cidade" value={editedData.cidade} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*DATA DOS DADOS*/}
                                <Col xxl={3}>
                                    <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="data_dado">DATA DOS DADOS:</Label>
                                    <Input type="date" className="form-control form-control-sm" name="data_dado"
                                        id="data_dado" value={editedData.data_dado} onChange={handleInputChange} />
                                </Col>
                                {/*TIPO CI*/}
                                <Col xxl={3}>
                                    <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="tipo_ci" >TIPO CI:</Label>
                                    <Input className="form-control-sm" type="select" name="tipo_ci" id="inputTipo_ci" 
                                        value={editedData.tipo_ci} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Caixa de Inspeção" value="Caixa de Inspeção">Caixa de Inspeção</option>
                                            <option key="TIL Predial" value="TIL Predial">TIL Predial</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                    </Input>
                                </Col>
                                {/*LOCAL DO CI*/}
                                <Col xxl={3}>
                                    <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="local_ci">LOCAL DO CI:</Label>
                                    <Input className="form-control-sm" type="select" name="local_ci" id="inputLocalCi" 
                                        value={editedData.local_ci} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Passeio" value="Passeio">Passeio</option>
                                            <option key="Via" value="Via">Via</option>
                                            <option key="Terreno / Propriedade Privada" value="Terreno / Propriedade Privada">Terreno / Propriedade Privada</option>
                                            <option key="Servidão Sanitária" value="Servidão Sanitária">Servidão Sanitária</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                    </Input>
                                </Col>
                                {/*MATERIAL TAMPA*/}
                                <Col xxl={3}>
                                    <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="mat_tampa">MATERIAL TAMPA:</Label>
                                    <Input className="form-control-sm" type="select" name="mat_tampa" id="inputMat_tampa" 
                                        value={editedData.mat_tampa} onChange={handleInputChange}>
                                        <option selected>Selecionar</option>
                                        <option key="Ferro Fundido" value="Ferro Fundido">Ferro Fundido</option>
                                        <option key="Laje" value="Laje">Laje</option>
                                        <option key="Ferro Fundido + Laje" value="Ferro Fundido + Laje">
                                            Ferro Fundido + Laje</option>
                                        <option key="Plástico" value="Plástico">Plástico</option>
                                        <option key="Outros" value="Outros">Outros</option>
                                    </Input>
                                </Col>
                                {/*DIAM. DA TAMPA*/}
                                <Col xxl={2}>
                                    <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="diam_tampa">DIAMETRO DA TAMPA:</Label>
                                    <Input className="form-control-sm" type="select" name="diam_tampa" id="inputDiam_tampa" 
                                        value={editedData.diam_tampa} onChange={handleInputChange}>
                                        <option selected>Selecionar</option>
                                        <option key="400mm" value="400mm">400mm</option>
                                        <option key="600mm" value="600mm">600mm</option>
                                        <option key="800mm" value="800mm">800mm</option>
                                        <option key="Outros" value="Outros">Outros</option>
                                    </Input>
                                </Col>
                                {/*PAVIMENTAÇÂO DA CALÇADA*/}
                                <Col xxl={3}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="tipo_pav">TIPO PAVIMENTAÇÂO:</Label>
                                        <Input className="form-control-sm" type="select" name="tipo_pav" id="inputTipo_pav" 
                                            value={editedData.tipo_pav} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Concreto" value="Concreto">Concreto</option>
                                            <option key="Piso Cerâmico" value="Piso Cerâmico">Piso Cerâmico</option>
                                            <option key="Piso Padrão Municipal" value="Piso Padrão Municipal">Piso Padrão Municipal</option>
                                            <option key="Lajota" value="Lajota">Lajota</option>
                                            <option key="Paralelepípedo" value="Paralelepípedo">Paralelepípedo</option>
                                            <option key="Terreno Natural" value="Terreno Natural">Terreno Natural</option>
                                            <option key="Asfalto" value="Asfalto">Asfalto</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*PROFUND. DO POÇO*/}
                                <Col xxl={3}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="prof_poco">PROFUNDIDADE DO POÇO:</Label>
                                        <Input type="number" className="form-control form-control-sm" name="prof_poco"
                                            id="prof_poco" value={editedData.prof_poco} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*MATERIAL DO POÇO*/}
                                <Col xxl={3}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="mat_poco">MATERIAL DO POÇO:</Label>
                                        <Input className="form-control-sm" type="select" name="mat_poco" id="inputMat_poco" 
                                            value={editedData.mat_poco} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Tubo concreto pré-moldado" value="Tubo concreto pré-moldado">Tubo concreto pré-moldado</option>
                                            <option key="Bloco + Alvenaria" value="Bloco + Alvenaria">Bloco + Alvenaria</option>
                                            <option key="PEAD" value="PEAD">PEAD</option>
                                            <option key="Polietileno" value="Polietileno">Polietileno</option>
                                            <option key="PVC" value="PVC">PVC</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*FORMATO DO POÇO*/}
                                <Col xxl={3}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="form_poco">FORMATO DO POÇO:</Label>
                                        <Input className="form-control-sm" type="select" name="form_poco" id="inputForm_poco" 
                                            value={editedData.form_poco} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Circular" value="Circular">Circular</option>
                                            <option key="Retangular" value="Retangular">Retangular</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*DIAM. INT. DO POÇO*/}
                                <Col xxl={3}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="d_int_poco">DIAMETRO INTERNO DO POÇO:</Label>
                                        <Input className="form-control-sm" type="select" name="d_int_poco" id="inputD_int_poco" 
                                            value={editedData.d_int_poco} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="400mm" value="400mm">400mm</option>
                                            <option key="600mm" value="600mm">600mm</option>
                                            <option key="100mm" value="100mm">100mm</option>
                                            <option key="150mm" value="150mm">150mm</option>
                                            <option key="800mm" value="800mm">800mm</option>
                                            <option key="1000mm" value="1000mm">1000mm</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*DIAMETRO POÇO RET*/}
                                <Col xxl={3}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="d_poco_ret">DIAMETRO DO POÇO RET:</Label>
                                        <Input type="number" className="form-control form-control-sm" name="d_poco_ret"
                                            id="d_poco_ret" value={editedData.d_poco_ret} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*METERIAL DO TUBO S*/}
                                <Col xxl={3}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="qtd_tubo_s">QUANTIDADE TUBO SAÍDA:</Label>
                                        <Input type="number" className="form-control form-control-sm" name="qtd_tubo_s"
                                            id="qtd_tubo_s" value={editedData.qtd_tubo_s} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*METERIAL DO TUBO S*/}
                                <Col xxl={3}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="mat_tubo_s">MATERIAL DO TUBO DE SAÍDA:</Label>
                                        <Input className="form-control-sm" type="select" name="mat_tubo_s" id="inputMat_tubo_s" 
                                            value={editedData.mat_tubo_s} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="PVC" value="PVC">PVC</option>
                                            <option key="Manilha Cerâmica" value="Manilha Cerâmica">Manilha Cerâmica</option>
                                            <option key="Amianto" value="Amianto">Amianto</option>
                                            <option key="Manilha Concreto" value="Manilha Concreto">Manilha Concreto</option>
                                            <option key="PEAD" value="PEAD">PEAD</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*DIAMETRO DO TUBO S1*/}
                                <Col xxl={3}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="d_tubo_s1">DIAMETRO DO TUBO SAÍDA 1:</Label>
                                        <Input className="form-control-sm" type="select" name="d_tubo_s1" id="inputD_tubo_s1" 
                                            value={editedData.d_tubo_s1} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="100mm" value="100mm">100mm</option>
                                            <option key="150mm" value="150mm">150mm</option>
                                            <option key="200mm" value="200mm">200mm</option>
                                            <option key="250mm" value="250mm">250mm</option>
                                            <option key="300mm" value="300mm">300mm</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*METERIAL DO TUBO 1*/}
                                <Col xxl={3}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="mat_tubo_1">METERIAL DO TUBO SAÍDA 1:</Label>
                                        <Input className="form-control-sm" type="select" name="mat_tubo_1" id="inputMat_tubo_1" 
                                            value={editedData.mat_tubo_1} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="PVC" value="PVC">PVC</option>
                                            <option key="Manilha Cerâmica" value="Manilha Cerâmica">Manilha Cerâmica</option>
                                            <option key="Amianto" value="Amianto">Amianto</option>
                                            <option key="Manilha Concreto" value="Manilha Concreto">Manilha Concreto</option>
                                            <option key="PEAD" value="PEAD">PEAD</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*DIAMETRO DO TUBO S2*/}
                                <Col xxl={3}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="d_tubo_s2">DIAMETRO DO TUBO SAÍDA 2:</Label>
                                        <Input className="form-control-sm" type="select" name="d_tubo_s2" id="inputD_tubo_s2" 
                                            value={editedData.d_tubo_s2} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="100mm" value="100mm">100mm</option>
                                            <option key="150mm" value="150mm">150mm</option>
                                            <option key="200mm" value="200mm">200mm</option>
                                            <option key="250mm" value="250mm">250mm</option>
                                            <option key="300mm" value="300mm">300mm</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*FONTE COMP*/}
                                <Col xxl={4}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="font_comp">FONTE COMP:</Label>
                                        <Input type="text" className="form-control form-control-sm" name="font_comp"
                                            id="font_comp" value={editedData.font_comp} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*FONTE DA GEOMET*/}
                                <Col xxl={4}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="font_geom">FONTE DA GEOMET:</Label>
                                        <Input type="text" className="form-control form-control-sm" name="font_geom"
                                            id="font_geom" value={editedData.font_geom} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*ORIGEM DOS ARQUIVOS*/}
                                <Col xxl={4}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="orig_arq">ORIGEM DOS ARQUIVOS:</Label>
                                        <Input type="text" className="form-control form-control-sm" name="orig_arq"
                                            id="orig_arq" value={editedData.orig_arq} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*PATH IMAGEM*/}
                                <Col xxl={5}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="pathImg">PATH IMAGEM:</Label>
                                        <Input type="text" className="form-control form-control-sm" name="pathImg"
                                            id="pathImg" value={editedData.pathImg} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*LATITUDE*/}
                                <Col xxl={3}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="latitude">LATITUDE:</Label>
                                        <Input type="number" className="form-control form-control-sm" name="latitude"
                                            id="latitude" value={editedData.latitude} onChange={input_coord} />
                                    </div>
                                </Col>
                                {/*LONGITUDE*/}
                                <Col xxl={3}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="longitude">LONGITUDE:</Label>
                                        <Input type="number" className="form-control form-control-sm" name="longitude"
                                            id="longitude" value={editedData.longitude} onChange={input_coord} />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
                <hr />
                <ModalFooter>
                    <Button color="primary" onClick={handleSave}>
                        Salvar
                    </Button>{' '}
                    <Button color="danger" onClick={toggleModal}>
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default EditModalCiCad;
