export const DictRedeCad = {

    'Rede': 'Rede',
    "id_cad":{
        "label":"ID DA REDE",
        "type":"text"
    },
    "sub_bacia":{
        "label":"SUB-BACIA",
        "type":"text"
    },
    "elevatoria":{
        "label":"ELEVATÓRIA",
        "type":"text"
    },
    "ete":{
        "label":"ETE",
        "type":"text"
    },
    "regional":{
        "label":"REGIONAL",
        "type":"text"
    },
    "codope_esg":{
        "label":"CÓDIGO OPERACIONAL",
        "type":"text"
    },
    "operacao":{
        "label":"OPERAÇÃO",
        "type":"text"
    },
    "tipo":{
        "label":"TIPO DO TRECHO",
        "type":"text"
    },
    "posicao":{
        "label":"POSIÃO",
        "type":"text"
    },
    "pontoa":{
        "label":"PONTO A",
        "type":"text"
    },
    "pontob":{
        "label":"PONTO B",
        "type":"text"
    },
    "loctrecpa":{
        "label":"LOCAL TRECHO PA",
        "type":"text"
    },
    "loctrecpb":{
        "label":"LOCAL TRECHO PB",
        "type":"text"
    },
    "mat_tub":{
        "label":"MATERIAL DO TUBO",
        "type":"text"
    },
    "tipo_tub":{
        "label":"TIPO DO TUBO",
        "type":"text"
    },
    "d_gal_ret":{
        "label":"D GAL RET",
        "type":"text"
    },
    "dim_tub":{
        "label":"DIM TUBO",
        "type":"text"
    },
    "sent_fluxo":{
        "label":"SENTIDO FLUXO",
        "type":"text"
    },
    "stat_disp":{
        "label":"STATUS DISP",
        "type":"text"
    },
    "extensao_m":{
        "label":"EXTENSAO M",
        "type":"text"
    },
    "data_dado":{
        "label":"DATA DOS DADOS",
        "type":"text"
    },
    "fonte_dado":{
        "label":"FONTE DADO",
        "type":"text"
    },
    "uf":{
        "label":"UF",
        "type":"text"
    },
    "cidade":{
        "label":"CIDADE",
        "type":"text"
    },
    "bairro":{
        "label":"BAIRRO",
        "type":"text"
    },
    "logradouro":{
        "label":"LOGRADOURO",
        "type":"text"
    },
    "created_at":{
        "label":"DATA DE CRIAÇÃO",
        "type":"text"
    },
    "updated_at":{
        "label":"ULTIMA MODIFICAÇÃO",
        "type":"text"
    },
}
