import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Col, Container, Row, Input, Label } from 'reactstrap';
import Iframe from 'react-iframe'
import Loader from '../../../Components/Common/Loader';
import FeatherIcon from "feather-icons-react";
import { useSelector } from 'react-redux';

// IframeWithLoading component remains the same as in the previous version
const IframeWithLoading = React.memo(({ src, title, width, height, keyDash }) => {
    const [loading, setLoading] = useState(true);
    const layoutModeType = useSelector(state => state['Layout']['layoutModeType']);

    const iframeSrc = useMemo(() => {
        const theme = layoutModeType === 'dark' ? 'night' : 'light';
        return `${src}?tab=2-externo#theme=${theme}&bordered=false&titled=false`;
    }, [src, layoutModeType]);

    const handleLoad = useCallback(() => {
        setLoading(false);
    }, []);

    return (
        <div>
            <Col xxl={12} className="w-100 h-100 bg-light">
                {loading && (
                    <div
                        style={{
                            position: 'absolute',
                            top: '20%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 1,
                        }}
                    >
                        <FeatherIcon
                            icon="clock"
                            className="text-info"
                        />
                        {" "}Buscando Informações...{" "}
                        <br />
                        <Loader />
                    </div>
                )}
                <Iframe
                    key={`${keyDash}-${layoutModeType}`}
                    src={iframeSrc}
                    title={title}
                    width={width}
                    height={height}
                    sandbox=""
                    frameBorder="0"
                    onLoad={handleLoad}
                    style={{ display: loading ? 'none' : 'block' }}
                    allowTransparency
                />
            </Col>
        </div>
    );
});

const Dashboards = () => {
    document.title = "Dashboards | SANAPP";

    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubcategory, setSelectedSubcategory] = useState('');
    const [selectedDashboard, setSelectedDashboard] = useState(null);
    const [keyDash, setKeyDash] = useState(0);

    // Define categories and subcategories
    const categories = useMemo(() => [
        { id: 'agua', name: 'Água' },
        { id: 'esgoto', name: 'Esgoto' },
        { id: 'drenagem', name: 'Drenagem' }
    ], []);

    const subcategories = useMemo(() => ({
        agua: [
            { id: 'cadastro', name: 'Cadastro' },
            { id: 'status', name: 'Status' },
            { id: 'os', name: 'Ordens de Serviço' }
        ],
        esgoto: [
            { id: 'cadastro', name: 'Cadastro' },
            { id: 'status', name: 'Status' },
            { id: 'os', name: 'Ordens de Serviço' }
        ],
        drenagem: [
            { id: 'cadastro', name: 'Cadastro' },
            { id: 'status', name: 'Status' },
            { id: 'os', name: 'Ordens de Serviço' }
        ]
    }), []);

    const dashboardOptions = useMemo(() => [
        {
            "id": 1,
            "name": "Cadastros - Caixas de Inspeção",
            "base_url": "https://metabase.apisanapp.com.br/",
            "categoria": "esgoto",
            "subcategoria": "cadastro",
            "perfil_usuario": "gestor",
            "dir": "public",
            "tipo": "dashboard",
            "plataforma": "mobile",
            "cod_metabase": "0581b93e-293b-407c-9248-292d48dd623c",
            "show_titled": "false",
            "bordered": "false",
            "tab": "2-externo",
            "theme": "light",
            "width": "100%",
            "height": "910",
            "updated_at": "2024-08-14T11:15:26.378511-03:00"
        },
        {
            "id": 2,
            "name": "Status - Caixa de Inspeção",
            "base_url": "https://metabase.apisanapp.com.br/",
            "categoria": "esgoto",
            "subcategoria": "status",
            "perfil_usuario": "gestor",
            "dir": "public",
            "tipo": "dashboard",
            "plataforma": "mobile",
            "cod_metabase": "4117ae34-b4e3-4466-9db4-c39589c2c09c",
            "show_titled": "false",
            "bordered": "false",
            "tab": "2-externo",
            "theme": "light",
            "width": "100%",
            "height": "910",
            "updated_at": "2024-08-14T11:17:40.135096-03:00"
        },
        {
            "id": 3,
            "name": "Cadastros - Poços de Visita",
            "base_url": "https://metabase.apisanapp.com.br/",
            "categoria": "esgoto",
            "subcategoria": "cadastro",
            "perfil_usuario": "gestor",
            "dir": "public",
            "tipo": "dashboard",
            "plataforma": "mobile",
            "cod_metabase": "084c0ba7-1651-4249-a74b-c175d398494e",
            "show_titled": "false",
            "bordered": "false",
            "tab": "2-externo",
            "theme": "light",
            "width": "100%",
            "height": "910",
            "updated_at": "2024-08-14T11:21:28.912334-03:00"
        },
        {
            "id": 4,
            "name": "Status - Poços de Visita",
            "base_url": "https://metabase.apisanapp.com.br/",
            "categoria": "esgoto",
            "subcategoria": "status",
            "perfil_usuario": "gestor",
            "dir": "public",
            "tipo": "dashboard",
            "plataforma": "mobile",
            "cod_metabase": "b9aaedf9-1a24-452d-8e72-d5c83333a8d4",
            "show_titled": "false",
            "bordered": "false",
            "tab": "2-externo",
            "theme": "light",
            "width": "100%",
            "height": "910",
            "updated_at": "2024-08-14T11:19:13.862803-03:00"
        },
        {
            "id": 5,
            "name": "Cadastros - Redes",
            "base_url": "https://metabase.apisanapp.com.br/",
            "categoria": "esgoto",
            "subcategoria": "cadastro",
            "perfil_usuario": "gestor",
            "dir": "public",
            "tipo": "dashboard",
            "plataforma": "mobile",
            "cod_metabase": "282965ec-4e67-4f1e-856f-e712b31e11a1",
            "show_titled": "false",
            "bordered": "false",
            "tab": "2-externo",
            "theme": "light",
            "width": "100%",
            "height": "910",
            "updated_at": "2024-08-14T11:20:07.289441-03:00"
        },
        {
            "id": 6,
            "name": "Status - Redes",
            "base_url": "https://metabase.apisanapp.com.br/",
            "categoria": "esgoto",
            "subcategoria": "status",
            "perfil_usuario": "gestor",
            "dir": "public",
            "tipo": "dashboard",
            "plataforma": "mobile",
            "cod_metabase": "6ea147c2-a23b-482b-928f-d24f647e5d8b",
            "show_titled": "false",
            "bordered": "false",
            "tab": "2-externo",
            "theme": "light",
            "width": "100%",
            "height": "910",
            "updated_at": "2024-08-14T11:20:59.919969-03:00"
        },
        {
            "id": 7,
            "name": "SANEPAR - Observações PVs",
            "base_url": "https://metabase.apisanapp.com.br/",
            "categoria": "esgoto",
            "subcategoria": "cadastro",
            "perfil_usuario": "Gestor",
            "dir": "public",
            "tipo": "dashboard",
            "plataforma": "mobile",
            "cod_metabase": "4e068dec-8d5d-40a8-8678-bd280c745dad",
            "show_titled": "false",
            "bordered": "false",
            "tab": "2-externo",
            "theme": "light",
            "width": "100%",
            "height": "910",
            "updated_at": "2024-11-04T17:04:15.933758-03:00"
        },
        // ... (other dashboard options)
    ], []);

    // Filter dashboards based on selected category and subcategory
    const filteredDashboards = useMemo(() => {
        return dashboardOptions.filter(dashboard => 
            (!selectedCategory || dashboard.categoria === selectedCategory) &&
            (!selectedSubcategory || dashboard.subcategoria === selectedSubcategory)
        );
    }, [dashboardOptions, selectedCategory, selectedSubcategory]);

    // Reset selections when changing category
    const handleCategoryChange = useCallback((event) => {
        const newCategory = event.target.value;
        setSelectedCategory(newCategory);
        setSelectedSubcategory('');
        setSelectedDashboard(null);
        setKeyDash(prev => prev + 1);
    }, []);

    // Update subcategory selection
    const handleSubcategoryChange = useCallback((event) => {
        const newSubcategory = event.target.value;
        setSelectedSubcategory(newSubcategory);
        setSelectedDashboard(null);
        setKeyDash(prev => prev + 1);
    }, []);

    // Handle dashboard selection
    const handleDashboardChange = useCallback((event) => {
        const selected = filteredDashboards.find(option => option.id === parseInt(event.target.value));
        setSelectedDashboard(selected);
        setKeyDash(prev => prev + 1);
    }, [filteredDashboards]);

    // Set initial dashboard when filters change
    useEffect(() => {
        if (filteredDashboards.length > 0 && !selectedDashboard) {
            setSelectedDashboard(filteredDashboards[0]);
        }
    }, [filteredDashboards, selectedDashboard]);

    return (
        <React.Fragment>
        <div className="page-content">

            <Container fluid>
                <Row className="mt-2">
                    <Col md={4}>
                        <Label className="form-label fs-12 mb-1" for="categorySelect">
                            Categoria:
                        </Label>
                        <Input
                            className="form-control-sm fs-14"
                            type="select"
                            name="categorySelect"
                            id="categorySelect"
                            value={selectedCategory}
                            onChange={handleCategoryChange}
                        >
                            <option value="">Selecione uma categoria</option>
                            {categories.map((category) => (
                                <option key={category.id} value={category.id}>
                                    {category.name}
                                </option>
                            ))}
                        </Input>
                    </Col>
                    <Col md={4}>
                        <Label className="form-label fs-12 mb-1" for="subcategorySelect">
                            Subcategoria:
                        </Label>
                        <Input
                            className="form-control-sm fs-14"
                            type="select"
                            name="subcategorySelect"
                            id="subcategorySelect"
                            value={selectedSubcategory}
                            onChange={handleSubcategoryChange}
                            disabled={!selectedCategory}
                        >
                            <option value="">Selecione uma subcategoria</option>
                            {selectedCategory && subcategories[selectedCategory].map((subcat) => (
                                <option key={subcat.id} value={subcat.id}>
                                    {subcat.name}
                                </option>
                            ))}
                        </Input>
                    </Col>
                    <Col md={4}>
                        <Label className="form-label fs-12 mb-1" for="dashboardSelect">
                            Dashboard:
                        </Label>
                        <Input 
                            className="form-control-sm fs-14"
                            type="select"
                            name="dashboardSelect"
                            id="dashboardSelect"
                            value={selectedDashboard ? selectedDashboard.id : ''}
                            onChange={handleDashboardChange}
                            disabled={!selectedSubcategory}
                        >
                            <option value="">Selecione um dashboard</option>
                            {filteredDashboards.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                        </Input>
                    </Col>
                </Row>
                <Row className="mt-4">
                    {selectedDashboard && (
                        <IframeWithLoading
                            src={`${selectedDashboard.base_url}${selectedDashboard.dir}/${selectedDashboard.tipo}/${selectedDashboard.cod_metabase}`}
                            title={selectedDashboard.name}
                            width={selectedDashboard.width}
                            height={selectedDashboard.height}
                            keyDash={keyDash}
                        />
                    )}
                </Row>
            </Container>

        </div>

        </React.Fragment>
    );
};

export default Dashboards;