import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { NEW_PASSWORD } from "./actionTypes";
import { userNewPasswordSuccess, userNewPasswordError } from "./actions";

//Include Both Helper File with needed methods
import {
  postNewPwd,
  postJwtNewPwd,
} from "../../../helpers/backend_helper";


//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* newUser({ payload: { user, history } }) {
  try {

    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtNewPwd, user);
      yield put(userNewPasswordSuccess(response.message));
      setTimeout(() => {
        window.location.assign("/");
      }, 3000);

    } else if (process.env.REACT_APP_API_URL) {
      const response = yield call(postNewPwd, user);
      if (response) {
        yield put(userNewPasswordSuccess(response.data));
        setTimeout(() => {
          window.location.assign("/");
        }, 3000);
      }
    }

  } catch (error) {
    console.log(error)
    yield put(userNewPasswordError(error));
    setTimeout(() => {
      window.location.assign("/password-reset");
    }, 3000);
  }
}

export function* watchUserPasswordNew() {
  yield takeEvery(NEW_PASSWORD, newUser);
}

function* newPasswordSaga() {
  yield all([fork(watchUserPasswordNew)]);
}

export default newPasswordSaga;
