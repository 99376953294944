import {
  GET_CI_STATUS,
  GET_CI_STATUSFILTER,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_NEW_CI_STATUS,
  ADD_CI_STATUS_SUCCESS,
  ADD_CI_STATUS_FAIL,
  UPDATE_CI_STATUS,
  UPDATE_CI_STATUS_SUCCESS,
  UPDATE_CI_STATUS_FAIL,
  DELETE_CI_STATUS,
  DELETE_CI_STATUS_SUCCESS,
  DELETE_CI_STATUS_FAIL,
} from "./actionType";

// common success
export const CiStatusApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const CiStatusApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getCiStatus = (cistatus) => ({
  type: GET_CI_STATUS,
  payload: cistatus,
});

// common success
export const CiStatusFilterResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const CiStatusFilterResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getCiStatusfilter = cistatus => ({
  type: GET_CI_STATUSFILTER,
  payload: cistatus,
});

export const updateCiStatus = cistatus => ({
  type: UPDATE_CI_STATUS,
  payload: cistatus,
});

export const updateCiStatusSuccess = cistatus => ({
  type: UPDATE_CI_STATUS_SUCCESS,
  payload: cistatus,
});

export const updateCiStatusFail = error => ({
  type: UPDATE_CI_STATUS_FAIL,
  payload: error,
});

export const addNewCiStatus = cistatus => ({
  type: ADD_NEW_CI_STATUS,
  payload: cistatus,
});

export const addCiStatusSuccess = cistatus => ({
  type: ADD_CI_STATUS_SUCCESS,
  payload: cistatus,
});

export const addCiStatusFail = error => ({
  type: ADD_CI_STATUS_FAIL,
  payload: error,
});
export const deleteCiStatus = cistatus => ({
  type: DELETE_CI_STATUS,
  payload: cistatus,
});

export const deleteCiStatusSuccess = cistatus => ({
  type: DELETE_CI_STATUS_SUCCESS,
  payload: cistatus,
});

export const deleteCiStatusFail = error => ({
  type: DELETE_CI_STATUS_FAIL,
  payload: error,
});