export const DictPvCad = {

    "id_cad":{
        "label":"ID DO PV",
        "type":"text"
    },
    "sub_bacia":{
        "label":"SUB-BACIA",
        "type":"text"
    },
    "matric_edif":{
        "label":"MATRÍCULA EDIFÍCIO",
        "type":"text"
    },
    "inscr_imob":{
        "label":"INSCRIÇÃO IMOBILIÁRIA",
        "type":"text"
    },
    "tipo_pv":{
        "label":"TIPO DO PV",
        "type":"text"
    },
    "local_pv":{
        "label":"LOCAL DO PV",
        "type":"text"
    },
    "mat_tampa":{
        "label":"MATERIAL DA TAMPA",
        "type":"text"
    },
    "form_tampa":{
        "label":"FORMATO DA TAMPA",
        "type":"text"
    },
    "diam_tampa":{
        "label":"DIAMETRO DA TAMPA",
        "type":"text"
    },
    "tipo_pav":{
        "label":"TIPO DE PAVIMENTAÇÂO",
        "type":"text"
    },
    "prof_poco":{
        "label":"PROFUNDIDADE DO POÇO",
        "type":"text"
    },
    "mat_poco":{
        "label":"MATERIAL DO POÇO",
        "type":"text"
    },
    "form_poco":{
        "label":"FORMATO DO POÇO:",
        "type":"text"
    },
    "d_int_poco":{
        "label":"DIMENSÕES INTERNAS DO POÇO",
        "type":"text"
    },
    "d_poco_ret":{
        "label":"DIMENSÕES DO POÇO RET",
        "type":"text"
    },
    "qtd_tubo_s":{
        "label":"QUANT. TUBO SAÍDA",
        "type":"text"
    },
    "fonte":{
        "label":"FONTE",
        "type":"text"
    },
    "elevatoria":{
        "label":"ELEVATÓRIA",
        "type":"text"
    },
    "ete":{
        "label":"ETE",
        "type":"text"
    },
    "regional":{
        "label":"REGIONAL",
        "type":"text"
    },
    "codope_esg":{
        "label":"CÓDIGO OPERACIONAL",
        "type":"text"
    },
    "operacao":{
        "label":"OPERAÇÃO",
        "type":"text"
    },
    "tipo":{
        "label":"TIPO DO TRECHO",
        "type":"text"
    },
    "posicao":{
        "label":"POSIÃO",
        "type":"text"
    },
    "pontoa":{
        "label":"PONTO A",
        "type":"text"
    },
    "pontob":{
        "label":"PONTO B",
        "type":"text"
    },
    "loctrecpa":{
        "label":"LOCAL TRECHO PA",
        "type":"text"
    },
    "loctrecpb":{
        "label":"LOCAL TRECHO PB",
        "type":"text"
    },
    "mat_tub":{
        "label":"MATERIAL DO TUBO",
        "type":"text"
    },
    "tipo_tub":{
        "label":"TIPO DO TUBO",
        "type":"text"
    },
    "d_gal_ret":{
        "label":"D GAL RET",
        "type":"text"
    },
    "dim_tub":{
        "label":"DIM TUBO",
        "type":"text"
    },
    "sent_fluxo":{
        "label":"SENTIDO FLUXO",
        "type":"text"
    },
    "stat_disp":{
        "label":"STATUS DISP",
        "type":"text"
    },
    "extensao_m":{
        "label":"EXTENSAO M",
        "type":"text"
    },
    "data_dado":{
        "label":"DATA DOS DADOS",
        "type":"text"
    },
    "uf":{
        "label":"UF",
        "type":"text"
    },
    "cidade":{
        "label":"CIDADE",
        "type":"text"
    },
    "bairro":{
        "label":"BAIRRO",
        "type":"text"
    },
    "logradouro":{
        "label":"LOGRADOURO",
        "type":"text"
    },
    "n_edifica":{
        "label":"NUMERO DO EDIFICIO",
        "type":"text"
    },
    "created_at":{
        "label":"DATA DE CRIAÇÃO",
        "type":"text"
    },
    "updated_at":{
        "label":"ULTIMA MODIFICAÇÃO",
        "type":"text"
    },
}
