import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Table, UncontrolledCarousel } from 'reactstrap';
//import { Card, CardBody, Row, Col, Button, CardHeader, CardFooter, Label, Table, Input, Modal } from "reactstrap";

import { Swiper, SwiperSlide } from "swiper/react";

import SimpleBar from 'simplebar-react';
import { Pagination, Navigation } from "swiper";

import { useDispatch } from "react-redux";

import { updateCiStatus } from "../../../../Store/cistatusesgoto/action";
import EditModalCiStatus from "../Editar/EditModalCiStatus";

import img1 from "../../../../Assets/images/sanapp/CI01.jpeg";
import img2 from "../../../../Assets/images/sanapp/CI02.jpeg";
import img3 from "../../../../Assets/images/sanapp/CI03.jpeg";
import axios from 'axios';
import ImageComponent from './ImageComponent';




async function getimagefunc(url) {
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'image/avif,image/webp,image/apng,image/svg+xml,image/*,*/*;q=0.8',
                'Accept-Encoding': 'gzip, deflate, br, zstd',
                'Accept-Language': 'pt-BR,pt;q=0.9,en-US;q=0.8,en;q=0.7',
                'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36',
                'Referer': 'https://apisanapp.com.br/', // Ajuste o referer conforme necessário
                'Cookie': '__Secure-OSID=your-cookie; __Secure-3PAPISID=your-cookie; __Secure-3PSID=your-cookie; DRIVE_STREAM=your-cookie; NID=your-cookie; __Secure-3PSIDTS=your-cookie; __Secure-3PSIDCC=your-cookie'
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.blob();
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'imagemCi.jpg'; // Nome do arquivo para download
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(error => console.error('There has been a problem with your fetch operation:', error));

        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }

        //const blob = await response.blob();
        //return blob;
        const responsave = await response;
        return responsave;
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
        throw error;
    }
}


const CiStatus = ({ item, onSave }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [itemStatus, setItemStatus] = useState(...item);

    const dispatch = useDispatch();
    const [modal_center_ci, setmodal_center_ci] = useState(false);
    const tog_center_ci = () => setmodal_center_ci(!modal_center_ci);

    const openEditModal = () => setModalIsOpen(true);
    const toggleModal = () => setModalIsOpen(!modalIsOpen);

    const saveEditDataCiStatus = async (editedData) => {
        if (editedData.imagem) { delete editedData.imagem };
        const response = await dispatch(updateCiStatus(editedData));
    };

    const getimage = async (requestUrl) => {
        try {
            //const img_id = requestUrl ? requestUrl.replace("https://drive.google.com/uc?id=", "").replace("&export=download", "") : null;
            //const img_id = requestUrl.replace("https://drive.google.com/uc?id=", "").replace("&export=download", "");
            //const newUrl = `https://drive.usercontent.google.com/download?id=${img_id}&authuser=0`;
            //const response = await axios.get(newUrl, { responseType: 'blob' });
            const response = await axios.get(requestUrl, { responseType: 'blob' });
            console.log(response)
            const legendBlob = URL.createObjectURL(response.data);
            return response;
        } catch (error) {
            console.error('Error fetching legend:', error);
        }
    };

    return (
        <>
            <Button
                className="mt-3 start-25 shadow-lg p-2 px-4 ms-auto"
                color='primary'
                onClick={openEditModal}
            >
                STATUS
            </Button>
            <Modal isOpen={modalIsOpen} toggle={toggleModal} style={{ width: '800px', maxWidth: '100vw' }} centered>
                <ModalHeader toggle={toggleModal}>
                    <div className="justify-content-center">
                        CAIXA DE INSPEÇÃO - STATUS { }
                    </div>
                </ModalHeader>
                <hr />
                <ModalBody>
                    <Row>
                        <Col xl={12}>
                            <SimpleBar autoHide={false} className="simplebar-track-secondary" style={{ maxHeight: "250px" }}>
                                <Table className="table-sm table-hover table-striped align-middle table-nowrap mb-0">
                                    <tbody>
                                        <tr>
                                            <td className="fs-10">RESOLVIDA:</td>
                                            <td className="fs-10">{itemStatus?.resolvida}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">DATA RESOLUÇÃO:</td>
                                            <td className="fs-10">{itemStatus?.data_resol}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">PRIORIDADE:</td>
                                            <td className="fs-10">{itemStatus?.prioridade}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">RESPONSAVEL:</td>
                                            <td className="fs-10">{itemStatus?.responsave}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">CIDADE:</td>
                                            <td className="fs-10">{itemStatus?.cidade}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">BAIRRO:</td>
                                            <td className="fs-10">{itemStatus?.bairro}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">LOGRADOURO:</td>
                                            <td className="fs-10">{itemStatus?.logradouro}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">DATA DOS DADOS:</td>
                                            <td className="fs-10">{itemStatus?.data_dado}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">ID DO CI:</td>
                                            <td className="fs-10">{itemStatus?.id_ci}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">ID DO CI STATUS:</td>
                                            <td className="fs-10">{itemStatus?.id_cistatu}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">ABRASIVIDADE TAMPA:</td>
                                            <td className="fs-10">{itemStatus?.abras_tamp}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">ESTADO DA TAMPA:</td>
                                            <td className="fs-10">{itemStatus?.est_tampa}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">ESTADO PAVIMENTAÇÃO:</td>
                                            <td className="fs-10">{itemStatus?.est_pav}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">NÍVEL GREI:</td>
                                            <td className="fs-10">{itemStatus?.nivel_grei}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">ESTADO DA LAJE:</td>
                                            <td className="fs-10">{itemStatus?.est_laje}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">ESTADO ATENÇÃO:</td>
                                            <td className="fs-10">{itemStatus?.est_atenca}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">STATUS ACESSO:</td>
                                            <td className="fs-10">{itemStatus?.stat_acess}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">CARACTERÍSTICA ACABAMENTO EXT:</td>
                                            <td className="fs-10">{itemStatus?.c_acab_ext}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">CARACTERÍSTICA ACABAMENTO INT:</td>
                                            <td className="fs-10">{itemStatus?.c_acab_int}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">JUN EDIFÍCIO CI:</td>
                                            <td className="fs-10">{itemStatus?.jun_edi_ci}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">JUN CI RAM:</td>
                                            <td className="fs-10">{itemStatus?.jun_ci_ram}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">INFILTRAÇÃO:</td>
                                            <td className="fs-10">{itemStatus?.infiltra}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">INFILTRAÇÃO PARAS:</td>
                                            <td className="fs-10">{itemStatus?.inf_paras}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">STATUS ESCOAMENTO:</td>
                                            <td className="fs-10">{itemStatus?.stat_escoa}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">STATUS DISP:</td>
                                            <td className="fs-10">{itemStatus?.stat_disp}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">FONT COMP:</td>
                                            <td className="fs-10">{itemStatus?.font_comp}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">FONT GEOM:</td>
                                            <td className="fs-10">{itemStatus?.font_geom}</td>
                                        </tr>
                                        <tr>
                                            <td className="fs-10">ORIGEM ARQUIVOS:</td>
                                            <td className="fs-10">{itemStatus?.orig_arq}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </SimpleBar>
                        </Col>
                    </Row>
                </ModalBody>
                <hr />
                <ModalFooter>
                    <Modal
                        isOpen={modal_center_ci}
                        toggle={() => {
                            tog_center_ci();
                        }}
                        size="md"
                        centered
                    >
                        <Row className="m-3">
                            <h4 className="card-title mb-0">FOTOS DA CAIXA DE INSPEÇÃO</h4>
                            <p className="text-muted">Abaixo as imagens disponíveis em nosso sistema:</p>
                            <UncontrolledCarousel
                                interval={4000}
                                items={[
                                    {
                                        altText: " ",
                                        caption: " ",
                                        key: 1,
                                        src: img1,
                                    },
                                    {
                                        altText: " ",
                                        caption: " ",
                                        key: 2,
                                        src: img2,
                                        //src: itemStatus?.img_path_ext_int,
                                    },
                                    {
                                        altText: " ",
                                        caption: " ",
                                        key: 3,
                                        src: img3,
                                        //src: itemStatus?.img_path_ext_int ? "https://drive.google.com/uc?id=1oMjhj3IZspZqu3vMq7o5kSN2YVaQajbP&export=download" : img1,
                                    },
                                        /*
                                    {
                                        altText: " ",
                                        caption: " ",
                                        key: 4,
                                        src: itemStatus?.img_path_ext_int.replace("&export=download", ""),
                                    },
                                    {
                                        altText: " ",
                                        caption: " ",
                                        key: 5,
                                        src: itemStatus?.img_path_ext_int.replace("https://drive.google.com/uc?id=", "https://drive.usercontent.google.com/download?id=").replace("&export=download", "&authuser=0"),
                                    },
                                    */
                                    /*
                                    {
                                        altText: " ",
                                        caption: " ",
                                        key: 6,
                                        src: getimage(item.img_path_ext_int),
                                    },
                                    {
                                        altText: " ",
                                        caption: " ",
                                        key: 7,
                                        src: getimagefunc(item.img_path_ext_int),
                                    },
                                    {
                                        altText: " ",
                                        caption: " ",
                                        key: 8,
                                        src: getimagefunc(itemStatus?.img_path_ext_int.replace("https://drive.google.com/uc?id=", "https://drive.usercontent.google.com/download?id=").replace("&export=download", "&authuser=0")),
                                    },
                                    */
                                ]}
                            />
                        </Row>
                    </Modal>

                    <div className="hstack gap-4">
                        <Button
                            className="mt-3 shadow-lg p-2 px-2"
                            color='success'
                        >ABRIR ORDEM DE SERVIÇO</Button>
                        {' '}
                        <Button
                            className="mt-3 shadow-lg p-2 px-5"
                            color='primary'
                            onClick={() => tog_center_ci()}
                        >FOTOS</Button>
                        <EditModalCiStatus data={itemStatus} onSave={saveEditDataCiStatus} />
                        {' '}
                        <Button
                            className="mt-3 shadow-lg p-2 px-5"
                            color="danger"
                            onClick={toggleModal}>
                            VOLTAR
                        </Button>
                    </div>

                </ModalFooter>
            </Modal>
        </>
    );
};

export default CiStatus;
