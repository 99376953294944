import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label, Row, Col } from 'reactstrap';

const EditModalPvCad = ({ coord, data, onSave }) => {
    const [editedData, setEditedData] = useState(data);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openEditModal = () => setModalIsOpen(true);
    const toggleModal = () => setModalIsOpen(!modalIsOpen);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedData({ ...editedData, [name]: value });
    };
    
    const input_coord = (e) => {
        const name = e.target[0];
        const coordenad = { 
            latitude:coord[0], 
            longitude:coord[1] 
        };
        setEditedData({ ...editedData, [name]: coordenad[`${name}`] });
    };

    const handleSave = () => {
        onSave(editedData);
        toggleModal();
    };

    return (
        <>
            <Button
                className="mt-3 start-25 shadow-lg p-2 px-4 ms-auto"
                color='secondary'
                onClick={openEditModal}
            >
                EDITAR
            </Button>
            <Modal isOpen={modalIsOpen} toggle={toggleModal} style={{ width: '1024px', maxWidth: '100vw' }} centered>
                <ModalHeader toggle={toggleModal}>
                    <div className="justify-content-center">
                        Editar dados do cadastro do Poço de Visita
                    </div>
                </ModalHeader>
                <hr />
                <ModalBody>
                    <Row>
                        <Col xxl={12}>
                            <Row className="gy-0">
                                {/*ID PV*/}
                                <Col xxl={1}>
                                    <Label className="form-label mt-0 fs-12 mb-0" for="id_pv">ID PV:</Label>
                                    <Input type="text" className="form-control form-control-sm" name="id_pv"
                                        id="id_pv" value={editedData.id_pv} onChange={handleInputChange} />
                                </Col>
                                {/*LOGRADOURO*/}
                                <Col xxl={4}>
                                    <Label className="form-label mt-0 fs-12 mb-0" for="logradouro">LOGRADOURO:</Label>
                                    <Input type="text" className="form-control form-control-sm" name="logradouro"
                                        id="logradouro" value={editedData.logradouro} onChange={handleInputChange} />
                                </Col>
                                {/*No. EDIFÍCIO*/}
                                <Col xxl={2}>
                                    <Label className="form-label mt-0 fs-12 mb-0" for="n_edifica">No. EDIFÍCIO:</Label>
                                    <Input type="number" className="form-control form-control-sm" name="n_edifica"
                                        id="n_edifica" value={editedData.n_edifica} onChange={handleInputChange} />
                                </Col>
                                {/*BAIRRO*/}
                                <Col xxl={3}>
                                    <Label className="form-label mt-0 fs-12 mb-0" for="bairro">BAIRRO:</Label>
                                    <Input type="text" className="form-control form-control-sm" name="bairro"
                                        id="bairro" value={editedData.bairro} onChange={handleInputChange} />
                                </Col>
                                {/*CIDADE*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-0 fs-12 mb-0" for="cidade">CIDADE:</Label>
                                        <Input type="text" className="form-control form-control-sm" name="cidade"
                                            id="cidade" value={editedData.cidade} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*DATA DOS DADOS*/}
                                <Col xxl={3}>
                                    <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="data_dado">DATA DOS DADOS:</Label>
                                    <Input type="date" className="form-control form-control-sm" name="data_dado"
                                        id="data_dado" value={editedData.data_dado} onChange={handleInputChange} />
                                </Col>
                                {/*TIPO PV*/}
                                <Col xxl={3}>
                                    <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="tipo_pv" >TIPO PV:</Label>
                                    <Input className="form-control-sm" type="select" name="tipo_pv" id="inputTipo_pv" 
                                        value={editedData.tipo_pv} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Poço" value="Poço">Poço</option>
                                            <option key="TIL Radial" value="TIL Radial">TIL Radial</option>
                                            <option key="TIL de Passagem" value="TIL de Passagem">TIL de Passagem</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                    </Input>
                                </Col>
                                {/*LOCAL DO PV*/}
                                <Col xxl={3}>
                                    <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="local_pv">LOCAL DO PV:</Label>
                                    <Input className="form-control-sm" type="select" name="local_pv" id="inputLocalCi" 
                                        value={editedData.local_pv} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Passeio" value="Passeio">Passeio</option>
                                            <option key="Via" value="Via">Via</option>
                                            <option key="Terreno / Propriedade Privada" value="Terreno / Propriedade Privada">Terreno / Propriedade Privada</option>
                                            <option key="Servidão Sanitária" value="Servidão Sanitária">Servidão Sanitária</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                    </Input>
                                </Col>
                                {/*MATERIAL TAMPA*/}
                                <Col xxl={3}>
                                    <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="mat_tampa">MATERIAL TAMPA:</Label>
                                    <Input className="form-control-sm" type="select" name="mat_tampa" id="inputMat_tampa" 
                                        value={editedData.mat_tampa} onChange={handleInputChange}>
                                        <option selected>Selecionar</option>
                                        <option key="Ferro Fundido" value="Ferro Fundido">Ferro Fundido</option>
                                        <option key="Laje" value="Laje">Laje</option>
                                        <option key="Ferro Fundido + Laje" value="Ferro Fundido + Laje">
                                            Ferro Fundido + Laje</option>
                                        <option key="Plástico" value="Plástico">Plástico</option>
                                        <option key="Outros" value="Outros">Outros</option>
                                    </Input>
                                </Col>
                                {/*DIAM. DA TAMPA*/}
                                <Col xxl={2}>
                                    <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="diam_tampa">DIAM. DA TAMPA:</Label>
                                    <Input className="form-control-sm" type="select" name="diam_tampa" id="inputDiam_tampa" 
                                        value={editedData.diam_tampa} onChange={handleInputChange}>
                                        <option selected>Selecionar</option>
                                        <option key="400mm" value="400mm">400mm</option>
                                        <option key="600mm" value="600mm">600mm</option>
                                        <option key="800mm" value="800mm">800mm</option>
                                        <option key="1000mm" value="1000mm">1000mm</option>
                                        <option key="Outros" value="Outros">Outros</option>
                                    </Input>
                                </Col>
                                {/*PAVIMENTAÇÂO DA CALÇADA*/}
                                <Col xxl={3}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="tipo_pav">TIPO PAVIMENTAÇÂO:</Label>
                                        <Input className="form-control-sm" type="select" name="tipo_pav" id="inputTipo_pav" 
                                            value={editedData.tipo_pav} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Concreto" value="Concreto">Asfalto</option>
                                            <option key="Lajota Sextavada" value="Lajota Sextavada">Lajota Sextavada</option>
                                            <option key="Paralelepípedo / Pedra" value="Paralelepípedo / Pedra">Paralelepípedo / Pedra</option>
                                            <option key="Terreno Natural" value="Terreno Natural">Terreno Natural</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*PERFIL DO POÇO DE VISITA*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="perfil_pv">PERFIL DO POÇO:</Label>
                                        <Input className="form-control-sm" type="select" name="perfil_pv" id="inputPerfil_pv" 
                                            value={editedData.perfil_pv} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Padrão" value="Padrão">Padrão</option>
                                            <option key="Ponta Seca" value="Ponta Seca">Ponta Seca</option>
                                            <option key="Gradeamento Estação Elevatória" value="Gradeamento Estação Elevatória">Gradeamento Estação Elevatória</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*CONT VAZ C*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="cont_vaz_c">CONT VAZ C:</Label>
                                        <Input className="form-control-sm" type="select" name="cont_vaz_c" id="inputCont_vaz_c" 
                                            value={editedData.cont_vaz_c} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Não Possui" value="Não Possui">Não Possui</option>
                                            <option key="Outra sub-bacia" value="Outra sub-bacia">Outra sub-bacia</option>
                                            <option key="Edificação" value="Edificação">Edificação</option>
                                            <option key="Macro-contribuinte" value="Macro-contribuinte">Macro-contribuinte</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*PROFUND. DO POÇO*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="prof_poco">PROFUND. DO POÇO:</Label>
                                        <Input type="number" className="form-control form-control-sm" name="prof_poco"
                                            id="prof_poco" value={editedData.prof_poco} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*MATERIAL DO POÇO*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="mat_poco">MATERIAL DO POÇO:</Label>
                                        <Input className="form-control-sm" type="select" name="mat_poco" id="inputMat_poco" 
                                            value={editedData.mat_poco} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Tubo concreto pré-moldado" value="Tubo concreto pré-moldado">Tubo concreto pré-moldado</option>
                                            <option key="Bloco + Alvenaria" value="Bloco + Alvenaria">Bloco + Alvenaria</option>
                                            <option key="PEAD" value="PEAD">PEAD</option>
                                            <option key="Polietileno" value="Polietileno">Polietileno</option>
                                            <option key="PVC" value="PVC">PVC</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*FORMATO DO POÇO*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="form_poco">FORMATO DO POÇO:</Label>
                                        <Input className="form-control-sm" type="select" name="form_poco" id="inputForm_poco" 
                                            value={editedData.form_poco} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Circular" value="Circular">Circular</option>
                                            <option key="Retangular" value="Retangular">Retangular</option>
                                            <option key="Câmara (TIL)" value="Câmara (TIL)">Câmara (TIL)</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*DIAM. INT. DO POÇO*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="d_poco_cir">DIAM. DO POÇO CIR:</Label>
                                        <Input className="form-control-sm" type="select" name="d_poco_cir" id="inputD_int_poco" 
                                            value={editedData.d_poco_cir} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="400mm" value="400mm">400mm</option>
                                            <option key="600mm" value="600mm">600mm</option>
                                            <option key="800mm" value="800mm">800mm</option>
                                            <option key="1000mm" value="1000mm">1000mm</option>
                                            <option key="1200mm" value="1200mm">1200mm</option>
                                            <option key="1500mm" value="1500mm">1500mm</option>
                                            <option key="2000mm" value="2000mm">2000mm</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*DIAMETRO POÇO RET*/}
                                <Col xxl={3}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="d_poco_r">DIAMETRO DO POÇO R:</Label>
                                        <Input type="text" className="form-control form-control-sm" name="d_poco_r"
                                            id="d_poco_r" value={editedData.d_poco_r} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*METERIAL DO TUBO S*/}
                                <Col xxl={3}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="mat_tubo_s">METERIAL DO TUBO S:</Label>
                                        <Input className="form-control-sm" type="select" name="mat_tubo_s" id="inputMat_tubo_s" 
                                            value={editedData.mat_tubo_s} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="PVC" value="PVC">PVC</option>
                                            <option key="Manilha Cerâmica" value="Manilha Cerâmica">Manilha Cerâmica</option>
                                            <option key="Amianto" value="Amianto">Amianto</option>
                                            <option key="Manilha Concreto" value="Manilha Concreto">Manilha Concreto</option>
                                            <option key="PEAD" value="PEAD">PEAD</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*TIPO TUBO*/}
                                <Col xxl={3}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="tipo_tub">TIPO TUBO:</Label>
                                        <Input className="form-control-sm" type="select" name="tipo_tub" id="inputTipo_tub" 
                                            value={editedData.tipo_tub} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Circular" value="Circular">Circular</option>
                                            <option key="Retangular" value="Retangular">Retangular</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*DIAMETRO DO TUBO S1*/}
                                <Col xxl={3}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="d_tub_s">DIAMETRO DO TUBO S:</Label>
                                        <Input className="form-control-sm" type="select" name="d_tub_s" id="inputD_tubo_s1" 
                                            value={editedData.d_tub_s} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="100mm" value="100mm">100mm</option>
                                            <option key="150mm" value="150mm">150mm</option>
                                            <option key="200mm" value="200mm">200mm</option>
                                            <option key="250mm" value="250mm">250mm</option>
                                            <option key="300mm" value="300mm">300mm</option>
                                            <option key="350mm" value="350mm">350mm</option>
                                            <option key="400mm" value="400mm">400mm</option>
                                            <option key="500mm" value="500mm">500mm</option>
                                            <option key="600mm" value="600mm">600mm</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*D GAL RET*/}
                                <Col xxl={3}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="d_gal_ret">D GAL RET:</Label>
                                        <Input type="number" className="form-control form-control-sm" name="d_gal_ret"
                                            id="tipd_gal_reto_tub" value={editedData.d_gal_ret} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*FONTE COMP*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="font_comp">FONTE COMP:</Label>
                                        <Input type="text" className="form-control form-control-sm" name="font_comp"
                                            id="font_comp" value={editedData.font_comp} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*FONTE DA GEOMET*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="font_geom">FONTE DA GEOMET:</Label>
                                        <Input type="text" className="form-control form-control-sm" name="font_geom"
                                            id="font_geom" value={editedData.font_geom} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*ORIGEM DOS ARQUIVOS*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="orig_arq">ORIGEM DOS ARQUIVOS:</Label>
                                        <Input type="text" className="form-control form-control-sm" name="orig_arq"
                                            id="orig_arq" value={editedData.orig_arq} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*PATH IMAGEM*/}
                                <Col xxl={3}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="pathimgpv">PATH IMAGEM:</Label>
                                        <Input type="text" className="form-control form-control-sm" name="pathimg"
                                            id="pathimgpv" value={editedData.pathimg} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*LATITUDE*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="latitudepv">LATITUDE:</Label>
                                        <Input type="number" className="form-control form-control-sm" name="latitude"
                                            id="latitudepv" value={editedData.latitude} onChange={input_coord} />
                                    </div>
                                </Col>
                                {/*LONGITUDE*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="longitudepv">LONGITUDE:</Label>
                                        <Input type="number" className="form-control form-control-sm" name="longitude"
                                            id="longitudepv" value={editedData.longitude} onChange={input_coord} />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
                <hr />
                <ModalFooter>
                    <Button color="primary" onClick={handleSave}>
                        Salvar
                    </Button>{' '}
                    <Button color="danger" onClick={toggleModal}>
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default EditModalPvCad;
