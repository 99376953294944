import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// CAMGEO Redux States
import {
    GET_CAMGEO,
    ADD_NEW_CAMGEO,
    DELETE_CAMGEO,
    UPDATE_CAMGEO,
} from "./actionType";
import {
    CamGeoApiResponseSuccess, CamGeoApiResponseError,
    addCamGeoSuccess,
    addCamGeoFail,
    updateCamGeoSuccess,
    updateCamGeoFail,
    deleteCamGeoSuccess,
    deleteCamGeoFail,
} from "./action";

//Include Both Helper File with needed methods
import {
    getCamGeo as getCamGeoApi,
    addNewCamGeo,
    updateCamGeo,
    deleteCamGeo,
} from "../../helpers/backend_helper";

function* getCamGeo({ payload: camgeo }) {
    try {
        let allResults = [];
        let response = yield call(getCamGeoApi, camgeo);
        allResults = allResults.concat(response.results);
        while (response.next){
            // Obtém o número da próxima página
            const nextPage = response.next.split('=').pop();
            // Faz uma nova chamada para a próxima página
            response = yield call(getCamGeoApi, { ...camgeo, page: nextPage });
            // Adicionamos os resultados desta página à lista principal
            allResults = allResults.concat(response.results);
        }
        yield put(CamGeoApiResponseSuccess(GET_CAMGEO, allResults));
        toast.success("CamGeo Load Complete", { autoClose: 3000 });
    } catch (error) {
        //console.log(error);
        yield put(CamGeoApiResponseError(GET_CAMGEO, error));
    }
}

function* onAddNewCamGeo({ payload: camgeo }) {
    try {
        const response = yield call(addNewCamGeo, camgeo);
        yield put(addCamGeoSuccess(response));
        toast.success("CamGeo Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(addCamGeoFail(error));
        toast.error("CamGeo Added Failed", { autoClose: 3000 });
    }
}

function* onDeleteCamGeo({ payload: camgeo }) {
    try {
        const response = yield call(deleteCamGeo, camgeo);
        yield put(deleteCamGeoSuccess({ camgeo, ...response }));
        toast.success("CamGeo Delete Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(deleteCamGeoFail(error));
        toast.error("CamGeo Delete Failed", { autoClose: 3000 });
    }
}

function* onUpdateCamGeo({ payload: camgeo }) {
    try {
        const response = yield call(updateCamGeo, camgeo);
        yield put(updateCamGeoSuccess(response));
        toast.success("CamGeo Updated Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(updateCamGeoFail(error));
        toast.error("CamGeo Updated Failed", { autoClose: 3000 });
    }
}

export function* watchGetCamGeo() {
    yield takeEvery(GET_CAMGEO, getCamGeo);
}

export function* watchAddNewCamGeo() {
    yield takeEvery(ADD_NEW_CAMGEO, onAddNewCamGeo);
}

export function* watchUpdateCamGeo() {
    yield takeEvery(UPDATE_CAMGEO, onUpdateCamGeo);
}

export function* watchDeleteCamGeo() {
    yield takeEvery(DELETE_CAMGEO, onDeleteCamGeo);
}

function* camgeoSaga() {
    yield all([
        fork(watchGetCamGeo),
        fork(watchAddNewCamGeo),
        fork(watchUpdateCamGeo),
        fork(watchDeleteCamGeo)
    ]
    );
}

export default camgeoSaga;
