import {
  NEW_PASSWORD,
  NEW_PASSWORD_SUCCESS,
  NEW_PASSWORD_ERROR,
} from "./actionTypes";

export const userNewPassword = (user, history) => {
  return {
    type: NEW_PASSWORD,
    payload: { user, history },
  };
};

export const userNewPasswordSuccess = message => {
  return {
    type: NEW_PASSWORD_SUCCESS,
    payload: message,
  };
};

export const userNewPasswordError = message => {
  return {
    type: NEW_PASSWORD_ERROR,
    payload: message,
  };
};
