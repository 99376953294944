import React from 'react';
import IframeWithLoading from "../IframeWithLoading"


const DashboardPvCadastro = () => {
    document.title = "Dashboard Pv - Cadastro | SANAPP";
    return (
        <React.Fragment>
            <IframeWithLoading
                src="https://metabase.apisanapp.com.br/public/dashboard/084c0ba7-1651-4249-a74b-c175d398494e"
                title="Pv - Cadastro"
                width="100%"
                height="1180"
            />
        </React.Fragment>
    );
};

export default DashboardPvCadastro;