import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// REDE_STATUS Redux States
import {
    GET_REDE_STATUS,
    ADD_NEW_REDE_STATUS,
    DELETE_REDE_STATUS,
    UPDATE_REDE_STATUS,
} from "./actionType";
import {
    RedeStatusApiResponseSuccess, RedeStatusApiResponseError,
    addRedeStatusSuccess,
    addRedeStatusFail,
    updateRedeStatusSuccess,
    updateRedeStatusFail,
    deleteRedeStatusSuccess,
    deleteRedeStatusFail,
} from "./action";

//Include Both Helper File with needed methods
import {
    getRedeStatus as getRedeStatusApi,
    addNewRedeStatus,
    updateRedeStatus,
    deleteRedeStatus,
} from "../../helpers/backend_helper";

function* getRedeStatus({ payload: redestatus }) {
    try {
        let allResults = [];
        let response = yield call(getRedeStatusApi, redestatus);
        allResults = allResults.concat(response.results);
        while (response.next){
            // Obtém o número da próxima página
            const nextPage = response.next.split('=').pop();
            // Faz uma nova chamada para a próxima página
            response = yield call(getRedeStatusApi, { ...redestatus, page: nextPage });
            // Adicionamos os resultados desta página à lista principal
            allResults = allResults.concat(response.results);
        }
        yield put(RedeStatusApiResponseSuccess(GET_REDE_STATUS, allResults));
        //console.log(allResults);
    } catch (error) {
        //console.log(error);
        yield put(RedeStatusApiResponseError(GET_REDE_STATUS, error));
    }
}

function* onAddNewRedeStatus({ payload: redestatus }) {
    try {
        const response = yield call(addNewRedeStatus, redestatus);
        yield put(addRedeStatusSuccess(response));
        toast.success("RedeStatus Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(addRedeStatusFail(error));
        toast.error("RedeStatus Added Failed", { autoClose: 3000 });
    }
}

function* onDeleteRedeStatus({ payload: redestatus }) {
    try {
        const response = yield call(deleteRedeStatus, redestatus);
        yield put(deleteRedeStatusSuccess({ redestatus, ...response }));
        toast.success("RedeStatus Delete Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(deleteRedeStatusFail(error));
        toast.error("RedeStatus Delete Failed", { autoClose: 3000 });
    }
}

function* onUpdateRedeStatus({ payload: redestatus }) {
    try {
        const response = yield call(updateRedeStatus, redestatus);
        yield put(updateRedeStatusSuccess(response));
        toast.success("RedeStatus Updated Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(updateRedeStatusFail(error));
        toast.error("RedeStatus Updated Failed", { autoClose: 3000 });
    }
}

export function* watchGetRedeStatus() {
    yield takeEvery(GET_REDE_STATUS, getRedeStatus);
}

export function* watchAddNewRedeStatus() {
    yield takeEvery(ADD_NEW_REDE_STATUS, onAddNewRedeStatus);
}

export function* watchUpdateRedeStatus() {
    yield takeEvery(UPDATE_REDE_STATUS, onUpdateRedeStatus);
}

export function* watchDeleteRedeStatus() {
    yield takeEvery(DELETE_REDE_STATUS, onDeleteRedeStatus);
}

function* redestatusSaga() {
    yield all([
        fork(watchGetRedeStatus),
        fork(watchAddNewRedeStatus),
        fork(watchUpdateRedeStatus),
        fork(watchDeleteRedeStatus)
    ]
    );
}

export default redestatusSaga;
