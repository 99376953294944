import React from 'react';
import IframeWithLoading from "../IframeWithLoading"


const DashboardCasanBomAbrigo = () => {
    document.title = "Dashboard Casan - Bom Abrigo | SANAPP";

    return (
        <React.Fragment>
            <IframeWithLoading
                src="https://metabase.apisanapp.com.br/public/dashboard/df2d8d01-e681-4a08-b07e-29b55b7eb34c"
                title="Casan - Bom Abrigo"
                width="100%"
                height="870"
            />
        </React.Fragment>
    );
};

export default DashboardCasanBomAbrigo;