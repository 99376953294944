import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// CI_CADASTROS Redux States
import {
    GET_CI_CADASTROS,
    GET_CI_CADASTROSFILTER,
    ADD_NEW_CI_CADASTROS,
    DELETE_CI_CADASTROS,
    UPDATE_CI_CADASTROS,
} from "./actionType";
import {
    CiCadastrosApiResponseSuccess, CiCadastrosApiResponseError,
    CiCadastrosFilterResponseSuccess, CiCadastrosFilterResponseError,
    addCiCadastrosSuccess,
    addCiCadastrosFail,
    updateCiCadastrosSuccess,
    updateCiCadastrosFail,
    deleteCiCadastrosSuccess,
    deleteCiCadastrosFail,
} from "./action";

//Include Both Helper File with needed methods
import {
    getCiCadastros as getCiCadastrosApi,
    getCiCadastrosfilter,
    addNewCiCadastros,
    updateCiCadastros,
    deleteCiCadastros,
} from "../../helpers/backend_helper";


function* getCiCadastros({ payload: cicadastros }) {
    try {
        let allResults = [];
        let response = yield call(getCiCadastrosApi, cicadastros);
        allResults = allResults.concat(response.results);
        while (response.next){
            // Obtém o número da próxima página
            const nextPage = response.next.split('=').pop();
            // Faz uma nova chamada para a próxima página
            response = yield call(getCiCadastrosApi, { ...cicadastros, page: nextPage });
            // Adicionamos os resultados desta página à lista principal
            allResults = allResults.concat(response.results);
        }
        yield put(CiCadastrosApiResponseSuccess(GET_CI_CADASTROS, allResults));
    } catch (error) {
        yield put(CiCadastrosApiResponseError(GET_CI_CADASTROS, error));
    }
}

function* getCiCadastrosFilter({ payload: cicadastros }) {
    try {
        let allResults = [];
        let response = yield call(getCiCadastrosfilter, cicadastros);
        allResults = allResults.concat(response.results);
        while (response.next){
            // Obtém o número da próxima página
            const nextPage = response.next.split('=').pop();
            // Faz uma nova chamada para a próxima página
            response = yield call(getCiCadastrosfilter, { ...cicadastros, page: nextPage });
            // Adicionamos os resultados desta página à lista principal
            allResults = allResults.concat(response.results);
        }
        yield put(CiCadastrosFilterResponseSuccess(GET_CI_CADASTROSFILTER, allResults));
    } catch (error) {
        yield put(CiCadastrosFilterResponseError(GET_CI_CADASTROSFILTER, error));
    }
}

function* onAddNewCiCadastros({ payload: cicadastros }) {
    try {
        const response = yield call(addNewCiCadastros, cicadastros);
        yield put(addCiCadastrosSuccess(response));
        toast.success("CiCadastros Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(addCiCadastrosFail(error));
        toast.error("CiCadastros Added Failed", { autoClose: 3000 });
    }
}

function* onDeleteCiCadastros({ payload: cicadastros }) {
    try {
        const response = yield call(deleteCiCadastros, cicadastros);
        yield put(deleteCiCadastrosSuccess({ cicadastros, ...response }));
        toast.success("CiCadastros Delete Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(deleteCiCadastrosFail(error));
        toast.error("CiCadastros Delete Failed", { autoClose: 3000 });
    }
}

function* onUpdateCiCadastros({ payload: cicadastros }) {
    try {
        const response = yield call(updateCiCadastros, cicadastros);
        yield put(updateCiCadastrosSuccess(response));
        toast.success("CiCadastros Updated Successfully", { autoClose: 3000 });
    } catch (error) {
        console.log(error)
        yield put(updateCiCadastrosFail(error));
        toast.error("CiCadastros Updated Failed", { autoClose: 3000 });
    }
}

export function* watchGetCiCadastros() {
    yield takeEvery(GET_CI_CADASTROS, getCiCadastros);
}

export function* watchGetCiCadastrosFilter() {
    yield takeEvery(GET_CI_CADASTROSFILTER, getCiCadastrosFilter);
}

export function* watchAddNewCiCadastros() {
    yield takeEvery(ADD_NEW_CI_CADASTROS, onAddNewCiCadastros);
}

export function* watchUpdateCiCadastros() {
    yield takeEvery(UPDATE_CI_CADASTROS, onUpdateCiCadastros);
}

export function* watchDeleteCiCadastros() {
    yield takeEvery(DELETE_CI_CADASTROS, onDeleteCiCadastros);
}

function* cicadastrosSaga() {
    yield all([
        fork(watchGetCiCadastros),
        fork(watchGetCiCadastrosFilter),
        fork(watchAddNewCiCadastros),
        fork(watchUpdateCiCadastros),
        fork(watchDeleteCiCadastros)
    ]
    );
}

export default cicadastrosSaga;
