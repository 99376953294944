import React, { useEffect, useState } from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { PDFViewer, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Logo from "../../../../Assets/images/sanapp/logo-dark-mod2.png"
import { useSelector, useDispatch } from "react-redux";
import { getCiStatusfilter } from "../../../../Store/cistatusesgoto/action";


const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#F4F4F4',
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    header: {
        fontSize: 12,
        textAlign: 'center',
        fontWeight: 700,
        color: '#000',
        marginTop: 10,
        marginBottom: 10
    },
    table: {
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 0,
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#000',
    },
    tableHeader: {
        width: '100%',
        borderStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: '#000',
        borderRightWidth: 1,
        borderRightColor: '#000',
        padding: 5,
        fontSize: 12,
        textAlign: 'center',
        fontWeight: 700,
    },
    tableColHeader: {
        width: '50%',
        borderStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: '#000',
        borderRightWidth: 1,
        borderRightColor: '#000',
        padding: 5,
        fontSize: 8,
    },
    tableColData: {
        width: '50%',
        borderStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: '#000',
        padding: 5,
        fontSize: 8,
    },
    image: {
        width: '100%',
        height: '150',
        marginTop: 10,
    },
});

async function fetchImageAsBase64(imageUrl) {
    const response = await fetch(imageUrl, {
        mode: 'no-cors'
    });
    try {
        if (!response.ok) {
            throw new Error('Erro ao carregar a imagem');
        }

        const blob = await response.blob();
        const reader = new FileReader();

        console.log('Imagem em base64:', reader.readAsDataURL(blob));

        return new Promise((resolve) => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.readAsDataURL(blob);
        });
    } catch (error) {
        console.error('Erro ao baixar a imagem:', error);
        return null;
    }

    /*
    try {
        //axios.defaults.headers.common["Cache-Control"] = "no-cache";
        const response = await axios.get(imageUrl, { responseType: 'blob' }).then((response) => window.URL.createObjectURL(new Blob([response.data])));
        console.error('Baixou a imagem:', response);
        const imageBlob = new Blob([response.data], { type: response.headers['content-type'] });
        const base64Image = URL.createObjectURL(imageBlob);
        return `base64,${base64Image}`;
    } catch (error) {
        console.error('Erro ao baixar a imagem:', error);
        return null;
    }
    */
};

function PdfCiCad(data) {
    const [modalOpen, setModalOpen] = useState(false);
    const dispatch = useDispatch();
    const item = data.data;
    const [imageData, setImageData] = useState(null);

    // GET CI STATUS
    const { ciStatusFilter } = useSelector((state) => ({ ciStatusFilter: state.CiStatus.ciStatusFilter }));
    useEffect(() => {
        if (ciStatusFilter && !ciStatusFilter.length) {
            dispatch(getCiStatusfilter({ id_ci: item.id_ci }));
        }
    }, [dispatch, ciStatusFilter]);
    //console.log(ciStatusFilter)

    const MyDocument = () => (
        <Document title={'CAIXA DE INSPEÇÃO -' + item.id_ci} author="SANAPP">
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    {/* Criar cabeçario */}
                    <View style={styles.header} fixed>
                        <Image
                            src={Logo}
                            style={{ width: '30%' }}
                        />
                    </View>
                    {/* Cria o corpo da pagina */}
                    <View style={styles.table}>
                        {/* Titulo da Tabela */}
                        <View style={styles.tableRow}>
                            <View style={styles.tableHeader}>
                                <Text>CAIXA DE INSPEÇÃO - CADASTRO</Text>
                            </View>
                        </View>
                        {/* Dados da tabela*/}
                        <View style={styles.tableRow}  >
                            <View style={styles.tableColHeader}>
                                <Text>CIDADE</Text>
                            </View>
                            <View style={styles.tableColData}>
                                <Text>{item.cidade}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}  >
                            <View style={styles.tableColHeader}>
                                <Text>BAIRRO</Text>
                            </View>
                            <View style={styles.tableColData}>
                                <Text>{item.bairro}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}  >
                            <View style={styles.tableColHeader}>
                                <Text>LOGRADOURO</Text>
                            </View>
                            <View style={styles.tableColData}>
                                <Text>{item.logradouro}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}  >
                            <View style={styles.tableColHeader}>
                                <Text>NÚMERO DO EDIFÍCIO</Text>
                            </View>
                            <View style={styles.tableColData}>
                                <Text>{item.n_edificac}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}  >
                            <View style={styles.tableColHeader}>
                                <Text>MÊS</Text>
                            </View>
                            <View style={styles.tableColData}>
                                <Text>{item.mes}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}  >
                            <View style={styles.tableColHeader}>
                                <Text>ANO</Text>
                            </View>
                            <View style={styles.tableColData}>
                                <Text>{item.ano}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}  >
                            <View style={styles.tableColHeader}>
                                <Text>DATA DOS DADOS</Text>
                            </View>
                            <View style={styles.tableColData}>
                                <Text>{item.data_dado}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}  >
                            <View style={styles.tableColHeader}>
                                <Text>ID DO CI</Text>
                            </View>
                            <View style={styles.tableColData}>
                                <Text>{item.id_ci}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}  >
                            <View style={styles.tableColHeader}>
                                <Text>TIPO DO CI</Text>
                            </View>
                            <View style={styles.tableColData}>
                                <Text>{item.tipo_ci}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}  >
                            <View style={styles.tableColHeader}>
                                <Text>LOCAL DO CI</Text>
                            </View>
                            <View style={styles.tableColData}>
                                <Text>{item.local_ci}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}  >
                            <View style={styles.tableColHeader}>
                                <Text>MATERIAL DA TAMPA</Text>
                            </View>
                            <View style={styles.tableColData}>
                                <Text>{item.mat_tampa}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}  >
                            <View style={styles.tableColHeader}>
                                <Text>DIAMETRO DA TAMPA</Text>
                            </View>
                            <View style={styles.tableColData}>
                                <Text>{item.diam_tampa}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}  >
                            <View style={styles.tableColHeader}>
                                <Text>PAVIMENTAÇÂO DA CALCADA</Text>
                            </View>
                            <View style={styles.tableColData}>
                                <Text>{item.pav_calcad}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}  >
                            <View style={styles.tableColHeader}>
                                <Text>PROFUNDIDADE DO POÇO</Text>
                            </View>
                            <View style={styles.tableColData}>
                                <Text>{parseFloat(item.prof_poco).toFixed(2)} mts</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}  >
                            <View style={styles.tableColHeader}>
                                <Text>MATERIAL DO POÇO</Text>
                            </View>
                            <View style={styles.tableColData}>
                                <Text>{item.mat_poco}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}  >
                            <View style={styles.tableColHeader}>
                                <Text>FORMATO DO POÇO</Text>
                            </View>
                            <View style={styles.tableColData}>
                                <Text>{item.form_poco}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}  >
                            <View style={styles.tableColHeader}>
                                <Text>DIMENSÕES INTERNAS DO POÇO</Text>
                            </View>
                            <View style={styles.tableColData}>
                                <Text>{item.dim_int_po}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}  >
                            <View style={styles.tableColHeader}>
                                <Text>DIMENSÕES DO POÇO</Text>
                            </View>
                            <View style={styles.tableColData}>
                                <Text>{item.dim_poco_r}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}  >
                            <View style={styles.tableColHeader}>
                                <Text>MATERIAL DO TUBO</Text>
                            </View>
                            <View style={styles.tableColData}>
                                <Text>{item.mat_tubo_s}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}  >
                            <View style={styles.tableColHeader}>
                                <Text>DIMENSÕES TUBO</Text>
                            </View>
                            <View style={styles.tableColData}>
                                <Text>{item.dim_tubo_s}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}  >
                            <View style={styles.tableColHeader}>
                                <Text>FONTE COMP</Text>
                            </View>
                            <View style={styles.tableColData}>
                                <Text>{item.font_comp}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}  >
                            <View style={styles.tableColHeader}>
                                <Text>FONTE GEOMÉTRICA</Text>
                            </View>
                            <View style={styles.tableColData}>
                                <Text>{item.font_geom}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}  >
                            <View style={styles.tableColHeader}>
                                <Text>ORIGEM DO ARQUIVO</Text>
                            </View>
                            <View style={styles.tableColData}>
                                <Text>{item.orig_arq}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}  >
                            <View style={styles.tableColHeader}>
                                <Text>IMAGEM DISPONÍVEL</Text>
                            </View>
                            <View style={styles.tableColData}>
                                <Text>{item.imagem}</Text>
                            </View>
                        </View>
                    </View>

                    {ciStatusFilter && ciStatusFilter.length > 0 ?
                        <View style={styles.table} break>
                            {/* Titulo da Tabela */}
                            <View style={styles.tableRow}>
                                <View style={styles.tableHeader}>
                                    <Text>CAIXA DE INSPEÇÃO - STATUS</Text>
                                </View>
                            </View>
                            {/* Dados da tabela*/}
                            <View style={styles.tableRow}  >
                                <View style={styles.tableColHeader}>
                                    <Text>DATA DOS DADOS</Text>
                                </View>
                                <View style={styles.tableColData}>
                                    <Text>{ciStatusFilter[0].data_dado}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}  >
                                <View style={styles.tableColHeader}>
                                    <Text>ID DO CI</Text>
                                </View>
                                <View style={styles.tableColData}>
                                    <Text>{ciStatusFilter[0].id_ci}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}  >
                                <View style={styles.tableColHeader}>
                                    <Text>TIPO DO CI</Text>
                                </View>
                                <View style={styles.tableColData}>
                                    <Text>{ciStatusFilter[0].tipo_ci}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}  >
                                <View style={styles.tableColHeader}>
                                    <Text>LOCAL DO CI</Text>
                                </View>
                                <View style={styles.tableColData}>
                                    <Text>{ciStatusFilter[0].local_ci}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}  >
                                <View style={styles.tableColHeader}>
                                    <Text>MATERIAL DA TAMPA</Text>
                                </View>
                                <View style={styles.tableColData}>
                                    <Text>{ciStatusFilter[0].mat_tampa}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}  >
                                <View style={styles.tableColHeader}>
                                    <Text>DIAMETRO DA TAMPA</Text>
                                </View>
                                <View style={styles.tableColData}>
                                    <Text>{ciStatusFilter[0].diam_tampa}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}  >
                                <View style={styles.tableColHeader}>
                                    <Text>PAVIMENTAÇÂO DA CALCADA</Text>
                                </View>
                                <View style={styles.tableColData}>
                                    <Text>{ciStatusFilter[0].pav_calcad}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}  >
                                <View style={styles.tableColHeader}>
                                    <Text>PROFUNDIDADE DO POÇO</Text>
                                </View>
                                <View style={styles.tableColData}>
                                    <Text>{parseFloat(ciStatusFilter[0].prof_poco).toFixed(2)} mts</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}  >
                                <View style={styles.tableColHeader}>
                                    <Text>MATERIAL DO POÇO</Text>
                                </View>
                                <View style={styles.tableColData}>
                                    <Text>{ciStatusFilter[0].mat_poco}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}  >
                                <View style={styles.tableColHeader}>
                                    <Text>FORMATO DO POÇO</Text>
                                </View>
                                <View style={styles.tableColData}>
                                    <Text>{ciStatusFilter[0].form_poco}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}  >
                                <View style={styles.tableColHeader}>
                                    <Text>DIMENSÕES INTERNAS DO POÇO</Text>
                                </View>
                                <View style={styles.tableColData}>
                                    <Text>{ciStatusFilter[0].dim_int_po}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}  >
                                <View style={styles.tableColHeader}>
                                    <Text>DIMENSÕES DO POÇO</Text>
                                </View>
                                <View style={styles.tableColData}>
                                    <Text>{ciStatusFilter[0].dim_poco_r}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}  >
                                <View style={styles.tableColHeader}>
                                    <Text>MATERIAL DO TUBO</Text>
                                </View>
                                <View style={styles.tableColData}>
                                    <Text>{ciStatusFilter[0].mat_tubo_s}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}  >
                                <View style={styles.tableColHeader}>
                                    <Text>DIMENSÕES TUBO</Text>
                                </View>
                                <View style={styles.tableColData}>
                                    <Text>{ciStatusFilter[0].dim_tubo_s}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}  >
                                <View style={styles.tableColHeader}>
                                    <Text>FONTE COMP</Text>
                                </View>
                                <View style={styles.tableColData}>
                                    <Text>{ciStatusFilter[0].font_comp}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}  >
                                <View style={styles.tableColHeader}>
                                    <Text>FONTE GEOMÉTRICA</Text>
                                </View>
                                <View style={styles.tableColData}>
                                    <Text>{ciStatusFilter[0].font_geom}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}  >
                                <View style={styles.tableColHeader}>
                                    <Text>ORIGEM DO ARQUIVO</Text>
                                </View>
                                <View style={styles.tableColData}>
                                    <Text>{ciStatusFilter[0].orig_arq}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}  >
                                <View style={styles.tableColHeader}>
                                    <Text>IMAGEM DISPONÍVEL</Text>
                                </View>
                                <View style={styles.tableColData}>
                                    <Text>sem url</Text>
                                </View>
                            </View>
                            {/* Exibe a imagem */}
                            <View style={styles.tableRow} >
                                <View style={styles.tableHeader}>
                                    {imageData &&
                                        <Image style={styles.image} source={() => imageData ? imageData : null} />
                                    }
                                </View>
                            </View>
                        </View>
                        :
                        <View style={styles.table} break>
                            {/* Titulo da Tabela */}
                            <View style={styles.tableRow}>
                                <View style={styles.tableHeader}>
                                    <Text>CAIXA DE INSPEÇÃO - STATUS</Text>
                                </View>
                            </View>
                            {/* Dados da tabela*/}
                            <View style={styles.tableRow}>
                                <View style={styles.tableHeader}>
                                    <Text>NÃO LOCALIZADO STATUS DESTA CAIXA DE INSPEÇÃO</Text>
                                </View>
                            </View>
                        </View>
                    }
                </View>
            </Page>
        </Document>
    );

    const toggleModal = () => setModalOpen(!modalOpen);

    const generatePdf = async () => {
        // Baixe a imagem usando axios (substitua pela URL correta)
        if (ciStatusFilter && ciStatusFilter[0].img_acab_i) {
            const base64Image = await fetchImageAsBase64(ciStatusFilter[0].img_acab_i);
            // Atualiza o campo imagem com o conteúdo base64
            if (base64Image) {
                setImageData(base64Image);  // Atualiza o campo imagem com o conteúdo base64
            }
        } else {
            setImageData(null);
        }


        toggleModal();
    };

    return (
        <div>
            <Button 
                className="mt-3 start-25 shadow-lg p-2 ms-auto"
                color="primary" 
                onClick={generatePdf}
                >
                Gerar PDF
            </Button>
            <Modal isOpen={modalOpen} toggle={toggleModal} style={{ width: '800px', maxWidth: '30vw' }}>
                <ModalHeader toggle={toggleModal}>Visualizar PDF</ModalHeader>
                <ModalBody>
                    <PDFViewer width="100%" height="700px">
                        <MyDocument />
                    </PDFViewer>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggleModal}>
                        Fechar
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};
export default PdfCiCad;
