import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import proj4 from 'proj4';
// Api Conection
import { useSelector, useDispatch } from "react-redux";
import { getCiCadastrosfilter } from "../../../Store/cicadesgoto/action";
import classnames from "classnames";
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane, Input, Label, Table, UncontrolledTooltip } from "reactstrap";


const SearchOption = () => {

    const dispatch = useDispatch();
    const [value, setValue] = useState("");
    const [keyword, setKeyword] = useState('');
    const [searchResult, setSearchResult] = useState([]);

    // GET CI CADASTROS
    const { ciCadastrosFilter } = useSelector((state) => ({ ciCadastrosFilter: state.CiCadastros.ciCadastrosFilter }));
    useEffect(() => {
        if (ciCadastrosFilter && !ciCadastrosFilter.length) {
            //dispatch(getCiCadastrosfilter({fid:10}));
            dispatch(getCiCadastrosfilter());
            setSearchResult(ciCadastrosFilter)
        }
    }, [dispatch, ciCadastrosFilter]);
    // console.log(ciCadastrosFilter);

    // Arrow Nav tabs
    const [arrowNavTab, setarrowNavTab] = useState("1");
    const arrowNavToggle = (tab) => {
        if (arrowNavTab !== tab) {
            setarrowNavTab(tab);
        }
    };

    // Custom Nav Tabs
    const [customNav, setcustomNav] = useState("1");
    const customNavtoggle = (tab) => {
        if (customNav !== tab) {
            setcustomNav(tab);
        }
    };

    const handleInputChange = (event) => {
        setKeyword(event.target.value);
    };

    const handleSearch = () => {
        if (keyword) {
            const filteredResult = ciCadastrosFilter.filter((item) => {
                // Realizar a lógica de pesquisa com base na palavra-chave e nos atributos desejados
                const id_ci = item.id_ci ? item.id_ci.toLowerCase() : '';
                const tipo_ci = item.tipo_ci ? item.tipo_ci.toLowerCase() : '';
                const local_ci = item.local_ci ? item.local_ci.toLowerCase() : '';

                return (
                    id_ci.includes(keyword.toLowerCase()) ||
                    tipo_ci.includes(keyword.toLowerCase()) ||
                    local_ci.includes(keyword.toLowerCase())
                );
            });
            console.log(filteredResult);
            setSearchResult(filteredResult)

            // Enviar os ciCadastrosFilter filtrados para a Store do Redux
            dispatch(getCiCadastrosfilter(filteredResult));
        }
    };

    const onChangeData = (value) => {
        setValue(value);
    };

    // How do search system?

    return (
        <React.Fragment>

            <div className="content">

                <Col xxl={12}>
                    <Card>
                        <CardBody>
                            <Nav pills className="nav nav-pills arrow-navtabs nav-success bg-light mb-3">
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: arrowNavTab === "1",
                                        })}
                                        onClick={() => {
                                            arrowNavToggle("1");
                                        }}
                                    >
                                        ESGOTO
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: arrowNavTab === "2",
                                        })}
                                        onClick={() => {
                                            arrowNavToggle("2");
                                        }}
                                    >
                                        DRENAGEM
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: arrowNavTab === "3",
                                        })}
                                        onClick={() => {
                                            arrowNavToggle("3");
                                        }}
                                    >
                                        ÁGUA
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: arrowNavTab === "4",
                                        })}
                                        onClick={() => {
                                            arrowNavToggle("4");
                                        }}
                                    >
                                        SÓLIDOS
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent
                                activeTab={arrowNavTab}
                                className="text-muted"
                            >
                                <TabPane tabId="1" id="arrow-esgoto">
                                    <Col xxl={12}>
                                        <Card>
                                            <CardBody>
                                                <Nav pills className="nav-customs nav-danger mb-3">
                                                    <NavItem>
                                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customNav === "1", })} onClick={() => { customNavtoggle("1"); }} >
                                                            Caixa de Inspeção - Cadastro
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customNav === "2", })} onClick={() => { customNavtoggle("2"); }} >
                                                            Caixa de Inspeção - Status
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customNav === "3", })} onClick={() => { customNavtoggle("3"); }} >
                                                            Poço de Visita - Cadastro
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customNav === "4", })} onClick={() => { customNavtoggle("4"); }} >
                                                            Poço de Visita - Status
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customNav === "5", })} onClick={() => { customNavtoggle("5"); }} >
                                                            Rede - Cadastro
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customNav === "6", })} onClick={() => { customNavtoggle("6"); }} >
                                                            Rede - Status
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>

                                                <TabContent activeTab={customNav} className="text-muted">
                                                    <TabPane tabId="1" id="border-navs-home">
                                                        <div className="tab-pane active" id="border-navs-home" role="tabpanel">
                                                            <form className="app-search d-none d-md-block">


                                                                <Row className="g-3">
                                                                    <p className="text-muted fw-medium">Selecione abaixo os parametros</p>
                                                                </Row>

                                                                <Row className="g-3">
                                                                    <Col lg={4}>
                                                                        <div className="input-group">
                                                                            <label className="input-group-text" for="inputGroupSelect02">01</label>
                                                                            <select className="form-select" id="inputGroupSelect02">
                                                                                <option selected>adicionar...</option>
                                                                                <option value="1">One</option>
                                                                                <option value="2">Two</option>
                                                                                <option value="3">Three</option>
                                                                            </select>
                                                                        </div>
                                                                    </Col>

                                                                    <Col lg={4}>
                                                                        <div className="input-group">
                                                                            <label className="input-group-text" for="inputGroupSelect02">02</label>
                                                                            <select className="form-select" id="inputGroupSelect02">
                                                                                <option selected>adicionar...</option>
                                                                                <option value="1">One</option>
                                                                                <option value="2">Two</option>
                                                                                <option value="3">Three</option>
                                                                            </select>
                                                                        </div>
                                                                    </Col>

                                                                    <Col lg={4}>
                                                                        <div className="input-group">
                                                                            <label className="input-group-text" for="inputGroupSelect02">03</label>
                                                                            <select className="form-select" id="inputGroupSelect02">
                                                                                <option selected>adicionar...</option>
                                                                                <option value="1">One</option>
                                                                                <option value="2">Two</option>
                                                                                <option value="3">Three</option>
                                                                            </select>
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                                <div className="text-center pt-3 pb-1">
                                                                    <Link to="/pages-search-results" className="btn btn-primary btn-sm"> Buscar <i
                                                                        className="ri-arrow-right-line ms-1"></i></Link>
                                                                </div>

                                                            </form>

                                                            <Row>
                                                                <Col xl={12}>
                                                                    <div className="table-responsive">
                                                                        <Table className="table-hover align-middle table-nowrap mb-0">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col" style={{ "width": "25px" }}>
                                                                                        <div className="form-check">
                                                                                            <Input className="form-check-input" type="checkbox" id="checkAll" defaultValue="option1" />
                                                                                        </div>
                                                                                    </th>
                                                                                    <th scope="col">Id</th>
                                                                                    <th scope="col">CIDADE</th>
                                                                                    <th scope="col">bairro</th>
                                                                                    <th scope="col">logradouro</th>
                                                                                    <th scope="col">mes</th>
                                                                                    <th scope="col">ano</th>
                                                                                    <th scope="col">fid</th>
                                                                                    <th scope="col">Id caixa inspeção</th>
                                                                                    <th scope="col">n_edificac</th>
                                                                                    <th scope="col">tipo caixa inspeção</th>
                                                                                    <th scope="col">local caixa inspeção</th>
                                                                                    <th scope="col">material tampa</th>
                                                                                    <th scope="col">diametro tampa</th>
                                                                                    <th scope="col">pav_calcad</th>
                                                                                    <th scope="col">prof_poco</th>
                                                                                    <th scope="col">mat_poco</th>
                                                                                    <th scope="col">form_poco</th>
                                                                                    <th scope="col">dim_int_po</th>
                                                                                    <th scope="col">dim_poco_r</th>
                                                                                    <th scope="col">mat_tubo_s</th>
                                                                                    <th scope="col">dim_tubo_s</th>
                                                                                    <th scope="col">data_dado</th>
                                                                                    <th scope="col">font_comp</th>
                                                                                    <th scope="col">font_geom</th>
                                                                                    <th scope="col">orig_arq</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>

                                                                                {ciCadastrosFilter.map((item) => (

                                                                                    <tr>
                                                                                        <th scope="row">
                                                                                            <div className="form-check">
                                                                                                <Input className="form-check-input" type="checkbox" id={`inlineCheckbox${item.id}`} value="option1" />
                                                                                            </div>
                                                                                        </th>
                                                                                        <td>#{item.id}</td>
                                                                                        <th scope="col">{item.cidade}</th>
                                                                                        <th scope="col">{item.bairro}</th>
                                                                                        <th scope="col">{item.logradouro}</th>
                                                                                        <th scope="col">{item.mes}</th>
                                                                                        <th scope="col">{item.ano}</th>
                                                                                        <th scope="col">{item.fid}</th>
                                                                                        <th scope="col">{item.id_ci}</th>
                                                                                        <th scope="col">{item.n_edificac}</th>
                                                                                        <th scope="col">{item.tipo_ci}</th>
                                                                                        <th scope="col">{item.local_ci}</th>
                                                                                        <th scope="col">{item.mat_tampa}</th>
                                                                                        <th scope="col">{item.diam_tampa}</th>
                                                                                        <th scope="col">{item.pav_calcad}</th>
                                                                                        <th scope="col">{item.prof_poco}</th>
                                                                                        <th scope="col">{item.mat_poco}</th>
                                                                                        <th scope="col">{item.form_poco}</th>
                                                                                        <th scope="col">{item.dim_int_po}</th>
                                                                                        <th scope="col">{item.dim_poco_r}</th>
                                                                                        <th scope="col">{item.mat_tubo_s}</th>
                                                                                        <th scope="col">{item.dim_tubo_s}</th>
                                                                                        <th scope="col">{item.data_dado}</th>
                                                                                        <th scope="col">{item.font_comp}</th>
                                                                                        <th scope="col">{item.font_geom}</th>
                                                                                        <th scope="col">{item.orig_arq}</th>
                                                                                    </tr>
                                                                                ))}

                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                </Col>

                                                            </Row>
                                                        </div>
                                                    </TabPane>

                                                    <TabPane tabId="2" id="border-navs-profile">
                                                        <div className="d-flex">
                                                            ...
                                                        </div>
                                                        <div className="d-flex mt-2">
                                                            ...
                                                        </div>
                                                    </TabPane>

                                                    <TabPane tabId="3" id="border-navs-messages">
                                                        <div className="d-flex">
                                                            ...
                                                        </div>
                                                        <div className="d-flex mt-2">
                                                            ...
                                                        </div>
                                                        <div className="d-flex mt-2">
                                                            ...
                                                        </div>
                                                    </TabPane>

                                                    <TabPane tabId="4" id="border-navs-settings">
                                                        <div className="d-flex mt-2">
                                                            ...
                                                        </div>
                                                        <div className="d-flex mt-2">
                                                            ...
                                                        </div>
                                                        <div className="d-flex mt-2">
                                                            ...
                                                        </div>
                                                        <div className="d-flex mt-2">
                                                            ...
                                                        </div>
                                                    </TabPane>

                                                    <TabPane tabId="5" id="border-navs-settings">
                                                        <div className="d-flex mt-2">
                                                            ...
                                                        </div>
                                                        <div className="d-flex mt-2">
                                                            ...
                                                        </div>
                                                        <div className="d-flex mt-2">
                                                            ...
                                                        </div>
                                                        <div className="d-flex mt-2">
                                                            ...
                                                        </div>
                                                        <div className="d-flex mt-2">
                                                            ...
                                                        </div>
                                                    </TabPane>

                                                    <TabPane tabId="6" id="border-navs-settings">
                                                        <div className="d-flex mt-2">
                                                            ...
                                                        </div>
                                                        <div className="d-flex mt-2">
                                                            ...
                                                        </div>
                                                        <div className="d-flex mt-2">
                                                            ...
                                                        </div>
                                                        <div className="d-flex mt-2">
                                                            ...
                                                        </div>
                                                        <div className="d-flex mt-2">
                                                            ...
                                                        </div>
                                                        <div className="d-flex mt-2">
                                                            ...
                                                        </div>
                                                    </TabPane>
                                                </TabContent>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </TabPane>


                                <TabPane tabId="2" id="arrow-drenagem">
                                    <h6>Drenagem</h6>
                                    <div className="d-flex mt-2">
                                        ...
                                    </div>
                                    <div className="d-flex mt-2">
                                        ...
                                    </div>
                                    <div className="d-flex mt-2">
                                        ...
                                    </div>
                                </TabPane>


                                <TabPane tabId="3" id="arrow-agua">
                                    <h6>Água</h6>
                                    <div className="d-flex mt-2">
                                        ...
                                    </div>
                                    <div className="d-flex mt-2">
                                        ...
                                    </div>
                                    <div className="d-flex mt-2">
                                        ...
                                    </div>
                                </TabPane>

                                <TabPane tabId="4" id="arrow-solidos">
                                    <h6>Sólidos</h6>
                                    <div className="d-flex mt-2">
                                        ...
                                    </div>
                                    <div className="d-flex mt-2">
                                        ...
                                    </div>
                                    <div className="d-flex mt-2">
                                        ...
                                    </div>
                                </TabPane>

                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>

            </div>

        </React.Fragment>
    );
};

export default SearchOption;