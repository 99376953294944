import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";

import { configureStore } from "./Store";

import './Assets/css/app_style.css'
import './Assets/css/dashboard_style.css'
import './Assets/css/sidebar_style.css'
import './Assets/css/home_style.css'
//import './css/colors.css';
//import "./Assets/css/nucleo-icons.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={configureStore({})}>
    <React.Fragment>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);