import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes";
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions";

//Include Both Helper File with needed methods
import {
  postForgetPwd,
  postJwtForgetPwd,
} from "../../../helpers/backend_helper";


//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtForgetPwd, user);
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Um link com as instruções foi enviado, verifique sua caixa de e-mail por favor."
          )
        );
      }
    } else if (process.env.REACT_APP_API_URL) {
      const response = yield call(postForgetPwd, user);
      if (response) {
        yield put(
          userForgetPasswordSuccess(
            "Um link com as instruções foi enviado, verifique sua caixa de e-mail por favor."
          )
        );
      }
    }
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
