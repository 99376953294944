import React, { useState, useEffect } from 'react';
import classnames from "classnames";
import proj4 from 'proj4';

import {
    Button,
    Row,
    Col,
    Label,
    Table,
    Modal,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    UncontrolledCarousel,
} from "reactstrap";

import { Popup, LayerGroup, Circle, LayersControl } from 'react-leaflet';
import { useSelector, useDispatch } from "react-redux";
import SimpleBar from 'simplebar-react';
import EditModalCiCad from "../../Helpers/Editar/EditModalCiCad";
import CiStatus from "../../Helpers/Status/CiStatus";
import PdfCiCad from "../../Helpers/PDFCreate/PdfCiCad";
import EditModalCiStatus from "../../Helpers/Editar/EditModalCiStatus";


import img1 from "../../../../Assets/images/sanapp/CI01.jpeg";
import img2 from "../../../../Assets/images/sanapp/CI02.jpeg";
import img3 from "../../../../Assets/images/sanapp/CI03.jpeg";

import { updateCiCadastros } from "../../../../Store/cicadesgoto/action";
import { updateCiStatus } from "../../../../Store/cistatusesgoto/action";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import { DictCiCad } from '../../../../Legends/DictCiCad';
import { DictCiStatus } from '../../../../Legends/DictCiStatus';
const { Overlay } = LayersControl;


function CamadaCiCadastro({ ciCadastros, ciStatus }) {

    const [modal_center_ci, setmodal_center_ci] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    // Juntando objetos de ciStatus em ciCadastros
    const combinedData = ciCadastros.map(cadastro => {
        const status = ciStatus.filter(statusItem => statusItem.id_cad === cadastro.id_cad);
        return { ...cadastro, status };
    });

    const tog_center_ci = () => setmodal_center_ci(!modal_center_ci);
    const toggleModal = () => setModalIsOpen(!modalIsOpen);

    const dispatch = useDispatch();
    const BlueOptions = { fillColor: 'blue' };

    const saveEditDataCiCad = async (editedData) => {
        if (editedData.imagem) { delete editedData.imagem };
        const response = await dispatch(updateCiCadastros(editedData));
    };

    const saveEditDataCiStatus = async (editedData) => {
        if (editedData.imagem) { delete editedData.imagem };
        const response = await dispatch(updateCiStatus(editedData));
    };

    const transformCoord = (coord) => {
        try {
            const sourceEPSG = coord.match(/SRID=(\d+);/)[1]
            proj4.defs("EPSG:" + sourceEPSG, "+proj=utm +zone=22 +south +ellps=GRS80 +units=m +no_defs");
            const matches = coord.match(/POINT \(([^ ]+) ([^)]+)\)/);
            const coord_a = [parseFloat(matches[2]), parseFloat(matches[1])];
            return coord_a

            const transformed = proj4("EPSG:" + sourceEPSG, "EPSG:4326", coord_a);
            return [transformed[1], transformed[0]];
        }
        catch (err) { console.log(err); return [-27.612690, -48.598092] }
    };

    // Custom Nav Tabs
    const [customNav, setcustomNav] = useState("1");
    const customNavtoggle = (tab) => {
        if (customNav !== tab) {
            setcustomNav(tab);
        }
    };

    return (

        <Overlay name="CI - CADASTROS" checked>
            <LayerGroup style={{ zIndex: 1 }}>
                {combinedData.map((item) => (
                    <Circle center={transformCoord(item.geom)} pathOptions={BlueOptions} radius={1} weight={1} key={item.id_cad}>
                        <Popup>
                            <Row>
                                <Col lg={12}>
                                    <Nav pills className="nav-pills nav-primary mb-3 table-responsive">
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({ active: customNav === "1", })}
                                                onClick={() => { customNavtoggle("1"); }}
                                            >
                                                CADASTRO
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({ active: customNav === "2", })}
                                                onClick={() => { customNavtoggle("2"); }}
                                            >
                                                STATUS
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({ active: customNav === "3", })}
                                                onClick={() => { customNavtoggle("3"); }}
                                            >
                                                ORDEM SERVIÇO
                                            </NavLink>
                                        </NavItem>
                                    </Nav>

                                    <TabContent activeTab={customNav} className="text-muted">
                                        <TabPane tabId="1" id="border-navs-cadastro">
                                            <Label className="text-secondary mt-1 fs-16 mb-0">CAIXA DE INSPEÇÃO - CADASTRO</Label>
                                            <hr />
                                            <SimpleBar autoHide={false} className="simplebar-track-secondary" style={{ maxHeight: "250px" }}>
                                                <div className="table-responsive mt-4 mt-xl-0">
                                                    <Table
                                                        fixedHeaderScrollHeight="50px"
                                                        className="table-sm table-hover table-striped align-middle table-nowrap mb-0 table-responsive"
                                                        responsive
                                                        style={{ cursor: "pointer" }}
                                                        hover
                                                    >
                                                        <tbody>
                                                            {Object.keys(DictCiCad).map((key) => (
                                                                <tr key={key}>
                                                                    <td className="text-secondary fs-12">{DictCiCad[key].label}:</td>
                                                                    <td className="text-primary fs-12">{item[key]}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </SimpleBar>
                                            <hr />
                                            <div className="hstack gap-3">
                                                <PdfCiCad data={item} />
                                                <EditModalCiCad coord={transformCoord(item.geom)} data={item} onSave={saveEditDataCiCad} />
                                            </div>
                                        </TabPane>

                                        <TabPane tabId="2" id="border-navs-status">
                                            <Label className="text-secondary mt-1 fs-16 mb-0">CAIXA DE INSPEÇÃO - STATUS</Label>
                                            <hr />
                                            <SimpleBar autoHide={false} className="simplebar-track-secondary" style={{ maxHeight: "250px" }}>
                                                <div className="table-responsive mt-4 mt-xl-0">
                                                    <Table
                                                        fixedHeaderScrollHeight="50px"
                                                        className="table-sm table-hover table-striped align-middle table-nowrap mb-0 table-responsive"
                                                    >
                                                        <tbody>
                                                            {item.status.length > 0 ? (
                                                                Object.keys(DictCiStatus).map((key) => (
                                                                    <tr key={key}>
                                                                        <td className="text-secondary fs-12">{DictCiStatus[key].label}</td>
                                                                        <td className="text-primary fs-12">{item.status[0] ? item.status[0][key] : "SEM STATUS"}</td>
                                                                    </tr>
                                                                ))) : (
                                                                <tr>
                                                                    <td className="text-secondary fs-12 m-5">STATUS</td>
                                                                    <td className="text-primary fs-12 m-5">SEM STATUS</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </SimpleBar>
                                            <Modal
                                                isOpen={modal_center_ci}
                                                toggle={() => {
                                                    tog_center_ci();
                                                }}
                                                size="md"
                                                centered
                                            >
                                                <Row className="m-3">
                                                    <h4 className="card-title mb-0">FOTOS DA CAIXA DE INSPEÇÃO</h4>
                                                    <p className="text-muted">Abaixo as imagens disponíveis em nosso sistema:</p>
                                                    <UncontrolledCarousel
                                                        interval={4000}
                                                        items={[
                                                            {
                                                                altText: " ",
                                                                caption: " ",
                                                                key: 1,
                                                                src: img1,
                                                            },
                                                            {
                                                                altText: " ",
                                                                caption: " ",
                                                                key: 2,
                                                                src: img2,
                                                                //src: item.status[0]?.img_path_ext_int,
                                                            },
                                                            {
                                                                altText: " ",
                                                                caption: " ",
                                                                key: 3,
                                                                src: img3,
                                                                //src: item.status[0]?.img_path_ext_int ? "https://drive.google.com/uc?id=1oMjhj3IZspZqu3vMq7o5kSN2YVaQajbP&export=download" : img1,
                                                            },
                                                            /*
                                                        {
                                                            altText: " ",
                                                            caption: " ",
                                                            key: 4,
                                                            src: item.status[0]?.img_path_ext_int.replace("&export=download", ""),
                                                        },
                                                        {
                                                            altText: " ",
                                                            caption: " ",
                                                            key: 5,
                                                            src: item.status[0]?.img_path_ext_int.replace("https://drive.google.com/uc?id=", "https://drive.usercontent.google.com/download?id=").replace("&export=download", "&authuser=0"),
                                                        },
                                                        */
                                                            /*
                                                            {
                                                                altText: " ",
                                                                caption: " ",
                                                                key: 6,
                                                                src: getimage(item.img_path_ext_int),
                                                            },
                                                            {
                                                                altText: " ",
                                                                caption: " ",
                                                                key: 7,
                                                                src: getimagefunc(item.img_path_ext_int),
                                                            },
                                                            {
                                                                altText: " ",
                                                                caption: " ",
                                                                key: 8,
                                                                src: getimagefunc(item.status[0]?.img_path_ext_int.replace("https://drive.google.com/uc?id=", "https://drive.usercontent.google.com/download?id=").replace("&export=download", "&authuser=0")),
                                                            },
                                                            */
                                                        ]}
                                                    />
                                                </Row>
                                            </Modal>
                                            <hr />
                                            <div className="hstack gap-3">
                                                <Button
                                                    className="mt-3 shadow-lg p-2 px-4"
                                                    color='success'
                                                >ABRIR O.S</Button>
                                                {' '}
                                                <Button
                                                    className="mt-3 shadow-lg p-2 px-4"
                                                    color='primary'
                                                    onClick={() => tog_center_ci()}
                                                >FOTOS</Button>
                                                {' '}
                                                <EditModalCiStatus data={item.status[0]} onSave={saveEditDataCiStatus} />
                                            </div>
                                        </TabPane>

                                        <TabPane tabId="3" id="border-navs-ordens">
                                            <Label className="text-secondary mt-1 fs-16 mb-0">CAIXA DE INSPEÇÃO - ORDEM SERVIÇO</Label>
                                            <hr />
                                            <SimpleBar autoHide={false} className="simplebar-track-secondary" style={{ maxHeight: "250px" }}>
                                                <div className="table-responsive mt-4 mt-xl-0">
                                                    <Table
                                                        fixedHeaderScrollHeight="50px"
                                                        className="table-sm table-hover table-striped align-middle table-nowrap mb-0 table-responsive"
                                                    >
                                                        <tbody>
                                                            <div className="d-flex" style={{ height: "500px" }}>
                                                                ...
                                                            </div>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </SimpleBar>
                                            <hr />
                                            <div className="hstack gap-3">
                                                <Button
                                                    className="mt-3 shadow-lg p-2 px-3"
                                                    color='success'
                                                >VISUALIZAR</Button>
                                                {' '}
                                                <Button
                                                    className="mt-3 shadow-lg p-2 px-4"
                                                    color='primary'
                                                >EDITAR</Button>
                                                {' '}
                                                <Button
                                                    className="mt-3 shadow-lg p-2 px-3"
                                                    color='danger'
                                                >ENCERRAR</Button>
                                            </div>
                                        </TabPane>
                                    </TabContent>

                                </Col>
                            </Row>
                        </Popup>
                    </Circle>
                ))}
            </LayerGroup>
        </Overlay>

    );
};
export default CamadaCiCadastro;