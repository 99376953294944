import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import {
  postLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/backend_helper";

function* loginUser({ payload: { user, history } }) {

  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      //console.log(`User: ${user.username}`)
      //console.log(`Password: ${user.password}`)

      const response = yield call(postJwtLogin, {
        username: user.username,
        password: user.password,
      });

      //console.log(`Response Backend: ${response}`)
      //console.log(`Response Token: ${response.token}`)

      sessionStorage.setItem("token", JSON.stringify(response.token));
      sessionStorage.setItem("userdata", JSON.stringify(response));
      yield put(loginSuccess(response));
      window.location.assign("/");

    } else if (process.env.REACT_APP_API_URL) {
      const response = yield call(postLogin, {
        username: user.username,
        password: user.password,
      });
      sessionStorage.setItem("authUser", JSON.stringify(response));
      if (response.status === "success") {
        yield put(loginSuccess(response));
      window.location.assign("/")
      } else {
        yield put(apiError(response));
      }
    }

  } catch (error) {
    const get_erro_key = Object.keys(error)
    console.log(error[get_erro_key[0]])
    yield put(apiError(error[get_erro_key[0]]));
  }
}


function* logoutUser() {
  try {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("userdata");
    //localStorage.removeItem("token");
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      yield put(logoutUserSuccess(LOGOUT_USER, true));

    } else {
      yield put(logoutUserSuccess(LOGOUT_USER, true));
    }
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    const response = yield call(postSocialLogin, data);
    sessionStorage.setItem("authUser", JSON.stringify(response));
    yield put(loginSuccess(response));
    history.push("/");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
