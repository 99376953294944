import {
  GET_PV_STATUS,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_NEW_PV_STATUS,
  ADD_PV_STATUS_SUCCESS,
  ADD_PV_STATUS_FAIL,
  UPDATE_PV_STATUS,
  UPDATE_PV_STATUS_SUCCESS,
  UPDATE_PV_STATUS_FAIL,
  DELETE_PV_STATUS,
  DELETE_PV_STATUS_SUCCESS,
  DELETE_PV_STATUS_FAIL,
} from "./actionType";

// common success
export const PvStatusApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const PvStatusApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getPvStatus = pvstatus => ({
  type: GET_PV_STATUS,
  payload: pvstatus,
});

export const updatePvStatus = pvstatus => ({
  type: UPDATE_PV_STATUS,
  payload: pvstatus,
});

export const updatePvStatusSuccess = pvstatus => ({
  type: UPDATE_PV_STATUS_SUCCESS,
  payload: pvstatus,
});

export const updatePvStatusFail = error => ({
  type: UPDATE_PV_STATUS_FAIL,
  payload: error,
});

export const addNewPvStatus = pvstatus => ({
  type: ADD_NEW_PV_STATUS,
  payload: pvstatus,
});

export const addPvStatusSuccess = pvstatus => ({
  type: ADD_PV_STATUS_SUCCESS,
  payload: pvstatus,
});

export const addPvStatusFail = error => ({
  type: ADD_PV_STATUS_FAIL,
  payload: error,
});
export const deletePvStatus = pvstatus => ({
  type: DELETE_PV_STATUS,
  payload: pvstatus,
});

export const deletePvStatusSuccess = pvstatus => ({
  type: DELETE_PV_STATUS_SUCCESS,
  payload: pvstatus,
});

export const deletePvStatusFail = error => ({
  type: DELETE_PV_STATUS_FAIL,
  payload: error,
});