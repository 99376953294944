import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label, Row, Col } from 'reactstrap';

const EditModalRedeCad = ({ data, onSave }) => {
    const [editedData, setEditedData] = useState(data);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openEditModal = () => setModalIsOpen(true);
    const toggleModal = () => setModalIsOpen(!modalIsOpen);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedData({ ...editedData, [name]: value });
    };

    const handleSave = () => {
        onSave(editedData);
        toggleModal();
    };

    return (
        <>
            <Button
                className="mt-3 shadow-lg p-2 px-4"
                color='primary'
                onClick={openEditModal}
            >
                EDITAR
            </Button>
            <Modal isOpen={modalIsOpen} toggle={toggleModal} style={{ width: '1024px', maxWidth: '100vw' }} centered>
                <ModalHeader toggle={toggleModal}>
                    <div className="justify-content-center">
                        Editar dados de cadastro do Rede
                    </div>
                </ModalHeader>
                <hr />
                <ModalBody>
                    <Row>
                        <Col xxl={12}>
                            <Row className="gy-0">
                                {/*ID REDE*/}
                                <Col xxl={2}>
                                    <Label className="form-label mt-0 fs-12 mb-0" for="id">ID REDE:</Label>
                                    <Input type="text" className="form-control form-control-sm" name="id"
                                        id="id" value={editedData.id} onChange={handleInputChange} />
                                </Col>
                                {/*LOGRADOURO*/}
                                <Col xxl={4}>
                                    <Label className="form-label mt-0 fs-12 mb-0" for="logradouro">LOGRADOURO:</Label>
                                    <Input type="text" className="form-control form-control-sm" name="logradouro"
                                        id="logradouro" value={editedData.logradouro} onChange={handleInputChange} />
                                </Col>
                                {/*No. EDIFÍCIO*/}
                                <Col xxl={2}>
                                    <Label className="form-label mt-0 fs-12 mb-0" for="n_edif_ref">No. EDIFÍCIO REF.:</Label>
                                    <Input type="text" className="form-control form-control-sm" name="n_edif_ref"
                                        id="n_edif_ref" value={editedData.n_edif_ref} onChange={handleInputChange} />
                                </Col>
                                {/*BAIRRO*/}
                                <Col xxl={3}>
                                    <Label className="form-label mt-0 fs-12 mb-0" for="bairro">BAIRRO:</Label>
                                    <Input type="text" className="form-control form-control-sm" name="bairro"
                                        id="bairro" value={editedData.bairro} onChange={handleInputChange} />
                                </Col>
                                {/*CIDADE*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0" for="cidade">CIDADE:</Label>
                                        <Input type="text" className="form-control form-control-sm" name="cidade"
                                            id="cidade" value={editedData.cidade} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*UF*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0" for="uf">UF:</Label>
                                        <Input type="text" className="form-control form-control-sm" name="uf"
                                            id="uf" value={editedData.uf} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*DATA DOS DADOS*/}
                                <Col xxl={3}>
                                    <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="data_dado">DATA DOS DADOS:</Label>
                                    <Input type="date" className="form-control form-control-sm" name="data_dado"
                                        id="data_dado" value={editedData.data_dado} onChange={handleInputChange} />
                                </Col>
                                {/*TIPO REDE*/}
                                <Col xxl={3}>
                                    <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="tipo" >TIPO REDE:</Label>
                                    <Input className="form-control-sm" type="select" name="tipo" id="input_tipo"
                                        value={editedData.tipo} onChange={handleInputChange}>
                                        <option selected>Selecionar</option>
                                        <option key="Ramal Predial" value="Ramal Predial">Ramal Predial</option>
                                        <option key="Rede de Esgoto" value="Rede de Esgoto">Rede de Esgoto</option>
                                        <option key="Outros" value="Outros">Outros</option>
                                    </Input>
                                </Col>
                                {/*POSIÇÃO REDE*/}
                                <Col xxl={3}>
                                    <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="posicao" >POSIÇÃO REDE:</Label>
                                    <Input className="form-control-sm" type="select" name="posicao" id="input_posicao"
                                        value={editedData.posicao} onChange={handleInputChange}>
                                        <option selected>Selecionar</option>
                                        <option key="Enterrado" value="Enterrado">Enterrado</option>
                                        <option key="Exposto / Aéreo" value="Exposto / Aéreo">Exposto / Aéreo</option>
                                        <option key="Outros" value="Outros">Outros</option>
                                    </Input>
                                </Col>
                                {/*PONTO A*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="pontoa">PONTO A:</Label>
                                        <Input type="text" className="form-control form-control-sm" name="pontoa"
                                            id="pontoa" value={editedData.pontoa} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*N EDIF PA*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="n_edif_pa">N. EDIFÍCIO PA:</Label>
                                        <Input type="text" className="form-control form-control-sm" name="n_edif_pa"
                                            id="n_edif_pa" value={editedData.n_edif_pa} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*PONTO B*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="pontob">PONTO B:</Label>
                                        <Input type="text" className="form-control form-control-sm" name="pontob"
                                            id="pontob" value={editedData.pontob} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*N EDIF PB*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="n_edif_pb">N. EDIFÍCIO PB:</Label>
                                        <Input type="text" className="form-control form-control-sm" name="n_edif_pb"
                                            id="n_edif_pb" value={editedData.n_edif_pb} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*LOCAL DO REDE PONTO A*/}
                                <Col xxl={3}>
                                    <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="loctrecpa">LOCAL DO REDE PONTO A:</Label>
                                    <Input className="form-control-sm" type="select" name="loctrecpa" id="input_loctrecpa"
                                        value={editedData.loctrecpa} onChange={handleInputChange}>
                                        <option selected>Selecionar</option>
                                        <option key="Via" value="Via">Via</option>
                                        <option key="Calçada" value="Calçada">Calçada</option>
                                        <option key="Servidão Sanitária" value="Servidão Sanitária">Servidão Sanitária</option>
                                        <option key="Terreno Particular" value="Terreno Particular">Terreno Particular</option>
                                        <option key="Outros" value="Outros">Outros</option>
                                    </Input>
                                </Col>
                                {/*LOCAL DO REDE PONTO B*/}
                                <Col xxl={3}>
                                    <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="loctrecpb">LOCAL DO REDE PONTO B:</Label>
                                    <Input className="form-control-sm" type="select" name="loctrecpb" id="input_loctrecpb"
                                        value={editedData.loctrecpb} onChange={handleInputChange}>
                                        <option selected>Selecionar</option>
                                        <option key="Via" value="Via">Via</option>
                                        <option key="Calçada" value="Calçada">Calçada</option>
                                        <option key="Servidão Sanitária" value="Servidão Sanitária">Servidão Sanitária</option>
                                        <option key="Terreno Particular" value="Terreno Particular">Terreno Particular</option>
                                        <option key="Outros" value="Outros">Outros</option>
                                    </Input>
                                </Col>
                                {/*PAVIMENTAÇÂO REDE PONTO A*/}
                                <Col xxl={3}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="pavtrecpa">PAVIMENTAÇÂO  REDE PONTO A:</Label>
                                        <Input className="form-control-sm" type="select" name="pavtrecpa" id="input_pavtrecpa"
                                            value={editedData.pavtrecpa} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Asfalto" value="Asfalto">Asfalto</option>
                                            <option key="Lajota Sextavada" value="Lajota Sextavada">Lajota Sextavada</option>
                                            <option key="Paralelepípedo" value="Paralelepípedo">Paralelepípedo</option>
                                            <option key="Terreno Natural" value="Terreno Natural">Terreno Natural</option>
                                            <option key="Concreto" value="Concreto">Concreto</option>
                                            <option key="Piso Cerâmico" value="Piso Cerâmico">Piso Cerâmico</option>
                                            <option key="Piso Padrão Município" value="Piso Padrão Município">Piso Padrão Município</option>
                                            <option key="Lajota" value="Lajota">Lajota</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*PAVIMENTAÇÂO REDE PONTO B*/}
                                <Col xxl={3}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="pavtrecpb">PAVIMENTAÇÂO  REDE PONTO B:</Label>
                                        <Input className="form-control-sm" type="select" name="pavtrecpb" id="input_pavtrecpb"
                                            value={editedData.pavtrecpb} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="Asfalto" value="Asfalto">Asfalto</option>
                                            <option key="Lajota Sextavada" value="Lajota Sextavada">Lajota Sextavada</option>
                                            <option key="Paralelepípedo" value="Paralelepípedo">Paralelepípedo</option>
                                            <option key="Terreno Natural" value="Terreno Natural">Terreno Natural</option>
                                            <option key="Concreto" value="Concreto">Concreto</option>
                                            <option key="Piso Cerâmico" value="Piso Cerâmico">Piso Cerâmico</option>
                                            <option key="Piso Padrão Município" value="Piso Padrão Município">Piso Padrão Município</option>
                                            <option key="Lajota" value="Lajota">Lajota</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*METERIAL DO TUBO*/}
                                <Col xxl={3}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="mat_tub">METERIAL DO TUBO:</Label>
                                        <Input className="form-control-sm" type="select" name="mat_tub" id="input_mat_tub"
                                            value={editedData.mat_tub} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="PVC" value="PVC">PVC</option>
                                            <option key="Manilha Cerâmica" value="Manilha Cerâmica">Manilha Cerâmica</option>
                                            <option key="Amianto" value="Amianto">Amianto</option>
                                            <option key="Manilha Concreto" value="Manilha Concreto">Manilha Concreto</option>
                                            <option key="PEAD" value="PEAD">PEAD</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*DIAMETRO DO TUBO*/}
                                <Col xxl={3}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="dim_tub">DIAMETRO DO TUBO:</Label>
                                        <Input className="form-control-sm" type="select" name="dim_tub" id="input_dim_tub"
                                            value={editedData.dim_tub} onChange={handleInputChange}>
                                            <option selected>Selecionar</option>
                                            <option key="75mm" value="75mm">75mm</option>
                                            <option key="100mm" value="100mm">100mm</option>
                                            <option key="150mm" value="150mm">150mm</option>
                                            <option key="200mm" value="200mm">200mm</option>
                                            <option key="250mm" value="250mm">250mm</option>
                                            <option key="300mm" value="300mm">300mm</option>
                                            <option key="350mm" value="350mm">350mm</option>
                                            <option key="400mm" value="400mm">400mm</option>
                                            <option key="500mm" value="500mm">500mm</option>
                                            <option key="600mm" value="600mm">600mm</option>
                                            <option key="Outros" value="Outros">Outros</option>
                                        </Input>
                                    </div>
                                </Col>
                                {/*SENTIDO DO FLUXO*/}
                                <Col xxl={3}>
                                    <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="sent_fluxo">SENTIDO DO FLUXO:</Label>
                                    <Input className="form-control-sm" type="select" name="sent_fluxo" id="input_sent_fluxo"
                                        value={editedData.sent_fluxo} onChange={handleInputChange}>
                                        <option selected>Selecionar</option>
                                        <option key="AB" value="AB">AB</option>
                                        <option key="BA" value="BA">BA</option>
                                    </Input>
                                </Col>
                                {/*STATUS DIS*/}
                                <Col xxl={2}>
                                    <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="stat_disp">STATUS DIS:</Label>
                                    <Input className="form-control-sm" type="select" name="stat_disp" id="input_stat_disp"
                                        value={editedData.stat_disp} onChange={handleInputChange}>
                                        <option selected>Selecionar</option>
                                        <option key="Em operação" value="Em operação">Em operação</option>
                                        <option key="Fora de operação" value="Fora de operação">Fora de operação</option>
                                    </Input>
                                </Col>
                                {/*FONTE DADO*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="fonte_dado">FONTE DADO:</Label>
                                        <Input type="text" className="form-control form-control-sm" name="fonte_dado"
                                            id="fonte_dado" value={editedData.fonte_dado} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*EXTENSÃO M*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="extensao_m">EXTENSÃO M:</Label>
                                        <Input type="number" className="form-control form-control-sm" name="extensao_m"
                                            id="extensao_m" value={editedData.extensao_m} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*PATH IMAGEM*/}

                                {/*OBSERVAÇÃO*/}
                                <Col xxl={2}>
                                    <div>
                                        <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="obs">OBS.:</Label>
                                        <Input type="text" className="form-control form-control-sm" name="obs"
                                            id="obs" value={editedData.obs} onChange={handleInputChange} />
                                    </div>
                                </Col>
                                {/*VIDEO*/}

                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
                <hr />
                <ModalFooter>
                    <Button color="primary" onClick={handleSave}>
                        Salvar
                    </Button>{' '}
                    <Button color="danger" onClick={toggleModal}>
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default EditModalRedeCad;
