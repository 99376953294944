import { all, fork } from "redux-saga/effects";

//Auth
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import NewSaga from "./auth/createnewpwd/saga";
import ProfileSaga from "./auth/profile/saga";

import cicadastrosSaga from "./cicadesgoto/saga";
import cistatusSaga from "./cistatusesgoto/saga";
import pvcadastrosSaga from "./pvcadesgoto/saga";
import pvstatusSaga from "./pvstatusesgoto/saga";
import redecadastrosSaga from "./redecadesgoto/saga";
import redestatusSaga from "./redestatusesgoto/saga";

//camgeoserver
import camgeoSaga from "./camgeoserver/saga";

//layout
import LayoutSaga from "./layouts/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(NewSaga),
    fork(ProfileSaga),
    fork(cicadastrosSaga),
    fork(cistatusSaga),
    fork(pvcadastrosSaga),
    fork(pvstatusSaga),
    fork(redecadastrosSaga),
    fork(redestatusSaga),
    fork(camgeoSaga),
    fork(LayoutSaga)
  ]);
}
