import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';

import proj4 from 'proj4';
// Api Conection
import { useSelector, useDispatch } from "react-redux";
import { getRedeStatus } from "../../../../Store/redestatusesgoto/action";
import classnames from "classnames";
import { Card, CardHeader, CardFooter, CardBody, Col, Row, Input, Label, Table, Button } from "reactstrap";
import * as XLSX from 'xlsx';
import FileSaver from "file-saver";

const FilterRedeStatus = () => {

    // Create Form Values
    const [formValues, setFormValues] = useState({
        id_ci: "", n_edificac: "", tipo_ci: "", local_ci: "",
        mat_tampa: "", diam_tampa: "", pav_calcad: "", prof_poco: "", mat_poco: "",
        form_poco: "", dim_int_po: "", dim_poco_r: "", mat_tubo_s: "", dim_tubo_s: "",
        data_dado: "", font_comp: "", font_geom: "", orig_arq: "", cidade: "",
        bairro: "", logradouro: "", mes: "", ano: "",
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value === "Selecionar" ? "" : value });
    };

    const sendForm = () => {
        dispatch(getRedeStatus(formValues));
    };

    // GET CI CADASTROS
    const dispatch = useDispatch();
    const { redeStatus } = useSelector((state) => ({ redeStatus: state.RedeStatus.redeStatus }));
    useEffect(() => {
        if (redeStatus && !redeStatus.length) {
            dispatch(getRedeStatus());
        }
    }, [dispatch, redeStatus]);
    //console.log(redeStatus);

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 15;
    const handlePageClick = ({ selected }) => { setCurrentPage(selected) };
    const paginatedData = redeStatus.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );

    const [selectAll, setSelectAll] = useState(false);
    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckAll = () => {
        setSelectAll(!selectAll);
        const newCheckedItems = {};
        redeStatus.forEach((item) => {
            newCheckedItems[item.id] = !selectAll;
        });
        setCheckedItems(newCheckedItems);
        //console.log(checkedItems);
    };

    const handleCheckboxChange = (id) => {
        const newCheckedItems = { ...checkedItems, [id]: !checkedItems[id] };
        setCheckedItems(newCheckedItems);
    };

    const handleExportExcel = () => {
        // Filtra os itens marcados
        const selectedItems = redeStatus.filter((item) => checkedItems[item.id]);
        // Cria um array de dados para a planilha
        const dataForExcel = selectedItems.map((item) => ({
            ID: item.id,
            ID_REDE_STATUS: item.id_redestatu,
            ID_REDE: item.id_rede,

        }));
        const ws = XLSX.utils.json_to_sheet(dataForExcel);
        //const wb = XLSX.utils.book_new();
        const wb = XLSX.utils.book_new({ Sheets: { data: ws }, SheetsNames: ["data"] });
        XLSX.utils.book_append_sheet(wb, ws, 'Lista RedeStatus');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: "xlsx" })
        FileSaver.saveAs(data, "Selecionados_Rede_Status" + ".xlsx")
    };

    return (
        <React.Fragment>

            <div className="content">
                <Card>
                    <CardHeader>
                        <form className="app-search d-none d-md-block">
                            <Row>
                                <Col xxl={12}>
                                    <Row className="gy-0">
                                        {/*ID REDE*/}
                                        <Col xxl={2}>
                                            <Label className="form-label mt-0 fs-12 mb-0" for="id">ID REDE:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="id"
                                                id="id" value={formValues.id} onChange={handleInputChange} />
                                        </Col>
                                        {/*LOGRADOURO*/}
                                        <Col xxl={4}>
                                            <Label className="form-label mt-0 fs-12 mb-0" for="logradouro">LOGRADOURO:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="logradouro"
                                                id="logradouro" value={formValues.logradouro} onChange={handleInputChange} />
                                        </Col>
                                        {/*No. EDIFÍCIO*/}
                                        <Col xxl={2}>
                                            <Label className="form-label mt-0 fs-12 mb-0" for="n_edif_ref">No. EDIFÍCIO REF.:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="n_edif_ref"
                                                id="n_edif_ref" value={formValues.n_edif_ref} onChange={handleInputChange} />
                                        </Col>
                                        {/*BAIRRO*/}
                                        <Col xxl={3}>
                                            <Label className="form-label mt-0 fs-12 mb-0" for="bairro">BAIRRO:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="bairro"
                                                id="bairro" value={formValues.bairro} onChange={handleInputChange} />
                                        </Col>
                                        {/*CIDADE*/}
                                        <Col xxl={2}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0" for="cidade">CIDADE:</Label>
                                                <Input type="text" className="form-control form-control-sm" name="cidade"
                                                    id="cidade" value={formValues.cidade} onChange={handleInputChange} />
                                            </div>
                                        </Col>
                                        {/*UF*/}
                                        <Col xxl={2}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0" for="uf">UF:</Label>
                                                <Input type="text" className="form-control form-control-sm" name="uf"
                                                    id="uf" value={formValues.uf} onChange={handleInputChange} />
                                            </div>
                                        </Col>
                                        {/*DATA DOS DADOS*/}
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="data_dado">DATA DOS DADOS:</Label>
                                            <Input type="date" className="form-control form-control-sm" name="data_dado"
                                                id="data_dado" value={formValues.data_dado} onChange={handleInputChange} />
                                        </Col>
                                        {/*TIPO REDE*/}
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="tipo" >TIPO REDE:</Label>
                                            <Input className="form-control-sm" type="select" name="tipo" id="input_tipo"
                                                value={formValues.tipo} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Ramal Predial" value="Ramal Predial">Ramal Predial</option>
                                                <option key="Rede de Esgoto" value="Rede de Esgoto">Rede de Esgoto</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        {/*POSIÇÃO REDE*/}
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="posicao" >POSIÇÃO REDE:</Label>
                                            <Input className="form-control-sm" type="select" name="posicao" id="input_posicao"
                                                value={formValues.posicao} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Enterrado" value="Enterrado">Enterrado</option>
                                                <option key="Exposto / Aéreo" value="Exposto / Aéreo">Exposto / Aéreo</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        {/*PONTO A*/}
                                        <Col xxl={2}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="pontoa">PONTO A:</Label>
                                                <Input type="text" className="form-control form-control-sm" name="pontoa"
                                                    id="pontoa" value={formValues.pontoa} onChange={handleInputChange} />
                                            </div>
                                        </Col>
                                        {/*N EDIF PA*/}
                                        <Col xxl={2}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="n_edif_pa">N. EDIFÍCIO PA:</Label>
                                                <Input type="text" className="form-control form-control-sm" name="n_edif_pa"
                                                    id="n_edif_pa" value={formValues.n_edif_pa} onChange={handleInputChange} />
                                            </div>
                                        </Col>
                                        {/*PONTO B*/}
                                        <Col xxl={2}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="pontob">PONTO B:</Label>
                                                <Input type="text" className="form-control form-control-sm" name="pontob"
                                                    id="pontob" value={formValues.pontob} onChange={handleInputChange} />
                                            </div>
                                        </Col>
                                        {/*N EDIF PB*/}
                                        <Col xxl={2}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="n_edif_pb">N. EDIFÍCIO PB:</Label>
                                                <Input type="text" className="form-control form-control-sm" name="n_edif_pb"
                                                    id="n_edif_pb" value={formValues.n_edif_pb} onChange={handleInputChange} />
                                            </div>
                                        </Col>
                                        {/*LOCAL DO REDE PONTO A*/}
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="loctrecpa">LOCAL DO REDE PONTO A:</Label>
                                            <Input className="form-control-sm" type="select" name="loctrecpa" id="input_loctrecpa"
                                                value={formValues.loctrecpa} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Via" value="Via">Via</option>
                                                <option key="Calçada" value="Calçada">Calçada</option>
                                                <option key="Servidão Sanitária" value="Servidão Sanitária">Servidão Sanitária</option>
                                                <option key="Terreno Particular" value="Terreno Particular">Terreno Particular</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        {/*LOCAL DO REDE PONTO B*/}
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="loctrecpb">LOCAL DO REDE PONTO B:</Label>
                                            <Input className="form-control-sm" type="select" name="loctrecpb" id="input_loctrecpb"
                                                value={formValues.loctrecpb} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Via" value="Via">Via</option>
                                                <option key="Calçada" value="Calçada">Calçada</option>
                                                <option key="Servidão Sanitária" value="Servidão Sanitária">Servidão Sanitária</option>
                                                <option key="Terreno Particular" value="Terreno Particular">Terreno Particular</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        {/*PAVIMENTAÇÂO REDE PONTO A*/}
                                        <Col xxl={3}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="pavtrecpa">PAVIMENTAÇÂO  REDE PONTO A:</Label>
                                                <Input className="form-control-sm" type="select" name="pavtrecpa" id="input_pavtrecpa"
                                                    value={formValues.pavtrecpa} onChange={handleInputChange}>
                                                    <option selected>Selecionar</option>
                                                    <option key="Asfalto" value="Asfalto">Asfalto</option>
                                                    <option key="Lajota Sextavada" value="Lajota Sextavada">Lajota Sextavada</option>
                                                    <option key="Paralelepípedo" value="Paralelepípedo">Paralelepípedo</option>
                                                    <option key="Terreno Natural" value="Terreno Natural">Terreno Natural</option>
                                                    <option key="Concreto" value="Concreto">Concreto</option>
                                                    <option key="Piso Cerâmico" value="Piso Cerâmico">Piso Cerâmico</option>
                                                    <option key="Piso Padrão Município" value="Piso Padrão Município">Piso Padrão Município</option>
                                                    <option key="Lajota" value="Lajota">Lajota</option>
                                                    <option key="Outros" value="Outros">Outros</option>
                                                </Input>
                                            </div>
                                        </Col>
                                        {/*PAVIMENTAÇÂO REDE PONTO B*/}
                                        <Col xxl={3}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="pavtrecpb">PAVIMENTAÇÂO  REDE PONTO B:</Label>
                                                <Input className="form-control-sm" type="select" name="pavtrecpb" id="input_pavtrecpb"
                                                    value={formValues.pavtrecpb} onChange={handleInputChange}>
                                                    <option selected>Selecionar</option>
                                                    <option key="Asfalto" value="Asfalto">Asfalto</option>
                                                    <option key="Lajota Sextavada" value="Lajota Sextavada">Lajota Sextavada</option>
                                                    <option key="Paralelepípedo" value="Paralelepípedo">Paralelepípedo</option>
                                                    <option key="Terreno Natural" value="Terreno Natural">Terreno Natural</option>
                                                    <option key="Concreto" value="Concreto">Concreto</option>
                                                    <option key="Piso Cerâmico" value="Piso Cerâmico">Piso Cerâmico</option>
                                                    <option key="Piso Padrão Município" value="Piso Padrão Município">Piso Padrão Município</option>
                                                    <option key="Lajota" value="Lajota">Lajota</option>
                                                    <option key="Outros" value="Outros">Outros</option>
                                                </Input>
                                            </div>
                                        </Col>
                                        {/*METERIAL DO TUBO*/}
                                        <Col xxl={3}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="mat_tub">METERIAL DO TUBO:</Label>
                                                <Input className="form-control-sm" type="select" name="mat_tub" id="input_mat_tub"
                                                    value={formValues.mat_tub} onChange={handleInputChange}>
                                                    <option selected>Selecionar</option>
                                                    <option key="PVC" value="PVC">PVC</option>
                                                    <option key="Manilha Cerâmica" value="Manilha Cerâmica">Manilha Cerâmica</option>
                                                    <option key="Amianto" value="Amianto">Amianto</option>
                                                    <option key="Manilha Concreto" value="Manilha Concreto">Manilha Concreto</option>
                                                    <option key="PEAD" value="PEAD">PEAD</option>
                                                    <option key="Outros" value="Outros">Outros</option>
                                                </Input>
                                            </div>
                                        </Col>
                                        {/*DIAMETRO DO TUBO*/}
                                        <Col xxl={3}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="dim_tub">DIAMETRO DO TUBO:</Label>
                                                <Input className="form-control-sm" type="select" name="dim_tub" id="input_dim_tub"
                                                    value={formValues.dim_tub} onChange={handleInputChange}>
                                                    <option selected>Selecionar</option>
                                                    <option key="75mm" value="75mm">75mm</option>
                                                    <option key="100mm" value="100mm">100mm</option>
                                                    <option key="150mm" value="150mm">150mm</option>
                                                    <option key="200mm" value="200mm">200mm</option>
                                                    <option key="250mm" value="250mm">250mm</option>
                                                    <option key="300mm" value="300mm">300mm</option>
                                                    <option key="350mm" value="350mm">350mm</option>
                                                    <option key="400mm" value="400mm">400mm</option>
                                                    <option key="500mm" value="500mm">500mm</option>
                                                    <option key="600mm" value="600mm">600mm</option>
                                                    <option key="Outros" value="Outros">Outros</option>
                                                </Input>
                                            </div>
                                        </Col>
                                        {/*SENTIDO DO FLUXO*/}
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="sent_fluxo">SENTIDO DO FLUXO:</Label>
                                            <Input className="form-control-sm" type="select" name="sent_fluxo" id="input_sent_fluxo"
                                                value={formValues.sent_fluxo} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="AB" value="AB">AB</option>
                                                <option key="BA" value="BA">BA</option>
                                            </Input>
                                        </Col>
                                        {/*STATUS DIS*/}
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="stat_disp">STATUS DIS:</Label>
                                            <Input className="form-control-sm" type="select" name="stat_disp" id="input_stat_disp"
                                                value={formValues.stat_disp} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Em operação" value="Em operação">Em operação</option>
                                                <option key="Fora de operação" value="Fora de operação">Fora de operação</option>
                                            </Input>
                                        </Col>
                                        {/*FONTE DADO*/}
                                        <Col xxl={2}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="fonte_dado">FONTE DADO:</Label>
                                                <Input type="text" className="form-control form-control-sm" name="fonte_dado"
                                                    id="fonte_dado" value={formValues.fonte_dado} onChange={handleInputChange} />
                                            </div>
                                        </Col>
                                        {/*EXTENSÃO M*/}
                                        <Col xxl={2}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="extensao_m">EXTENSÃO M:</Label>
                                                <Input type="number" className="form-control form-control-sm" name="extensao_m"
                                                    id="extensao_m" value={formValues.extensao_m} onChange={handleInputChange} />
                                            </div>
                                        </Col>
                                        {/*PATH IMAGEM*/}

                                        {/*OBSERVAÇÃO*/}
                                        <Col xxl={2}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="obs">OBS.:</Label>
                                                <Input type="text" className="form-control form-control-sm" name="obs"
                                                    id="obs" value={formValues.obs} onChange={handleInputChange} />
                                            </div>
                                        </Col>
                                        {/*VIDEO*/}

                                        <Col xxl={2}>
                                            <div className="text-end">
                                                <Button className="mt-4 shadow-lg p-2 px-5 ms-auto" color='secondary'
                                                    onClick={sendForm}
                                                > Buscar <i className="ri-arrow-right-line ms-1"></i> </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </form>
                    </CardHeader>

                    <div className="ms-auto">
                        <Button className="mt-4 shadow-lg p-2 px-5 ms-auto" color='success' onClick={handleExportExcel}>Exportar para Excel</Button>
                    </div>

                    <CardBody>
                        <div className="tab-pane active" id="border-navs-home" role="tabpanel">
                            <Row>
                                <Col xl={12}>
                                    <div className="table-responsive">
                                        <Table className="table-hover align-middle table-nowrap mb-0 mt-5">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ "width": "25px" }}>
                                                        <div className="form-check">
                                                            <Input className="form-check-input" type="checkbox" id="checkAll" onChange={handleCheckAll} />
                                                        </div>
                                                    </th>
                                                    <th scope="col">Id</th>
                                                    <th scope="col">CIDADE</th>
                                                    <th scope="col">bairro</th>
                                                    <th scope="col">logradouro</th>
                                                    <th scope="col">mes</th>
                                                    <th scope="col">ano</th>
                                                    <th scope="col">fid</th>
                                                    <th scope="col">Id caixa inspeção</th>
                                                    <th scope="col">n_edificac</th>
                                                    <th scope="col">tipo caixa inspeção</th>
                                                    <th scope="col">local caixa inspeção</th>
                                                    <th scope="col">material tampa</th>
                                                    <th scope="col">diametro tampa</th>
                                                    <th scope="col">pav_calcad</th>
                                                    <th scope="col">prof_poco</th>
                                                    <th scope="col">mat_poco</th>
                                                    <th scope="col">form_poco</th>
                                                    <th scope="col">dim_int_po</th>
                                                    <th scope="col">dim_poco_r</th>
                                                    <th scope="col">mat_tubo_s</th>
                                                    <th scope="col">dim_tubo_s</th>
                                                    <th scope="col">data_dado</th>
                                                    <th scope="col">font_comp</th>
                                                    <th scope="col">font_geom</th>
                                                    <th scope="col">orig_arq</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paginatedData.map((item) => (
                                                    <tr>
                                                        <th scope="row">
                                                            <div className="form-check">
                                                                <Input className="form-check-input" type="checkbox" id={`inlineCheckbox${item.id}`}
                                                                    checked={checkedItems[item.id] || false} onChange={() => handleCheckboxChange(item.id)}
                                                                />
                                                            </div>
                                                        </th>
                                                        <td>#{item.id}</td>
                                                        <th scope="col">{item.cidade}</th>
                                                        <th scope="col">{item.bairro}</th>
                                                        <th scope="col">{item.logradouro}</th>
                                                        <th scope="col">{item.mes}</th>
                                                        <th scope="col">{item.ano}</th>
                                                        <th scope="col">{item.fid}</th>
                                                        <th scope="col">{item.id_ci}</th>
                                                        <th scope="col">{item.n_edificac}</th>
                                                        <th scope="col">{item.tipo_ci}</th>
                                                        <th scope="col">{item.local_ci}</th>
                                                        <th scope="col">{item.mat_tampa}</th>
                                                        <th scope="col">{item.diam_tampa}</th>
                                                        <th scope="col">{item.pav_calcad}</th>
                                                        <th scope="col">{item.prof_poco}</th>
                                                        <th scope="col">{item.mat_poco}</th>
                                                        <th scope="col">{item.form_poco}</th>
                                                        <th scope="col">{item.dim_int_po}</th>
                                                        <th scope="col">{item.dim_poco_r}</th>
                                                        <th scope="col">{item.mat_tubo_s}</th>
                                                        <th scope="col">{item.dim_tubo_s}</th>
                                                        <th scope="col">{item.data_dado}</th>
                                                        <th scope="col">{item.font_comp}</th>
                                                        <th scope="col">{item.font_geom}</th>
                                                        <th scope="col">{item.orig_arq}</th>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                            <ReactPaginate
                                previousLabel={'Anterior'}
                                nextLabel={'Próximo'}
                                breakLabel={'...'}
                                pageCount={Math.ceil(redeStatus.length / itemsPerPage)}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            />
                        </div>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    );
};

export default FilterRedeStatus;