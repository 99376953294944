import { useEffect } from "react";
import { useHistory } from "react-router-dom";

//Dashboard
import Dashboards from "../Pages/Dashboards/DashEsgoto/Dashboards";
import DashboardCasanBomAbrigo from "../Pages/Dashboards/DashEsgoto/DashCasanBomAbrigo";
import DashboardRedeCad from "../Pages/Dashboards/DashEsgoto/DashRedeCad";
import DashboardRedeStatus from "../Pages/Dashboards/DashEsgoto/DashRedeStatus";
import DashboardPvCadastro from "../Pages/Dashboards/DashEsgoto/DashPvCad";
import DashboardPvStatus from "../Pages/Dashboards/DashEsgoto/DashPvStatus";
import DashboardCiCadastro from "../Pages/Dashboards/DashEsgoto/DashCiCad";
import DashboardCiStatus from "../Pages/Dashboards/DashEsgoto/DashCiStatus";

//Maps
import Home from "../Pages/Home";

//Filtros
import FilterResult from "../Pages/Filters/FilterResult";
import Filters from "../Pages/Filters/FilterEsgoto";
import SearchResult from "../Pages/Search/SearchResult";

//Services
import Orders from "../Pages/Services/Orders";

//pages
import Basic404 from '../Pages/AuthenticationInner/Errors/Basic404';

//login
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import NewPasswCreate from "../Pages/Authentication/NewPasswCreate";
import Logout from "../Pages/Authentication/Logout";

// User Profile
import UserProfile from "../Pages/Authentication/UserProfile";

const RedirectToWebmaps = () => {
  const history = useHistory();

  useEffect(() => {
    //history.replace("/fossa-maps");
    history.replace("/webmap");
  }, [history]);

  return null;
};

const authProtectedRoutes = [

  //Dashboard
  { path: "/dashboards", component: Dashboards },
  { path: "/dash-casanbomabrigo", component: DashboardCasanBomAbrigo },
  { path: "/dash-redecad", component: DashboardRedeCad },
  { path: "/dash-redestatus", component: DashboardRedeStatus },
  { path: "/dash-pvcad", component: DashboardPvCadastro },
  { path: "/dash-pvstatus", component: DashboardPvStatus },
  { path: "/dash-cicad", component: DashboardCiCadastro },
  { path: "/dash-cistatus", component: DashboardCiStatus },

  //Maps
  { path: "/webmap", component: Home },

  //Filter
  { path: "/filters", component: Filters },
  { path: "/filteresgoto", component: Filters },
  { path: "/filterdrenagem", component: FilterResult },
  { path: "/filtersolidos", component: FilterResult },
  { path: "/filteragua", component: FilterResult },

 //Search
  { path: "/search-result", component: SearchResult },

  //Order Service
  { path: "/ordem-servico", component: Orders },

  //User Profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: RedirectToWebmaps },

];


const publicRoutes = [

  // Authentication Page
  { path: "/logout", component: Logout },
  { path: "/password-reset", component: ForgetPasswordPage },
  { path: "/password-reset-confirm/:uidb64/:token", component: NewPasswCreate },
  { path: "/login", component: Login },

  //AuthenticationInner pages
  { path: "/auth-404-basic", component: Basic404 },

];

export { authProtectedRoutes, publicRoutes };