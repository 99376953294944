import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import L from 'leaflet';
import 'leaflet.markercluster';
import proj4 from 'proj4';

function useDataFetch(actionFunction, nameFunction, dataProperty) {
    const dispatch = useDispatch();
    const data = useSelector(state => state[nameFunction][dataProperty]);
    useEffect(() => {
        if (!data.length) {
            dispatch(actionFunction());
        }
    }, [dispatch, data]);
    return data;
};

const GenericLayer = ({ map, layerGroup, isChecked, getDataAction, dataName, dataProperty, iconColor }) => {
    const data = useDataFetch(getDataAction, dataName, dataProperty);

    const transformCoord = (coord) => {
        try {
            let matchesLat, matchesLong;
            const sourceEPSG = coord.split(';')[0].split('=')[1];
            proj4.defs("EPSG:" + sourceEPSG, "+proj=utm +zone=22 +south +ellps=GRS80 +units=m +no_defs");
            if (coord.includes("MULTILINESTRING")) {
                const matches = coord.match(/MULTILINESTRING \(\(([^ ]+) ([^)]+), ([^)]+) ([^)]+)\)\)/);
                matchesLat = (parseFloat(matches[1]) + parseFloat(matches[3])) / 2;
                matchesLong = (parseFloat(matches[2]) + parseFloat(matches[4])) / 2;
            } else {
                const matches = coord.match(/POINT \(([^ ]+) ([^)]+)\)/);
                matchesLat = parseFloat(matches[1]);
                matchesLong = parseFloat(matches[2]);
            }
            return [matchesLong, matchesLat];
        } catch (err) {
            console.log(err);
            return null;
        }
    };

    useEffect(() => {
        if (map && layerGroup) {
            const markers = L.markerClusterGroup({
                iconCreateFunction: function (cluster) {
                    return L.divIcon({
                        html: `<div style="background-color: ${iconColor}; border-radius: 50px; padding: 5px 25px 5px 10px;">
                                <span style="color: white; font-size: 14px;">${cluster.getChildCount()}</span>
                                </div>`,
                        className: 'custom-cluster-icon  text-nowrap'
                    });
                }
            });

            data.forEach(cadastro => {
                const coords = transformCoord(cadastro.geom);
                if (coords && coords.length === 2) {
                    const [lat, lng] = coords;
                    L.marker([lat, lng], {
                        icon: L.divIcon({
                            html: `<div style="background-color: ${iconColor}; width: 8px; height: 8px; border-radius: 50%;"></div>`,
                            className: '',
                            iconSize: [8, 8]
                        })
                    }).addTo(markers);
                }
            });

            if (isChecked) {
                layerGroup.addLayer(markers);
                map.addLayer(layerGroup);
            } else {
                layerGroup.clearLayers();
                map.removeLayer(layerGroup);
            }

            return () => {
                layerGroup.clearLayers();
                map.removeLayer(layerGroup);
            };
        }
    }, [data, map, layerGroup, isChecked, iconColor]);

    return null;
};

export default GenericLayer;
