import {
    GET_CI_CADASTROS,
    GET_CI_CADASTROSFILTER,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    ADD_CI_CADASTROS_SUCCESS,
    ADD_CI_CADASTROS_FAIL,
    UPDATE_CI_CADASTROS_SUCCESS,
    UPDATE_CI_CADASTROS_FAIL,
    DELETE_CI_CADASTROS_SUCCESS,
    DELETE_CI_CADASTROS_FAIL,
} from "./actionType";

const INIT_STATE = {
    ciCadastros: [],
    ciCadastrosFilter: [],
};

const CiCadastros = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:

            switch (action.payload.actionType) {
                case GET_CI_CADASTROS:
                    return {
                        ...state,
                        ciCadastros: action.payload.data,
                        isCiCadastrosCreated: false,
                        isCiCadastrosSuccess: true,
                        successMessage: null,
                        errorMessage: null
                    };

                case GET_CI_CADASTROSFILTER:
                    return {
                        ...state,
                        ciCadastrosFilter: action.payload.data,
                        isCiCadastrosCreated: false,
                        isCiCadastrosSuccess: true,
                        successMessage: null,
                        errorMessage: null
                    };

                default:
                    return { ...state };
            }

        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_CI_CADASTROS:
                    return {
                        ...state,
                        error: action.payload.error,
                        isCiCadastrosCreated: false,
                        isCiCadastrosSuccess: true,
                        successMessage: null,
                        errorMessage: null
                    };

                case GET_CI_CADASTROSFILTER:
                    return {
                        ...state,
                        error: action.payload.error,
                        isCiCadastrosCreated: false,
                        isCiCadastrosSuccess: true,
                        successMessage: null,
                        errorMessage: null
                    };

                default:
                    return { ...state };
            }

        case GET_CI_CADASTROS: {
            return {
                ...state,
                isCiCadastrosCreated: false
            };
        }

        case GET_CI_CADASTROSFILTER: {
            return {
                ...state,
                isCiCadastrosCreated: false
            };
        }

        case ADD_CI_CADASTROS_SUCCESS:
            return {
                ...state,
                isCiCadastrosCreated: true,
                ciCadastros: [...state.ciCadastros, action.payload.data],
                isCiCadastrosAdd: true,
                isCiCadastrosAddFail: false,
                successMessage: 'Cadastro adicionado com sucesso', // Defina sua mensagem de sucesso
                errorMessage: null, // Limpa qualquer mensagem de erro anterior
            };

        case ADD_CI_CADASTROS_FAIL:
            return {
                ...state,
                error: action.payload,
                isCiCadastrosAdd: false,
                isCiCadastrosAddFail: true,
                successMessage: null, // Limpa qualquer mensagem de sucesso anterior
                errorMessage: 'Erro ao adicionar cadastro', // Define sua mensagem de erro
            };

        case UPDATE_CI_CADASTROS_SUCCESS:
            return {
                ...state,
                ciCadastros: state.ciCadastros.map(cicadastros =>
                    cicadastros.id.toString() === action.payload.id.toString()
                        ? { ...cicadastros, ...action.payload }
                        : {...cicadastros}
                ),
                isCiCadastrosUpdate: true,
                isCiCadastrosUpdateFail: false,
                successMessage: 'Cadastro atualizado com sucesso', // Defina sua mensagem de sucesso
                errorMessage: null, // Limpa qualquer mensagem de erro anterior
            };

        case UPDATE_CI_CADASTROS_FAIL:
            return {
                ...state,
                error: action.payload,
                isCiCadastrosUpdate: false,
                isCiCadastrosUpdateFail: true,
                successMessage: null, // Limpa qualquer mensagem de sucesso anterior
                errorMessage: 'Erro ao atualizar cadastro', // Define sua mensagem de erro
            };

        case DELETE_CI_CADASTROS_SUCCESS:
            return {
                ...state,
                ciCadastros: state.ciCadastros.filter(
                    cicadastros => cicadastros.id.toString() !== action.payload.cicadastros.toString()
                ),
                isCiCadastrosDelete: true,
                isCiCadastrosDeleteFail: false
            };

        case DELETE_CI_CADASTROS_FAIL:
            return {
                ...state,
                error: action.payload,
                isCiCadastrosDelete: false,
                isCiCadastrosDeleteFail: true
            };

        default:
            return { ...state };
    }
};

export default CiCadastros;