import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useSelector, useDispatch } from "react-redux";
import { getCiStatusfilter } from "../../../../Store/cistatusesgoto/action";
import { Card, CardHeader, CardFooter, CardBody, Col, Row, Input, Label, Table, Button } from "reactstrap";
import * as XLSX from 'xlsx';
import FileSaver from "file-saver";


const FilterCiStatus = () => {
    const [formValues, setFormValues] = useState({
        id_ci: "", n_edificac: "", tipo_ci: "", local_ci: "",
        mat_tampa: "", diam_tampa: "", pav_calcad: "", prof_poco: "", mat_poco: "",
        form_poco: "", dim_int_po: "", dim_poco_r: "", mat_tubo_s: "", dim_tubo_s: "",
        data_dado: "", font_comp: "", font_geom: "", orig_arq: "", cidade: "",
        bairro: "", logradouro: "", mes: "", ano: "",
    });
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value === "Selecionar" ? "" : value });
    };
    const dispatch = useDispatch();
    const sendForm = () => { dispatch(getCiStatusfilter(formValues)); };
    const { ciStatusFilter } = useSelector((state) => ({ ciStatusFilter: state.CiStatus.ciStatusFilter }));
    useEffect(() => { if (ciStatusFilter && !ciStatusFilter.length) { dispatch(getCiStatusfilter(formValues)); } }, [dispatch, ciStatusFilter]);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 15;
    const handlePageClick = ({ selected }) => { setCurrentPage(selected) };
    const paginatedData = ciStatusFilter.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    const [selectAll, setSelectAll] = useState(false);
    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckAll = () => {
        setSelectAll(!selectAll);
        const newCheckedItems = {};
        ciStatusFilter.forEach((item) => {
            newCheckedItems[item.id] = !selectAll;
        });
        setCheckedItems(newCheckedItems);
        //console.log(checkedItems);
    };

    const handleCheckboxChange = (id) => {
        const newCheckedItems = { ...checkedItems, [id]: !checkedItems[id] };
        setCheckedItems(newCheckedItems);
    };

    const handleExportExcel = () => {
        // Filtra os itens marcados
        const selectedItems = ciStatusFilter.filter((item) => checkedItems[item.id]);
        // Cria um array de dados para a planilha
        const dataForExcel = selectedItems.map((item) => ({
            ID: item.id,
            ID_CI_STATUS: item.id_cistatu,
            ID_CI: item.id_ci,
            LOGRADOURO: item.logradouro,
            N_EDIF: item.n_edificac,
            CIDADE: item.cidade,
            BAIRRO: item.bairro,
            DATA_DADO: item.data_dado,
            ABRAS_TAMPA: item.abras_tamp,
            ESTADO_TAMPA: item.est_tampa,
            ESTADO_PAVIMENTAÇÂO: item.est_pav,
            NIVEL_GREIDE: item.nivel_grei,
            ESTADO_LAJE: item.est_laje,
            ESTADO_ATENÇÃO: item.est_atenca,
            IMG_ACAB_E: item.img_acab_e,
            STATUS_ACESSO: item.stat_acess,
            C_ACAB_EXTERNO: item.c_acab_ext,
            C_ACAB_INTERNA: item.c_acab_int,
            JUNT_EDIF_CI: item.jun_edi_ci,
            JUNT_CI_RAM: item.jun_ci_ram,
            INFILTRAÇÃO: item.infiltra,
            INFIL_PARAS: item.inf_paras,
            STATUS_ESCOAMENTO: item.stat_escoa,
            STATUS_DISP: item.stat_disp,
            IMG_ACAB_I: item.img_acab_i,
            FONT_COMP: item.font_comp,
            FONT_GEOM: item.font_geom,
            ORIGEM_ARQ: item.orig_arq,
            RESOLVIDA: item.resolvida,
            DATA_RESOLUÇÃO: item.data_resol,
            PRIORIDADE: item.prioridade,
            RESPONSAVEL: item.responsave,
        }));
        const ws = XLSX.utils.json_to_sheet(dataForExcel);
        //const wb = XLSX.utils.book_new();
        const wb = XLSX.utils.book_new({ Sheets: { data: ws }, SheetsNames: ["data"] });
        XLSX.utils.book_append_sheet(wb, ws, 'Lista CiStatus');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: "xlsx" })
        FileSaver.saveAs(data, "Selecionados_Ci_Status" + ".xlsx")
    };

    return (
        <React.Fragment>
            <div className="content">
                <Card>
                    <CardHeader>
                        <form className="app-search d-none d-md-block">
                            <Row>
                                <Col xxl={12}>
                                    <Row className="gy-0">
                                        <Col xxl={2}>
                                            <Label className="form-label mt-0 fs-12 mb-0" for="id_cistatu">ID CI STATUS:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="id_cistatu"
                                                id="id_cistatu" value={formValues.id_cistatu} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={1}>
                                            <Label className="form-label mt-0 fs-12 mb-0" for="id_ci">ID CI:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="id_ci"
                                                id="id_ci" value={formValues.id_ci} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={4}>
                                            <Label className="form-label mt-0 fs-12 mb-0" for="logradouro">LOGRADOURO:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="logradouro"
                                                id="logradouro" value={formValues.logradouro} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={3}>
                                            <Label className="form-label mt-0 fs-12 mb-0" for="bairro">BAIRRO:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="bairro"
                                                id="bairro" value={formValues.bairro} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-0 fs-12 mb-0" for="cidade">CIDADE:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="cidade"
                                                id="cidade" value={formValues.cidade} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="data_dado">DATA DOS DADOS:</Label>
                                            <Input type="date" className="form-control form-control-sm" name="data_dado"
                                                id="data_dado" value={formValues.data_dado} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0" for="abras_tamp">ABRASIVIDADE TAMPA:</Label>
                                            <Input className="form-control-sm" type="select" name="abras_tamp" id="inputAbras_tamp"
                                                value={formValues.abras_tamp} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Adequada" value="Adequada">Adequada</option>
                                                <option key="Desgastada" value="Desgastada">Desgastada</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0" for="est_tampa">ESTADO DA TAMPA:</Label>
                                            <Input className="form-control-sm" type="select" name="est_tampa" id="inputEst_tampa"
                                                value={formValues.est_tampa} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Adequado" value="Adequado">Adequado</option>
                                                <option key="Necessidade de Manutenção" value="Necessidade de Manutenção">Necessidade de Manutenção</option>
                                                <option key="Necessidade de Substituição" value="Necessidade de Substituição">Necessidade de Substituição</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="est_pav" >ESTADO DA PAVIMENTAÇÂO:</Label>
                                            <Input className="form-control-sm" type="select" name="est_pav" id="inputEst_pav"
                                                value={formValues.est_pav} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Adequada" value="Adequada">Adequada</option>
                                                <option key="Pequenas Imperfeições" value="Pequenas Imperfeições">Pequenas Imperfeições</option>
                                                <option key="Necessidade de Manutenção Estrutural" value="Necessidade de Manutenção Estrutural">Necessidade de Manutenção Estrutural</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="nivel_grei">NIVEL GREI:</Label>
                                            <Input className="form-control-sm" type="select" name="nivel_grei" id="inputLocalCi"
                                                value={formValues.nivel_grei} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Sim" value="Sim">Sim</option>
                                                <option key="Não" value="Não">Não</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="est_laje">ESTADO DA LAJE:</Label>
                                            <Input className="form-control-sm" type="select" name="est_laje" id="inputEst_laje"
                                                value={formValues.est_laje} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Adequado" value="Adequado">Adequado</option>
                                                <option key="Não foi possível avaliar" value="Não foi possível avaliar">Não foi possível avaliar</option>
                                                <option key="Não tem laje" value="Não tem laje">Não tem laje</option>
                                                <option key="Necessidade de Manutenção Estrutural" value="Necessidade de Manutenção Estrutural">Necessidade de Manutenção Estrutural</option>
                                                <option key="Risco de Colapso da Estrutura" value="Risco de Colapso da Estrutura">Risco de Colapso da Estrutura</option>
                                                <option key="Pequenas Imperfeições" value="Pequenas Imperfeições">Pequenas Imperfeições</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="est_atenca">ESTADO DE ATENÇÃO:</Label>
                                            <Input className="form-control-sm" type="select" name="est_atenca" id="inputEst_atenca"
                                                value={formValues.est_atenca} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Sem Riscos Iminentes" value="Sem Riscos Iminentes">Sem Riscos Iminentes</option>
                                                <option key="Com Riscos Iminentes" value="Com Riscos Iminentes">Com Riscos Iminentes</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="stat_acess">STATUS ACESSO:</Label>
                                            <Input className="form-control-sm" type="select" name="stat_acess" id="inputStat_acess"
                                                value={formValues.stat_acess} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Acessível" value="Acessível">Acessível</option>
                                                <option key="Acessível com dificuldades" value="Acessível com dificuldades">Acessível com dificuldades</option>
                                                <option key="Inacessível" value="Inacessível">Inacessível</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="c_acab_ext">C ACABAMENTO EXTERNO:</Label>
                                            <Input className="form-control-sm" type="select" name="c_acab_ext" id="inputC_acab_ext"
                                                value={formValues.c_acab_ext} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Adequado" value="Adequado">Adequado</option>
                                                <option key="Pequenas Imperfeições" value="Pequenas Imperfeições">Pequenas Imperfeições</option>
                                                <option key="Necessidade de Manutenção Estrutural" value="Necessidade de Manutenção Estrutural">Necessidade de Manutenção Estrutural</option>
                                                <option key="Risco de Colapso da Estrutura" value="Risco de Colapso da Estrutura">Risco de Colapso da Estrutura</option>
                                                <option key="Colapsado" value="Colapsado">Colapsado</option>
                                                <option key="Não foi possível avaliar" value="Não foi possível avaliar">Não foi possível avaliar</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="c_acab_int">C ACABAMENTO INTERNO:</Label>
                                            <Input className="form-control-sm" type="select" name="c_acab_int" id="inputC_acab_int"
                                                value={formValues.c_acab_int} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Calha de Escoamento Bem Acabada" value="Calha de Escoamento Bem Acabada">Calha de Escoamento Bem Acabada</option>
                                                <option key="Calha de Escoamento com Imperfeições" value="Calha de Escoamento com Imperfeições">Calha de Escoamento com Imperfeições</option>
                                                <option key="Calha de Escoamento Irregular" value="Calha de Escoamento Irregular">Calha de Escoamento Irregular</option>
                                                <option key="Sem Calha de Escoamento" value="Sem Calha de Escoamento">Sem Calha de Escoamento</option>
                                                <option key="Fundo Quebrado" value="Fundo Quebrado">Fundo Quebrado</option>
                                                <option key="Sem Fundo" value="Sem Fundo">Sem Fundo</option>
                                                <option key="Não Foi Possível Avaliar" value="Não Foi Possível Avaliar">Não Foi Possível Avaliar</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="jun_edi_ci">JUN EDIF CI:</Label>
                                            <Input className="form-control-sm" type="select" name="jun_edi_ci" id="inputJun_edi_ci"
                                                value={formValues.jun_edi_ci} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Adequada" value="Adequada">Adequada</option>
                                                <option key="Inadequada" value="Inadequada">Inadequada</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="jun_ci_ram">JUN CI RAM:</Label>
                                            <Input className="form-control-sm" type="select" name="jun_ci_ram" id="inputJun_ci_ram"
                                                value={formValues.jun_ci_ram} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Adequada" value="Adequada">Adequada</option>
                                                <option key="Inadequada" value="Inadequada">Inadequada</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="infiltra">INFILTRAÇÃO:</Label>
                                            <Input className="form-control-sm" type="select" name="infiltra" id="inputInfiltra"
                                                value={formValues.infiltra} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Sem Infiltração" value="Sem Infiltração">Sem Infiltração</option>
                                                <option key="Infiltração Parcial" value="Infiltração Parcial">Infiltração Parcial</option>
                                                <option key="Infiltração Total" value="Infiltração Total">Infiltração Total</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="inf_paras">INFILTRAÇÃO PARAS:</Label>
                                            <Input className="form-control-sm" type="select" name="inf_paras" id="inputInf_paras"
                                                value={formValues.inf_paras} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Sim" value="Sim">Sim</option>
                                                <option key="Não" value="Não">Não</option>
                                                <option key="Não Foi Possível Avaliar" value="Não Foi Possível Avaliar">Não Foi Possível Avaliar</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="stat_escoa">STATUS ESCOAMENTO:</Label>
                                            <Input className="form-control-sm" type="select" name="stat_escoa" id="inputStat_escoa"
                                                value={formValues.stat_escoa} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Livre" value="Livre">Livre</option>
                                                <option key="Parcial por Detritos ou Sólidos" value="Parcial por Detritos ou Sólidos">Parcial por Detritos ou Sólidos</option>
                                                <option key="Parcial por Areia" value="Parcial por Areia">Parcial por Areia</option>
                                                <option key="Restrito por Detritos ou Sólidos" value="Restrito por Detritos ou Sólidos">Restrito por Detritos ou Sólidos</option>
                                                <option key="Restrito por Areia" value="Restrito por Areia">Restrito por Areia</option>
                                                <option key="Transbordando" value="Transbordando">Transbordando</option>
                                                <option key="Não Foi Possível Avaliar" value="Não Foi Possível Avaliar">Não Foi Possível Avaliar</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="stat_disp">STATUS DISP:</Label>
                                            <Input className="form-control-sm" type="select" name="stat_disp" id="inputStat_disp"
                                                value={formValues.stat_disp} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Conectado à Rede de Esgoto" value="Conectado à Rede de Esgoto">Conectado à Rede de Esgoto</option>
                                                <option key="Edificação Não Conectada à CI" value="Edificação Não Conectada à CI">Edificação Não Conectada à CI</option>
                                                <option key="CI Não Conectada à rede de Esgoto" value="CI Não Conectada à rede de Esgoto">CI Não Conectada à rede de Esgoto</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="font_comp">FONTE COMP:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="font_comp"
                                                id="font_comp" value={formValues.font_comp} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="font_geom">FONTE DA GEOMET:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="font_geom"
                                                id="font_geom" value={formValues.font_geom} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="orig_arq">ORIGEM DOS ARQUIVOS:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="orig_arq"
                                                id="orig_arq" value={formValues.orig_arq} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="resolvida">RESOLVIDA:</Label>
                                            <Input className="form-control-sm" type="select" name="resolvida" id="inputResolvida"
                                                value={formValues.resolvida} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Sim" value="Sim">Sim</option>
                                                <option key="Não" value="Não">Não</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="data_resol">DATA RESOLVIDA:</Label>
                                            <Input type="date" className="form-control form-control-sm" name="data_resol"
                                                id="data_resol" value={formValues.data_resol} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="prioridade">PRIORIDADE:</Label>
                                            <Input className="form-control-sm" type="select" name="prioridade" id="inputPrioridade"
                                                value={formValues.prioridade} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Adequado" value="Adequado">Adequado</option>
                                                <option key="Necessita de Atenção ou Limpeza" value="Necessita de Atenção ou Limpeza">Necessita de Atenção ou Limpeza</option>
                                                <option key="Manutenção Programada" value="Manutenção Programada">Manutenção Programada</option>
                                                <option key="Manutenção Emergencial" value="Manutenção Emergencial">Manutenção Emergencial</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="responsave">RESPONSÁVEL:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="responsave"
                                                id="responsave" value={formValues.responsave} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={2}>
                                            <div className="text-end ms-auto end-0 hstack justify-content-end">
                                                <Button className="mt-4 shadow-lg p-2 px-5 ms-auto" color='secondary'
                                                    onClick={sendForm}
                                                > Buscar <i className="ri-arrow-right-line ms-1"></i> </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </form>
                    </CardHeader>

                    <div className="ms-auto">
                        <Button className="mt-4 shadow-lg p-2 px-5 ms-auto" color='success' onClick={handleExportExcel}>Exportar para Excel</Button>
                    </div>
                    
                    <CardBody>
                        <div className="tab-pane active" id="border-navs-home" role="tabpanel">
                            <Row>
                                <Col xl={12}>
                                    <div className="table-responsive">
                                        <Table className="table-hover align-middle table-nowrap mb-0 mt-5">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ "width": "25px" }}>
                                                        <div className="form-check">
                                                            <Input className="form-check-input" type="checkbox" id="checkAll" onChange={handleCheckAll} />
                                                        </div>
                                                    </th>
                                                    <th scope="col">Id</th>
                                                    <th scope="col">Id CI STATUS</th>
                                                    <th scope="col">Id CI</th>
                                                    <th scope="col">LOGRADOURO</th>
                                                    <th scope="col">N. EDIF</th>
                                                    <th scope="col">CIDADE</th>
                                                    <th scope="col">BAIRRO</th>
                                                    <th scope="col">DATA DADO</th>
                                                    <th scope="col">ABRAS TAMPA</th>
                                                    <th scope="col">ESTADO TAMPA</th>
                                                    <th scope="col">ESTADO PAVIMENTAÇÂO</th>
                                                    <th scope="col">NIVEL GREIDE</th>
                                                    <th scope="col">ESTADO LAJE</th>
                                                    <th scope="col">ESTADO ATENÇÃO</th>
                                                    <th scope="col">IMG ACAB E</th>
                                                    <th scope="col">STATUS ACESSO</th>
                                                    <th scope="col">C ACAB EXTERNO</th>
                                                    <th scope="col">C ACAB INTERNA</th>
                                                    <th scope="col">JUNT EDIF CI</th>
                                                    <th scope="col">JUNT CI RAM</th>
                                                    <th scope="col">INFILTRAÇÃO</th>
                                                    <th scope="col">INFIL PARAS</th>
                                                    <th scope="col">STATUS ESCOAMENTO</th>
                                                    <th scope="col">STATUS DISP</th>
                                                    <th scope="col">IMG ACAB I</th>
                                                    <th scope="col">FONT COMP</th>
                                                    <th scope="col">FONT GEOM</th>
                                                    <th scope="col">ORIGEM ARQ</th>
                                                    <th scope="col">RESOLVIDA</th>
                                                    <th scope="col">DATA RESOLUÇÃO</th>
                                                    <th scope="col">PRIORIDADE</th>
                                                    <th scope="col">RESPONSAVEL</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paginatedData.map((item, index) => (
                                                    <tr>
                                                        <th scope="row">
                                                            <div className="form-check">
                                                                <Input className="form-check-input" type="checkbox" id={`inlineCheckbox${item.id}`}
                                                                    checked={checkedItems[item.id] || false} onChange={() => handleCheckboxChange(item.id)}
                                                                />
                                                            </div>
                                                        </th>
                                                        <td>#{item.id}</td>
                                                        <th scope="col">{item.id_cistatu}</th>
                                                        <th scope="col">{item.id_ci}</th>
                                                        <th scope="col">{item.logradouro}</th>
                                                        <th scope="col">{item.n_edificac}</th>
                                                        <th scope="col">{item.cidade}</th>
                                                        <th scope="col">{item.bairro}</th>
                                                        <th scope="col">{item.data_dado}</th>
                                                        <th scope="col">{item.abras_tamp}</th>
                                                        <th scope="col">{item.est_tampa}</th>
                                                        <th scope="col">{item.est_pav}</th>
                                                        <th scope="col">{item.nivel_grei}</th>
                                                        <th scope="col">{item.est_laje}</th>
                                                        <th scope="col">{item.est_atenca}</th>
                                                        <th scope="col">{item.img_acab_e}</th>
                                                        <th scope="col">{item.stat_acess}</th>
                                                        <th scope="col">{item.c_acab_ext}</th>
                                                        <th scope="col">{item.c_acab_int}</th>
                                                        <th scope="col">{item.jun_edi_ci}</th>
                                                        <th scope="col">{item.jun_ci_ram}</th>
                                                        <th scope="col">{item.infiltra}</th>
                                                        <th scope="col">{item.inf_paras}</th>
                                                        <th scope="col">{item.stat_escoa}</th>
                                                        <th scope="col">{item.stat_disp}</th>
                                                        <th scope="col">{item.img_acab_i}</th>
                                                        <th scope="col">{item.font_comp}</th>
                                                        <th scope="col">{item.font_geom}</th>
                                                        <th scope="col">{item.orig_arq}</th>
                                                        <th scope="col">{item.resolvida}</th>
                                                        <th scope="col">{item.data_resol}</th>
                                                        <th scope="col">{item.prioridade}</th>
                                                        <th scope="col">{item.responsave}</th>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                            <ReactPaginate previousLabel={'Anterior'} breakLabel={'...'} nextLabel={'Próximo'} activeClassName={'active'}
                                pageCount={Math.ceil(ciStatusFilter.length / itemsPerPage)} marginPagesDisplayed={1} pageRangeDisplayed={3}
                                onPageChange={handlePageClick} containerClassName={'pagination'} subContainerClassName={'pages pagination'} />
                        </div>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    );
};

export default FilterCiStatus;