import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Alert, Card, Col, Container, Row, Form, Label, Input, FormFeedback } from 'reactstrap';
import { useParams, useNavigate } from 'react-router-dom';
import logoLight from "../../Assets/images/sanapp/logo-sanapp-light.jpg";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { useSelector, useDispatch } from "react-redux";

import { userNewPassword } from "../../Store/actions";

//formik
import { useFormik } from 'formik';
import * as Yup from 'yup';

const NewPasswCreate = () => {
    document.title = "Create New Password | Sanapp";

    const dispatch = useDispatch();
    const [passwordShow, setPasswordShow] = useState(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
    const { uidb64, token } = useParams();

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            new_password: "",
            confirm_password: "",
        },
        validationSchema: Yup.object({
            new_password: Yup.string()
                .min(8, 'Password must be at least 8 characters')
                .matches(RegExp('(.*[a-z].*)'), 'At least lowercase letter')
                .matches(RegExp('(.*[A-Z].*)'), 'At least uppercase letter')
                .matches(RegExp('(.*[0-9].*)'), 'At least one number')
                .required("This field is required"),
            confirm_password: Yup.string()
                .oneOf([Yup.ref("new_password")], "Both passwords need to be the same")
                .required("Confirm Password Required"),
        }),
        onSubmit: (values) => {
            //console.log({ ...values, uidb64: uidb64, token: token });
            dispatch(userNewPassword({ ...values, uidb64: uidb64, token: token }));
        }
    });

    const { newError, newSuccessMsg } = useSelector(state => ({
        newError: state.NewPassword.newError,
        newSuccessMsg: state.NewPassword.newSuccessMsg,
    }));

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-wrapper auth-bg-cover py-1 d-flex justify-content-center align-items-center min-vh-100">
                    <div className="bg-overlay"></div>
                    <div className="auth-page-content overflow-hidden pt-lg-1 m-5">
                        <Container >
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center mt-0 mb-2 text-white-50">
                                        <div>
                                            <Link to="/" className="d-inline-block auth-logo">
                                                <img className="logo-sanapp" src={logoLight} alt="" height="20" />
                                            </Link>
                                        </div>
                                        <div className="space-sidebar"><br></br></div>
                                        <p className="mt-3 fs-15 fw-medium">Gestão inteligente de ativos em saneamento 4.0</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <Row className="justify-content-center">
                                        <Col md={8} lg={6} xl={5}>
                                            <Card className="overflow-hidden">
                                                <div className="p-lg-4 p-1 m-4">
                                                    <h5 className="text-primary">Criar nova senha</h5>
                                                    <p className="text-muted">Sua nova senha deve ser diferente da usada anteriormente.</p>

                                                    <div className="p-2">

                                                        {newError && newError ? (
                                                            <Alert color="danger" style={{ marginTop: "13px" }}>
                                                                {newError}
                                                            </Alert>
                                                        ) : null}
                                                        {newSuccessMsg ? (
                                                            <Alert color="success" style={{ marginTop: "13px" }}>
                                                                {newSuccessMsg}
                                                            </Alert>
                                                        ) : null}

                                                        <Form onSubmit={validation.handleSubmit} action="/auth-signin-basic">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="new_password-input">Senha</Label>
                                                                <div className="position-relative auth-pass-inputgroup">
                                                                    <Input
                                                                        type={passwordShow ? "text" : "password"}
                                                                        className="form-control pe-5 new_password-input"
                                                                        placeholder="Coloque a senha"
                                                                        id="new_password-input"
                                                                        name="new_password"
                                                                        value={validation.values.new_password}
                                                                        onBlur={validation.handleBlur}
                                                                        onChange={validation.handleChange}
                                                                        invalid={validation.errors.new_password && validation.touched.new_password ? true : false}
                                                                    />
                                                                    {validation.errors.new_password && validation.touched.new_password ? (
                                                                        <FormFeedback type="invalid">{validation.errors.new_password}</FormFeedback>
                                                                    ) : null}
                                                                    <Button color="link" onClick={() => setPasswordShow(!passwordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                                                        id="password-addon"><i className="ri-eye-fill align-middle"></i></Button>
                                                                </div>
                                                                <div id="passwordInput" className="form-text">Deve ter pelo menos 8 caracteres.</div>
                                                            </div>

                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="confirm-password-input">Confirmar Senha</Label>
                                                                <div className="position-relative auth-pass-inputgroup mb-3">
                                                                    <Input
                                                                        type={confirmPasswordShow ? "text" : "password"}
                                                                        className="form-control pe-5 password-input"
                                                                        placeholder="Confirme a senha"
                                                                        id="confirm-password-input"
                                                                        name="confirm_password"
                                                                        value={validation.values.confirm_password}
                                                                        onBlur={validation.handleBlur}
                                                                        onChange={validation.handleChange}
                                                                        invalid={validation.errors.confirm_password && validation.touched.confirm_password ? true : false}
                                                                    />
                                                                    {validation.errors.confirm_password && validation.touched.confirm_password ? (
                                                                        <FormFeedback type="invalid">{validation.errors.confirm_password}</FormFeedback>
                                                                    ) : null}
                                                                    <Button color="link" onClick={() => setConfirmPasswordShow(!confirmPasswordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon">
                                                                        <i className="ri-eye-fill align-middle"></i></Button>
                                                                </div>
                                                            </div>

                                                            <div id="password-contain" className="p-3 bg-light mb-2 rounded">
                                                                <h5 className="fs-13">Senha deve conter:</h5>
                                                                <p id="pass-length" className="invalid fs-12 mb-2">Minimo <b>8 caracterws</b></p>
                                                                <p id="pass-lower" className="invalid fs-12 mb-2">At <b>lowercase</b> letter (a-z)</p>
                                                                <p id="pass-upper" className="invalid fs-12 mb-2">At least <b>uppercase</b> letter (A-Z)</p>
                                                                <p id="pass-number" className="invalid fs-12 mb-0">A least <b>number</b> (0-9)</p>
                                                            </div>

                                                            <div className="mt-4">
                                                                <Button color="success" className="w-100" type="submit">Alterar Senha</Button>
                                                            </div>
                                                        </Form>
                                                    </div>

                                                    <div className="mt-3 text-center">
                                                        <p className="mb-0">Espere, eu lembrei da minha senha... <Link to="/auth-signin-cover" className="fw-semibold text-primary text-decoration-underline"> Click aqui </Link> </p>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <footer className="footer">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center">
                                        <p className="mb-0">&copy; {new Date().getFullYear()} Sanapp <i className="mdi mdi-rocket text-success"></i> gestão de ativos em saneamento</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </footer>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default NewPasswCreate;