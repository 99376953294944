import { APIClient } from "./api_helper";
import * as url from "./url_helper";

const api = new APIClient();

// CRIAR ORDEM DE SERVICO
export const postORDERSERVICE = data => api.create(url.ORDEM_SERVICO, data);

// CI_CADASTROS
// get CI_CADASTROS
export const getCiCadastros = data => api.get(url.CI_CADASTROS, data);
// get CI_CADASTROS
export const getCiCadastrosfilter = data => api.get(url.CI_CADASTROSFILTER, data);
// add CiCadastros
export const addNewCiCadastros = data => api.create(url.ADD_NEW_CI_CADASTROS, data);
// update CiCadastros
export const updateCiCadastros = data => api.update(url.UPDATE_CI_CADASTROS, data);
// delete CiCadastros
export const deleteCiCadastros = data => api.delete(url.DELETE_CI_CADASTROS + '/' + data);

// CI_Status
// get CI_Status
export const getCiStatus = data => api.get(url.CI_STATUS, data);
// get CI_Status
export const getCiStatusfilter = data => api.get(url.CI_STATUSFILTER, data);
// add CiStatus
export const addNewCiStatus = data => api.create(url.ADD_CI_STATUS, data);
// update CiStatus
export const updateCiStatus = data => api.update(url.UPDATE_CI_STATUS, data);
// delete CiStatus
export const deleteCiStatus = data => api.delete(url.DELETE_CI_STATUS + '/' + data);

// PV_CADASTROS
// get PV_CADASTROS
export const getPvCadastros = data => api.get(url.PV_CADASTROS, data);
// add PvCadastros
export const addNewPvCadastros = data => api.create(url.ADD_NEW_PV_CADASTROS, data);
// update PvCadastros
export const updatePvCadastros = data => api.update(url.UPDATE_PV_CADASTROS, data);
// delete PvCadastros
export const deletePvCadastros = data => api.delete(url.DELETE_PV_CADASTROS + '/' + data);

// PV_STATUS
// get PV_STATUS
export const getPvStatus = data => api.get(url.PV_STATUS, data);
// add PvStatus
export const addNewPvStatus = data => api.create(url.ADD_PV_STATUS, data);
// update PvStatus
export const updatePvStatus = data => api.update(url.UPDATE_PV_STATUS, data);
// delete PvStatus
export const deletePvStatus = data => api.delete(url.DELETE_PV_STATUS + '/' + data);

// PV_SERVICES
// get PV_SERVICES
export const getPvServices = data => api.get(url.PV_SERVICES, data);
// get PV_SERVICES
export const getPvServicesfilter = data => api.get(url.PV_SERVICESFILTER, data);
// add PV_SERVICES
export const addNewPvServices = data => api.create(url.ADD_PV_SERVICES, data);
// update PV_SERVICES
export const updatePvServices = (data, id_new) => api.update(url.UPDATE_PV_SERVICES, data, id_new ? id_new : null);
// delete PV_SERVICES
export const deletePvServices = data => api.delete(url.DELETE_PV_SERVICES + '/' + data);

// REDE_CADASTROS
// get REDE_CADASTROS
export const getRedeCadastros = data => api.get(url.REDE_CADASTROS, data);
// add REDE_CADASTROS
export const addNewRedeCadastros = data => api.create(url.ADD_NEW_REDE_CADASTROS, data);
// update REDE_CADASTROS
export const updateRedeCadastros = data => api.update(url.UPDATE_REDE_CADASTROS, data);
// delete REDE_CADASTROS
export const deleteRedeCadastros = data => api.delete(url.DELETE_REDE_CADASTROS + '/' + data);

// REDE_STATUS
// get REDE_STATUS
export const getRedeStatus = data => api.get(url.REDE_STATUS, data);
// add REDE_STATUS
export const addNewRedeStatus = data => api.create(url.ADD_REDE_STATUS, data);
// update REDE_STATUS
export const updateRedeStatus = data => api.update(url.UPDATE_REDE_STATUS, data);
// delete REDE_STATUS
export const deleteRedeStatus = data => api.delete(url.DELETE_REDE_STATUS + '/' + data);

// CAMGEO
// get CAMGEO
export const getCamGeo = data => api.get(url.CAMGEO, data);
// add PvStatus
export const addNewCamGeo = data => api.create(url.ADD_CAMGEO, data);
// update PvStatus
export const updateCamGeo = data => api.update(url.UPDATE_CAMGEO, data);
// delete PvStatus
export const deleteCamGeo = data => api.delete(url.DELETE_CAMGEO + '/' + data);

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("token");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
export const postRegister = data => api.create(url.POST_REGISTER, data);

// Login Method
export const postLogin = data => api.create(url.POST_LOGIN, data);

// postForgetPwd
export const postForgetPwd = data => api.create(url.POST_PASSWORD_FORGET, data);

// Login Method
export const postJwtLogin = data => api.create(url.POST_JWT_LOGIN, data);

// postForgetPwd
export const postJwtForgetPwd = data => api.create(url.POST_JWT_PASSWORD_FORGET, data);

// post New Pwd
export const postNewPwd = data => api.create(url.POST_NEW_PASSWORD + '/' + data.idx, data);
export const postJwtNewPwd = data => api.create(url.POST_JWT_NEW_PASSWORD + data.uidb64 + '/' + data.token + '/', data);

// postSocialLogin
export const postSocialLogin = data => api.create(url.SOCIAL_LOGIN, data);

// Edit profile
export const postProfile = (data) => api.update(url.POST_EDIT_PROFILE + '/' + data.idx, data);
export const postJwtProfile = data => api.create(url.POST_EDIT_JWT_PROFILE, data);

// Register Method
export const postJwtRegister = (url, data) => {
  return api.create(url, data)
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};