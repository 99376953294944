import React, { useState, useEffect } from 'react';
import { LayersControl, WMSTileLayer, useMap } from 'react-leaflet';
import L from 'leaflet';
import { useSelector, useDispatch } from "react-redux";
import { getCamGeo } from "../../../../Store/camgeoserver/action";

const { Overlay } = LayersControl;

const Legend = ({ camada }) => {
    const map = useMap();

    useEffect(() => {
        const fontName = [
            'Calibri%20Light%20Italic', 'Microsoft%20PhagsPa%20Bold',
            'Lucida%20Sans%20Typewriter%20Oblique', 'ChaparralPro-Regular',
            'Californian%20FB%20Italic', 'Times%20New%20Roman'
        ];
        const font1 = `&LEGEND_OPTIONS=fontName:${fontName[1]};fontAntiAliasing:true;fontColor:000044;fontSize:10;bgColor:0xFDFDFD;dpi:100`
        //const font2 = `&LEGEND_OPTIONS=forceRule:True;dx:0.2;dy:0.2;mx:0.2;my:0.2;fontStyle:italic;borderColor:ff0000;border:false;fontColor:555555;fontSize:11`
        //const font3 = `&LEGEND_OPTIONS=fontName:${fontName[0]};fontStyle:bold;borderColor:0000ff;border:true;fontColor:000099;fontSize:11;bgColor:0xEEEEFF`
        const legendUrl = `${camada.base_url}/${camada.server}/${camada.dir}/${camada.layertype}?request=GetLegendGraphic&format=image/png&width=15&height=10&layer=${camada.dir}:${camada.layers}${font1}`;
        const legend = L.control({ position: "bottomleft" });
        legend.onAdd = () => {
            const div = L.DomUtil.create("div", "info legend");
            const img = L.DomUtil.create("img", "legend-image", div);
            img.src = legendUrl;
            img.alt = "Legenda";
            return div;
        };
        legend.addTo(map);
        return () => {
            legend.remove();
        };
    }, [map, camada]);
    return null;
};

function useDataFetch(actionFunction, nameFunction, dataProperty) {
    const dispatch = useDispatch();
    const data = useSelector(state => state[nameFunction][dataProperty]);
    useEffect(() => {
        if (!data.length) {
            dispatch(actionFunction());
        }
    }, [dispatch, data]);
    return data;
};

const CamadasWMS = () => {
    const [camadasGeo, setCamadasGeo] = useState([]);
    const dataWMS = useDataFetch(getCamGeo, 'CamGeo', 'camGeo');

    const handleLayerAdd = (camada) => {
        setCamadasGeo((camadasGeo) => {
            if (!camadasGeo.includes(camada)) {
                return [...camadasGeo, camada];
            }
            return camadasGeo;
        });
    };

    const handleLayerRemove = (camada) => {
        setCamadasGeo((camadasGeo) => camadasGeo.filter((layer) => layer.id !== camada.id));
    };

    return (
        <>
            {dataWMS.map((camada) => (
                <Overlay
                    key={camada.id}
                    id={camada.id}
                    name={camada.name}
                    checked={camada?.iniciar_visivel === "Sim" ? true : false}
                >
                    <WMSTileLayer
                        url={`${camada.base_url}/${camada.server}/${camada.dir}/${camada.layertype}`}
                        layers={`${camada.dir}:${camada.layers}`}
                        version={camada.version}
                        minZoom={1}
                        maxZoom={25}
                        width={camada.width}
                        height={camada.height}
                        zIndex={100}
                        style={{
                            zIndex:99,
                        }}
                        params={{
                            transparent: camada.transparent,
                            attribution: camada.attribution,
                            format: camada.format,
                        }}
                        eventHandlers={{
                            add: () => handleLayerAdd(camada), // Atualiza a camada ativa ao adicionar
                            remove: () => handleLayerRemove(camada), // Atualiza a camada ativa ao remover
                        }}
                    //className={styles.overlay}
                    //format={params.format}
                    //transparent={params.transparent}
                    //opacity={params.opacity}
                    //styles={params.styles}
                    //srs={params.srs}
                    //logscale={params.logscale}
                    //origin={params.origin}
                    //time={params.time}
                    
                    />
                </Overlay>
            ))}
            {camadasGeo.map((camada) => (
                <Legend camada={camada} />
            ))}
        </>
    );
};

export default CamadasWMS;