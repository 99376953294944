import React from 'react';
import { layoutModeTypes } from "../../Components/constants/layout";
import { Input, Button, Card, CardBody, CardHeader, CardFooter } from "reactstrap";


const LightDark = ({ layoutMode, onChangeLayoutMode }) => {

    const mode = layoutMode === layoutModeTypes['DARKMODE'] ? layoutModeTypes['LIGHTMODE'] : layoutModeTypes['DARKMODE'];

    return (
        <div className="ms-1 header-item d-none d-sm-flex">

            <div onClick={() => onChangeLayoutMode(mode)}
                class="toggle-switch-light-dark btn btn-icon btn-topbar btn-ghost-secondary light-dark-mode border-dark">
                <div class="notch-switch-light-dark light-dark-mode btn-animation btn-dark align-self-center" />
                <i className='bx bx-moon fs-22 notch-icon' />
            </div>

        </div >
    );
};

export default LightDark;