import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// PV_CADASTROS Redux States
import {
    GET_PV_CADASTROS,
    ADD_NEW_PV_CADASTROS,
    DELETE_PV_CADASTROS,
    UPDATE_PV_CADASTROS,
} from "./actionType";
import {
    PvCadastrosApiResponseSuccess, PvCadastrosApiResponseError,
    addPvCadastrosSuccess,
    addPvCadastrosFail,
    updatePvCadastrosSuccess,
    updatePvCadastrosFail,
    deletePvCadastrosSuccess,
    deletePvCadastrosFail,
} from "./action";

//Include Both Helper File with needed methods
import {
    getPvCadastros as getPvCadastrosApi,
    addNewPvCadastros,
    updatePvCadastros,
    deletePvCadastros,
} from "../../helpers/backend_helper";

function* getPvCadastros({ payload: pvcadastros }) {
    try {
        let allResults = [];
        let response = yield call(getPvCadastrosApi, pvcadastros);
        allResults = allResults.concat(response.results);
        while (response.next){
            // Obtém o número da próxima página
            const nextPage = response.next.split('=').pop();
            // Faz uma nova chamada para a próxima página
            response = yield call(getPvCadastrosApi, { ...pvcadastros, page: nextPage });
            // Adicionamos os resultados desta página à lista principal
            allResults = allResults.concat(response.results);
        }
        yield put(PvCadastrosApiResponseSuccess(GET_PV_CADASTROS, allResults));
        //console.log(allResults);
    } catch (error) {
        //console.log(error);
        yield put(PvCadastrosApiResponseError(GET_PV_CADASTROS, error));
    }
}

function* onAddNewPvCadastros({ payload: pvcadastros }) {
    try {
        const response = yield call(addNewPvCadastros, pvcadastros);
        yield put(addPvCadastrosSuccess(response));
        toast.success("PvCadastros Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(addPvCadastrosFail(error));
        toast.error("PvCadastros Added Failed", { autoClose: 3000 });
    }
}

function* onDeletePvCadastros({ payload: pvcadastros }) {
    try {
        const response = yield call(deletePvCadastros, pvcadastros);
        yield put(deletePvCadastrosSuccess({ pvcadastros, ...response }));
        toast.success("PvCadastros Delete Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(deletePvCadastrosFail(error));
        toast.error("PvCadastros Delete Failed", { autoClose: 3000 });
    }
}

function* onUpdatePvCadastros({ payload: pvcadastros }) {
    try {
        const response = yield call(updatePvCadastros, pvcadastros);
        yield put(updatePvCadastrosSuccess(response));
        toast.success("PvCadastros Updated Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(updatePvCadastrosFail(error));
        toast.error("PvCadastros Updated Failed", { autoClose: 3000 });
    }
}

export function* watchGetPvCadastros() {
    yield takeEvery(GET_PV_CADASTROS, getPvCadastros);
}

export function* watchAddNewPvCadastros() {
    yield takeEvery(ADD_NEW_PV_CADASTROS, onAddNewPvCadastros);
}

export function* watchUpdatePvCadastros() {
    yield takeEvery(UPDATE_PV_CADASTROS, onUpdatePvCadastros);
}

export function* watchDeletePvCadastros() {
    yield takeEvery(DELETE_PV_CADASTROS, onDeletePvCadastros);
}

function* pvcadastrosSaga() {
    yield all([
        fork(watchGetPvCadastros),
        fork(watchAddNewPvCadastros),
        fork(watchUpdatePvCadastros),
        fork(watchDeletePvCadastros)
    ]
    );
}

export default pvcadastrosSaga;
