import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useSelector, useDispatch } from "react-redux";
import { getCiCadastros } from "../../../../Store/cicadesgoto/action";
import { Card, CardHeader, CardFooter, CardBody, Col, Row, Input, Label, Table, Button } from "reactstrap";
import * as XLSX from 'xlsx';
import FileSaver from "file-saver";

const FilterCiCadastro = () => {
    const [formValues, setFormValues] = useState({
        id_ci: "", n_edifica: "", tipo_ci: "", local_ci: "", mat_tampa: "", diam_tampa: "",
        tipo_pav: "", prof_poco: "", mat_poco: "", form_poco: "", d_int_poco: "", d_poco_ret: "",
        qtd_tubo_s: "", mat_tubo_s: "", d_tubo_s1: "", mat_tubo_1: "", d_tubo_s2: "", data_dado: "",
        font_comp: "", font_geom: "", orig_arq: "", cidade: "", bairro: "", logradouro: "",
        pathimg: "", latitude: "", longitude: "",
    });
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value === "Selecionar" ? "" : value });
    };

    const sendForm = () => { dispatch(getCiCadastros(formValues)); };
    
    const dispatch = useDispatch();

    const { ciCadastros } = useSelector((state) => ({ 
        ciCadastros: state.CiCadastros.ciCadastros 
    }));
    useEffect(() => { 
        if (ciCadastros && !ciCadastros.length) { 
            dispatch(getCiCadastros()); 
        }}, [dispatch, ciCadastros]);
    //console.log(ciCadastros)

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 15;
    
    const handlePageClick = ({ selected }) => { setCurrentPage(selected) };
    let paginatedData = ciCadastros.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
    useEffect(() => { 
        paginatedData = ciCadastros.slice(
            currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage
            ) }, []);

    const [selectAll, setSelectAll] = useState(false);
    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckAll = () => {
        setSelectAll(!selectAll);
        const newCheckedItems = {};
        ciCadastros.forEach((item) => {
            newCheckedItems[item.id] = !selectAll;
        });
        setCheckedItems(newCheckedItems);
        //console.log(checkedItems);
    };

    const handleCheckboxChange = (id) => {
        const newCheckedItems = { ...checkedItems, [id]: !checkedItems[id] };
        setCheckedItems(newCheckedItems);
    };

    const handleExportExcel = () => {
        // Filtra os itens marcados
        const selectedItems = ciCadastros.filter((item) => checkedItems[item.id]);
        // Cria um array de dados para a planilha
        const dataForExcel = selectedItems.map((item) => ({
            ID: item.id,
            ID_CI: item.id_ci ,
            LOGRADOURO: item.logradouro ,
            N_EDIFÍCIO: item.n_edifica ,
            BAIRRO: item.bairro ,
            CIDADE: item.cidade ,
            TIPO_CAIXA_INSPEÇÃO: item.tipo_ci ,
            LOCAL_CAIXA_INSPEÇÃO: item.local_ci ,
            MATERIAL_TAMPA: item.mat_tampa ,
            DIAMETRO_TAMPA: item.diam_tampa ,
            TIPO_PAVIMENTAÇÂO: item.tipo_pav ,
            PROFUND_POÇO: item.prof_poco ,
            MATERIAL_POÇO: item.mat_poco ,
            FORMATO_POÇO: item.form_poco ,
            DIMENSÕES_INTERNAS_POÇO: item.d_int_poco ,
            DIMENSÕES_POÇO_RET: item.d_poco_ret ,
            QTD_TUBO_SAIDA: item.qtd_tubo_s ,
            MATERIAL_TUBO_SAIDA: item.mat_tubo_s ,
            DIMENSÃO_TUBO_SAIDA_1: item.d_tubo_s1 ,
            MATERIAL_TUBO_SAIDA_1: item.mat_tubo_1 ,
            DIMENSÃO_TUBO_SAIDA_2: item.d_tubo_s2 ,
            DATA_DADO: item.data_dado ,
            FONT_COMP: item.font_comp ,
            FONT_GEOM: item.font_geom ,
            ORIGEM_ARQUIVOS: item.orig_arq ,
            PATH_IMAGEM: item.pathimg ,
            LATITUDE: item.latitude ,
            LONGITUDE: item.longitude ,
        }));
        
        const ws = XLSX.utils.json_to_sheet(dataForExcel);
        //const wb = XLSX.utils.book_new();
        const wb = XLSX.utils.book_new({ Sheets: { data: ws }, SheetsNames: ["data"] });
        XLSX.utils.book_append_sheet(wb, ws, 'Lista CiCad');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: "xlsx" })
        FileSaver.saveAs(data, "Selecionados_Ci_Cadastro" + ".xlsx")
    };

    return (
        <React.Fragment>
            <div className="content">
                <Card>
                    <CardHeader>
                        <form className="app-search d-none d-md-block">
                            <Row>
                                <Col xxl={12}>
                                    <Row className="gy-0">
                                        <Col xxl={1}>
                                            <Label className="form-label mt-0 fs-12 mb-0" for="id_ci">ID CI:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="id_ci"
                                                id="id_ci" value={formValues.id_ci} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={4}>
                                            <Label className="form-label mt-0 fs-12 mb-0" for="logradouro">LOGRADOURO:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="logradouro"
                                                id="logradouro" value={formValues.logradouro} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-0 fs-12 mb-0" for="n_edifica">No. EDIFÍCIO:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="n_edifica"
                                                id="n_edifica" value={formValues.n_edifica} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-0 fs-12 mb-0" for="bairro">BAIRRO:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="bairro"
                                                id="bairro" value={formValues.bairro} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-0 fs-12 mb-0" for="cidade">CIDADE:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="cidade"
                                                id="cidade" value={formValues.cidade} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="data_dado">DATA DOS DADOS:</Label>
                                            <Input type="date" className="form-control form-control-sm" name="data_dado"
                                                id="data_dado" value={formValues.data_dado} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="tipo_ci" >TIPO CI:</Label>
                                            <Input className="form-control-sm" type="select" name="tipo_ci" id="inputTipo_ci"
                                                value={formValues.tipo_ci} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Caixa de Inspeção" value="Caixa de Inspeção">Caixa de Inspeção</option>
                                                <option key="TIL Predial" value="TIL Predial">TIL Predial</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="local_ci">LOCAL DO CI:</Label>
                                            <Input className="form-control-sm" type="select" name="local_ci" id="inputLocalCi"
                                                value={formValues.local_ci} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Passeio" value="Passeio">Passeio</option>
                                                <option key="Via" value="Via">Via</option>
                                                <option key="Terreno / Propriedade Privada" value="Terreno / Propriedade Privada">Terreno / Propriedade Privada</option>
                                                <option key="Servidão Sanitária" value="Servidão Sanitária">Servidão Sanitária</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="mat_tampa">MATERIAL TAMPA:</Label>
                                            <Input className="form-control-sm" type="select" name="mat_tampa" id="inputMat_tampa"
                                                value={formValues.mat_tampa} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Ferro Fundido" value="Ferro Fundido">Ferro Fundido</option>
                                                <option key="Laje" value="Laje">Laje</option>
                                                <option key="Ferro Fundido + Laje" value="Ferro Fundido + Laje">
                                                    Ferro Fundido + Laje</option>
                                                <option key="Plástico" value="Plástico">Plástico</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="diam_tampa">DIAM. DA TAMPA:</Label>
                                            <Input className="form-control-sm" type="select" name="diam_tampa" id="inputDiam_tampa"
                                                value={formValues.diam_tampa} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="400mm" value="400mm">400mm</option>
                                                <option key="600mm" value="600mm">600mm</option>
                                                <option key="800mm" value="800mm">800mm</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="tipo_pav">TIPO PAVIMENTAÇÂO:</Label>
                                            <Input className="form-control-sm" type="select" name="tipo_pav" id="inputTipo_pav"
                                                value={formValues.tipo_pav} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Concreto" value="Concreto">Concreto</option>
                                                <option key="Piso Cerâmico" value="Piso Cerâmico">Piso Cerâmico</option>
                                                <option key="Piso Padrão Municipal" value="Piso Padrão Municipal">Piso Padrão Municipal</option>
                                                <option key="Lajota" value="Lajota">Lajota</option>
                                                <option key="Paralelepípedo" value="Paralelepípedo">Paralelepípedo</option>
                                                <option key="Terreno Natural" value="Terreno Natural">Terreno Natural</option>
                                                <option key="Asfalto" value="Asfalto">Asfalto</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="prof_poco">PROFUND. DO POÇO:</Label>
                                            <Input type="number" className="form-control form-control-sm" name="prof_poco"
                                                id="prof_poco" value={formValues.prof_poco} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="mat_poco">MATERIAL DO POÇO:</Label>
                                            <Input className="form-control-sm" type="select" name="mat_poco" id="inputMat_poco"
                                                value={formValues.mat_poco} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Tubo concreto pré-moldado" value="Tubo concreto pré-moldado">Tubo concreto pré-moldado</option>
                                                <option key="Bloco + Alvenaria" value="Bloco + Alvenaria">Bloco + Alvenaria</option>
                                                <option key="PEAD" value="PEAD">PEAD</option>
                                                <option key="Polietileno" value="Polietileno">Polietileno</option>
                                                <option key="PVC" value="PVC">PVC</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="form_poco">FORMATO DO POÇO:</Label>
                                            <Input className="form-control-sm" type="select" name="form_poco" id="inputForm_poco"
                                                value={formValues.form_poco} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Circular" value="Circular">Circular</option>
                                                <option key="Retangular" value="Retangular">Retangular</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="d_int_poco">DIAM. INT. DO POÇO:</Label>
                                            <Input className="form-control-sm" type="select" name="d_int_poco" id="inputD_int_poco"
                                                value={formValues.d_int_poco} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="400mm" value="400mm">400mm</option>
                                                <option key="600mm" value="600mm">600mm</option>
                                                <option key="100mm" value="100mm">100mm</option>
                                                <option key="150mm" value="150mm">150mm</option>
                                                <option key="800mm" value="800mm">800mm</option>
                                                <option key="1000mm" value="1000mm">1000mm</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="d_poco_ret">DIAMETRO DO POÇO R:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="d_poco_ret"
                                                id="d_poco_ret" value={formValues.d_poco_ret} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="qtd_tubo_s">QUANT TUBO S:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="qtd_tubo_s"
                                                id="qtd_tubo_s" value={formValues.qtd_tubo_s} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="mat_tubo_s">METERIAL DO TUBO S:</Label>
                                            <Input className="form-control-sm" type="select" name="mat_tubo_s" id="inputMat_tubo_s"
                                                value={formValues.mat_tubo_s} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="PVC" value="PVC">PVC</option>
                                                <option key="Manilha Cerâmica" value="Manilha Cerâmica">Manilha Cerâmica</option>
                                                <option key="Amianto" value="Amianto">Amianto</option>
                                                <option key="Manilha Concreto" value="Manilha Concreto">Manilha Concreto</option>
                                                <option key="PEAD" value="PEAD">PEAD</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="d_tubo_s1">DIAMETRO DO TUBO S:</Label>
                                            <Input className="form-control-sm" type="select" name="d_tubo_s1" id="inputD_tubo_s1"
                                                value={formValues.d_tubo_s1} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="400mm" value="400mm">100mm</option>
                                                <option key="600mm" value="600mm">150mm</option>
                                                <option key="100mm" value="100mm">200mm</option>
                                                <option key="150mm" value="150mm">250mm</option>
                                                <option key="800mm" value="800mm">300mm</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="mat_tubo_1">METERIAL DO TUBO S:</Label>
                                            <Input className="form-control-sm" type="select" name="mat_tubo_1" id="inputMat_tubo_1"
                                                value={formValues.mat_tubo_1} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="PVC" value="PVC">PVC</option>
                                                <option key="Manilha Cerâmica" value="Manilha Cerâmica">Manilha Cerâmica</option>
                                                <option key="Amianto" value="Amianto">Amianto</option>
                                                <option key="Manilha Concreto" value="Manilha Concreto">Manilha Concreto</option>
                                                <option key="PEAD" value="PEAD">PEAD</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="d_tubo_s2">DIAMETRO DO TUBO S:</Label>
                                            <Input className="form-control-sm" type="select" name="d_tubo_s2" id="inputD_tubo_s2"
                                                value={formValues.d_tubo_s2} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="400mm" value="400mm">100mm</option>
                                                <option key="600mm" value="600mm">150mm</option>
                                                <option key="100mm" value="100mm">200mm</option>
                                                <option key="150mm" value="150mm">250mm</option>
                                                <option key="800mm" value="800mm">300mm</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="font_comp">FONTE COMP:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="font_comp"
                                                id="font_comp" value={formValues.font_comp} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="font_geom">FONTE DA GEOMET:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="font_geom"
                                                id="font_geom" value={formValues.font_geom} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="orig_arq">ORIGEM DOS ARQUIVOS:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="orig_arq"
                                                id="orig_arq" value={formValues.orig_arq} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="pathimg">PATH IMAGEM:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="pathimg"
                                                id="pathimg" value={formValues.pathimg} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={1}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="latitude">LATITUDE:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="latitude"
                                                id="latitude" value={formValues.latitude} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={1}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="longitude">LONGITUDE:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="longitude"
                                                id="longitude" value={formValues.longitude} onChange={handleInputChange} />
                                        </Col>
                                        <Col xxl={2}>
                                            <div className="text-end ms-auto end-0 hstack justify-content-end">
                                                <Button className="mt-4 shadow-lg p-2 px-5 ms-auto" color='secondary'
                                                    onClick={sendForm}
                                                > Buscar <i className="ri-arrow-right-line ms-1"></i> </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </form>
                    </CardHeader>

                    <div className="ms-auto">
                        <Button className="mt-4 shadow-lg p-2 px-5 ms-auto" color='success' onClick={handleExportExcel}>Exportar para Excel</Button>
                    </div>

                    <CardBody>
                        <div className="tab-pane active" id="border-navs-home" role="tabpanel">
                            <Row>
                                <Col xl={12}>
                                    <div className="table-responsive">
                                        <Table className="table-hover align-middle table-nowrap mb-0 mt-5">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ "width": "25px" }}>
                                                        <div className="form-check">
                                                            <Input className="form-check-input" type="checkbox" id="checkAllCiCad" onChange={handleCheckAll} />
                                                        </div>
                                                    </th>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">ID CI</th>
                                                    <th scope="col">LOGRADOURO</th>
                                                    <th scope="col">N. EDIFÍCIO</th>
                                                    <th scope="col">BAIRRO</th>
                                                    <th scope="col">CIDADE</th>
                                                    <th scope="col">TIPO CAIXA INSPEÇÃO</th>
                                                    <th scope="col">LOCAL CAIXA INSPEÇÃO</th>
                                                    <th scope="col">MATERIAL TAMPA</th>
                                                    <th scope="col">DIAMETRO TAMPA</th>
                                                    <th scope="col">TIPO PAVIMENTAÇÂO</th>
                                                    <th scope="col">PROFUND POÇO</th>
                                                    <th scope="col">MATERIAL POÇO</th>
                                                    <th scope="col">FORMATO POÇO</th>
                                                    <th scope="col">DIMENSÕES INTERNAS POÇO</th>
                                                    <th scope="col">DIMENSÕES POÇO RET</th>
                                                    <th scope="col">QTD. TUBO SAIDA</th>
                                                    <th scope="col">MATERIAL TUBO SAIDA</th>
                                                    <th scope="col">DIMENSÃO TUBO SAIDA 1</th>
                                                    <th scope="col">MATERIAL TUBO SAIDA 1</th>
                                                    <th scope="col">DIMENSÃO TUBO SAIDA 2</th>
                                                    <th scope="col">DATA DADO</th>
                                                    <th scope="col">FONT COMP</th>
                                                    <th scope="col">FONT GEOM</th>
                                                    <th scope="col">ORIGEM ARQUIVOS</th>
                                                    <th scope="col">PATH IMAGEM</th>
                                                    <th scope="col">LATITUDE</th>
                                                    <th scope="col">LONGITUDE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paginatedData.map((item) => (
                                                    <tr>
                                                        <th scope="row">
                                                            <div className="form-check">
                                                                <Input className="form-check-input" type="checkbox" id={`inlineCheckbox${item.id}`}
                                                                    checked={checkedItems[item.id] || false} onChange={() => handleCheckboxChange(item.id)}
                                                                />
                                                            </div>
                                                        </th>
                                                        <td>#{item.id}</td>
                                                        <th scope="col">{item.id_ci}</th>
                                                        <th scope="col">{item.logradouro}</th>
                                                        <th scope="col">{item.n_edifica}</th>
                                                        <th scope="col">{item.bairro}</th>
                                                        <th scope="col">{item.cidade}</th>
                                                        <th scope="col">{item.tipo_ci}</th>
                                                        <th scope="col">{item.local_ci}</th>
                                                        <th scope="col">{item.mat_tampa}</th>
                                                        <th scope="col">{item.diam_tampa}</th>
                                                        <th scope="col">{item.tipo_pav}</th>
                                                        <th scope="col">{item.prof_poco}</th>
                                                        <th scope="col">{item.mat_poco}</th>
                                                        <th scope="col">{item.form_poco}</th>
                                                        <th scope="col">{item.d_int_poco}</th>
                                                        <th scope="col">{item.d_poco_ret}</th>
                                                        <th scope="col">{item.qtd_tubo_s}</th>
                                                        <th scope="col">{item.mat_tubo_s}</th>
                                                        <th scope="col">{item.d_tubo_s1}</th>
                                                        <th scope="col">{item.mat_tubo_1}</th>
                                                        <th scope="col">{item.d_tubo_s2}</th>
                                                        <th scope="col">{item.data_dado}</th>
                                                        <th scope="col">{item.font_comp}</th>
                                                        <th scope="col">{item.font_geom}</th>
                                                        <th scope="col">{item.orig_arq}</th>
                                                        <th scope="col">{item.pathimg}</th>
                                                        <th scope="col">{item.latitude}</th>
                                                        <th scope="col">{item.longitude}</th>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                            <ReactPaginate previousLabel={'Anterior'} nextLabel={'Próximo'} breakLabel={'...'} activeClassName={'active'}
                                pageCount={Math.ceil(ciCadastros.length / itemsPerPage)} subContainerClassName={'pages pagination'}
                                marginPagesDisplayed={1} pageRangeDisplayed={3} onPageChange={handlePageClick} containerClassName={'pagination'} />
                        </div>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    );
};
export default FilterCiCadastro;