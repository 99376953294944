import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

import { useSelector, useDispatch } from "react-redux";
import { getPvCadastros } from "../../../../Store/pvcadesgoto/action";
import { Card, CardHeader, CardFooter, CardBody, Col, Row, Input, Label, Table, Button } from "reactstrap";
import * as XLSX from 'xlsx';
import FileSaver from "file-saver";

const FilterPvCadastro = () => {

    const [formValues, setFormValues] = useState({
        id_pv: "", n_edifica: "", tipo_pv: "", local_pv: "", mat_tampa: "", diam_tampa: "", tipo_pav: "",
        perfil_pv: "", cont_vaz_c: "", prof_poco: "", mat_poco: "", form_poco: "", d_poco_cir: "", d_poco_r: "",
        mat_tubo_s: "", tipo_tub: "", d_tub_s: "", d_gal_ret: "", data_dado: "", font_comp: "", font_geom: "",
        orig_arq: "", cidade: "", bairro: "", logradouro: "", pathimg: "", latitude: "", longitude: "",
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value === "Selecionar" ? "" : value });
    };

    const sendForm = () => {
        dispatch(getPvCadastros(formValues));
        console.log(pvCadastros)
    };

    const dispatch = useDispatch();
    const { pvCadastros } = useSelector((state) => ({ pvCadastros: state.PvCadastros.pvCadastros }));
    useEffect(() => {
        if (pvCadastros && !pvCadastros.length) {
            dispatch(getPvCadastros());
        }
    }, [dispatch, pvCadastros]);

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 15;
    const handlePageClick = ({ selected }) => { setCurrentPage(selected) };

    let paginatedData = pvCadastros.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );

    useEffect(() => {
        paginatedData = pvCadastros.slice(
            currentPage * itemsPerPage,
            (currentPage + 1) * itemsPerPage
        );
    }, []);

    const [selectAll, setSelectAll] = useState(false);
    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckAll = () => {
        setSelectAll(!selectAll);
        const newCheckedItems = {};
        pvCadastros.forEach((item) => {
            newCheckedItems[item.id] = !selectAll;
        });
        setCheckedItems(newCheckedItems);
        //console.log(checkedItems);
    };

    const handleCheckboxChange = (id) => {
        const newCheckedItems = { ...checkedItems, [id]: !checkedItems[id] };
        setCheckedItems(newCheckedItems);
    };

    const handleExportExcel = () => {
        const selectedItems = pvCadastros.filter((item) => checkedItems[item.id]);
        // Cria um array de dados para a planilha
        const dataForExcel = selectedItems.map((item) => ({
            ID: item.id,
            LOGRADOURO: item.logradouro,
            N_EDIFÍCIO: item.n_edifica,
            BAIRRO: item.bairro,
            CIDADE: item.cidade,
            ID_POÇO_VISITA: item.id_pv,
            TIPO_POÇO_VISITA: item.tipo_pv,
            LOCAL_POÇO_VISITA: item.local_pv,
            MATERIAL_TAMPA: item.mat_tampa,
            DIAMETRO_TAMPA: item.diam_tampa,
            TIPO_PAVIMENTAÇÃO: item.tipo_pav,
            PERFIL_POÇO_VISITA: item.perfil_pv,
            CONT_VAZ_C: item.cont_vaz_c,
            PROFUNDIDADE_POÇO: item.prof_poco,
            MATERIAL_POÇO: item.mat_poco,
            FORMATO_POÇO: item.form_poco,
            DIMENSÕES_POÇO_CIR: item.d_poco_cir,
            DIMENSÕES_POÇO_R: item.d_poco_r,
            MATERIAL_TUBO_S: item.mat_tubo_s,
            TIPO_TUBO: item.tipo_tub,
            DIMENSÕES_TUBO_S: item.d_tub_s,
            DIMENSÕES_GAL_RET: item.d_gal_ret,
            DATA_DADO: item.data_dado,
            FONTE_COMP: item.font_comp,
            FONTE_GEOM: item.font_geom,
            ORIGEM_ARQUIVOS: item.orig_arq,
            PATH_IMAGEM: item.pathimg,
            LATITUDE: item.latitude,
            LONGITUDE: item.longitude,
        }));
        const ws = XLSX.utils.json_to_sheet(dataForExcel);
        //const wb = XLSX.utils.book_new();
        const wb = XLSX.utils.book_new({ Sheets: { data: ws }, SheetsNames: ["data"] });
        XLSX.utils.book_append_sheet(wb, ws, 'Lista PvCad');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: "xlsx" })
        FileSaver.saveAs(data, "Selecionados_Pv_Cad" + ".xlsx")
    };

    return (
        <React.Fragment>
            <div className="content">
                <Card>
                    <CardHeader>
                        <form className="app-search d-none d-md-block">
                            <Row>
                                <Col xxl={12}>
                                    <Row className="gy-0">
                                        {/*ID PV*/}
                                        <Col xxl={1}>
                                            <Label className="form-label mt-0 fs-12 mb-0" for="id_pv">ID PV:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="id_pv"
                                                id="id_pv" value={formValues.id_pv} onChange={handleInputChange} />
                                        </Col>
                                        {/*LOGRADOURO*/}
                                        <Col xxl={4}>
                                            <Label className="form-label mt-0 fs-12 mb-0" for="logradouro">LOGRADOURO:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="logradouro"
                                                id="logradouro" value={formValues.logradouro} onChange={handleInputChange} />
                                        </Col>
                                        {/*No. EDIFÍCIO*/}
                                        <Col xxl={2}>
                                            <Label className="form-label mt-0 fs-12 mb-0" for="n_edificac">No. EDIFÍCIO:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="n_edificac"
                                                id="n_edificac" value={formValues.n_edifica} onChange={handleInputChange} />
                                        </Col>
                                        {/*BAIRRO*/}
                                        <Col xxl={3}>
                                            <Label className="form-label mt-0 fs-12 mb-0" for="bairro">BAIRRO:</Label>
                                            <Input type="text" className="form-control form-control-sm" name="bairro"
                                                id="bairro" value={formValues.bairro} onChange={handleInputChange} />
                                        </Col>
                                        {/*CIDADE*/}
                                        <Col xxl={2}>
                                            <div>
                                                <Label className="form-label mt-0 fs-12 mb-0" for="cidade">CIDADE:</Label>
                                                <Input type="text" className="form-control form-control-sm" name="cidade"
                                                    id="cidade" value={formValues.cidade} onChange={handleInputChange} />
                                            </div>
                                        </Col>
                                        {/*DATA DOS DADOS*/}
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="data_dado">DATA DOS DADOS:</Label>
                                            <Input type="date" className="form-control form-control-sm" name="data_dado"
                                                id="data_dado" value={formValues.data_dado} onChange={handleInputChange} />
                                        </Col>
                                        {/*TIPO PV*/}
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="tipo_pv" >TIPO PV:</Label>
                                            <Input className="form-control-sm" type="select" name="tipo_pv" id="inputTipo_pv"
                                                value={formValues.tipo_pv} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Poço" value="Poço">Poço</option>
                                                <option key="TIL Radial" value="TIL Radial">TIL Radial</option>
                                                <option key="TIL de Passagem" value="TIL de Passagem">TIL de Passagem</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        {/*LOCAL DO PV*/}
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="local_pv">LOCAL DO PV:</Label>
                                            <Input className="form-control-sm" type="select" name="local_pv" id="inputLocalPv"
                                                value={formValues.local_pv} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Passeio" value="Passeio">Passeio</option>
                                                <option key="Via" value="Via">Via</option>
                                                <option key="Terreno / Propriedade Privada" value="Terreno / Propriedade Privada">Terreno / Propriedade Privada</option>
                                                <option key="Servidão Sanitária" value="Servidão Sanitária">Servidão Sanitária</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        {/*MATERIAL TAMPA*/}
                                        <Col xxl={3}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="mat_tampa">MATERIAL TAMPA:</Label>
                                            <Input className="form-control-sm" type="select" name="mat_tampa" id="inputMat_tampa"
                                                value={formValues.mat_tampa} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="Ferro Fundido" value="Ferro Fundido">Ferro Fundido</option>
                                                <option key="Laje" value="Laje">Laje</option>
                                                <option key="Ferro Fundido + Laje" value="Ferro Fundido + Laje">
                                                    Ferro Fundido + Laje</option>
                                                <option key="Plástico" value="Plástico">Plástico</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        {/*DIAM. DA TAMPA*/}
                                        <Col xxl={2}>
                                            <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="diam_tampa">DIAM. DA TAMPA:</Label>
                                            <Input className="form-control-sm" type="select" name="diam_tampa" id="inputDiam_tampa"
                                                value={formValues.diam_tampa} onChange={handleInputChange}>
                                                <option selected>Selecionar</option>
                                                <option key="400mm" value="400mm">400mm</option>
                                                <option key="600mm" value="600mm">600mm</option>
                                                <option key="800mm" value="800mm">800mm</option>
                                                <option key="1000mm" value="1000mm">1000mm</option>
                                                <option key="Outros" value="Outros">Outros</option>
                                            </Input>
                                        </Col>
                                        {/*PAVIMENTAÇÂO DA CALÇADA*/}
                                        <Col xxl={3}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="tipo_pav">TIPO PAVIMENTAÇÂO:</Label>
                                                <Input className="form-control-sm" type="select" name="tipo_pav" id="inputTipo_pav"
                                                    value={formValues.tipo_pav} onChange={handleInputChange}>
                                                    <option selected>Selecionar</option>
                                                    <option key="Concreto" value="Concreto">Asfalto</option>
                                                    <option key="Lajota Sextavada" value="Lajota Sextavada">Lajota Sextavada</option>
                                                    <option key="Paralelepípedo / Pedra" value="Paralelepípedo / Pedra">Paralelepípedo / Pedra</option>
                                                    <option key="Terreno Natural" value="Terreno Natural">Terreno Natural</option>
                                                    <option key="Outros" value="Outros">Outros</option>
                                                </Input>
                                            </div>
                                        </Col>
                                        {/*PERFIL DO POÇO DE VISITA*/}
                                        <Col xxl={2}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="perfil_pv">PERFIL DO POÇO:</Label>
                                                <Input className="form-control-sm" type="select" name="perfil_pv" id="inputPerfil_pv"
                                                    value={formValues.perfil_pv} onChange={handleInputChange}>
                                                    <option selected>Selecionar</option>
                                                    <option key="Padrão" value="Padrão">Padrão</option>
                                                    <option key="Ponta Seca" value="Ponta Seca">Ponta Seca</option>
                                                    <option key="Gradeamento Estação Elevatória" value="Gradeamento Estação Elevatória">Gradeamento Estação Elevatória</option>
                                                    <option key="Outros" value="Outros">Outros</option>
                                                </Input>
                                            </div>
                                        </Col>
                                        {/*CONT VAZ C*/}
                                        <Col xxl={2}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="cont_vaz_c">CONT VAZ C:</Label>
                                                <Input className="form-control-sm" type="select" name="cont_vaz_c" id="inputCont_vaz_c"
                                                    value={formValues.cont_vaz_c} onChange={handleInputChange}>
                                                    <option selected>Selecionar</option>
                                                    <option key="Não Possui" value="Não Possui">Não Possui</option>
                                                    <option key="Outra sub-bacia" value="Outra sub-bacia">Outra sub-bacia</option>
                                                    <option key="Edificação" value="Edificação">Edificação</option>
                                                    <option key="Macro-contribuinte" value="Macro-contribuinte">Macro-contribuinte</option>
                                                    <option key="Outros" value="Outros">Outros</option>
                                                </Input>
                                            </div>
                                        </Col>
                                        {/*PROFUND. DO POÇO*/}
                                        <Col xxl={2}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="prof_poco">PROFUND. DO POÇO:</Label>
                                                <Input type="number" className="form-control form-control-sm" name="prof_poco"
                                                    id="prof_poco" value={formValues.prof_poco} onChange={handleInputChange} />
                                            </div>
                                        </Col>
                                        {/*MATERIAL DO POÇO*/}
                                        <Col xxl={2}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="mat_poco">MATERIAL DO POÇO:</Label>
                                                <Input className="form-control-sm" type="select" name="mat_poco" id="inputMat_poco"
                                                    value={formValues.mat_poco} onChange={handleInputChange}>
                                                    <option selected>Selecionar</option>
                                                    <option key="Tubo concreto pré-moldado" value="Tubo concreto pré-moldado">Tubo concreto pré-moldado</option>
                                                    <option key="Bloco + Alvenaria" value="Bloco + Alvenaria">Bloco + Alvenaria</option>
                                                    <option key="PEAD" value="PEAD">PEAD</option>
                                                    <option key="Polietileno" value="Polietileno">Polietileno</option>
                                                    <option key="PVC" value="PVC">PVC</option>
                                                    <option key="Outros" value="Outros">Outros</option>
                                                </Input>
                                            </div>
                                        </Col>
                                        {/*FORMATO DO POÇO*/}
                                        <Col xxl={2}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="form_poco">FORMATO DO POÇO:</Label>
                                                <Input className="form-control-sm" type="select" name="form_poco" id="inputForm_poco"
                                                    value={formValues.form_poco} onChange={handleInputChange}>
                                                    <option selected>Selecionar</option>
                                                    <option key="Circular" value="Circular">Circular</option>
                                                    <option key="Retangular" value="Retangular">Retangular</option>
                                                    <option key="Câmara (TIL)" value="Câmara (TIL)">Câmara (TIL)</option>
                                                </Input>
                                            </div>
                                        </Col>
                                        {/*DIAM. INT. DO POÇO*/}
                                        <Col xxl={2}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="d_poco_cir">DIAM. DO POÇO CIR:</Label>
                                                <Input className="form-control-sm" type="select" name="d_poco_cir" id="inputD_int_poco"
                                                    value={formValues.d_poco_cir} onChange={handleInputChange}>
                                                    <option selected>Selecionar</option>
                                                    <option key="400mm" value="400mm">400mm</option>
                                                    <option key="600mm" value="600mm">600mm</option>
                                                    <option key="800mm" value="800mm">800mm</option>
                                                    <option key="1000mm" value="1000mm">1000mm</option>
                                                    <option key="1200mm" value="1200mm">1200mm</option>
                                                    <option key="1500mm" value="1500mm">1500mm</option>
                                                    <option key="2000mm" value="2000mm">2000mm</option>
                                                    <option key="Outros" value="Outros">Outros</option>
                                                </Input>
                                            </div>
                                        </Col>
                                        {/*DIAMETRO POÇO RET*/}
                                        <Col xxl={3}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="d_poco_r">DIAMETRO DO POÇO R:</Label>
                                                <Input type="text" className="form-control form-control-sm" name="d_poco_r"
                                                    id="d_poco_r" value={formValues.d_poco_r} onChange={handleInputChange} />
                                            </div>
                                        </Col>
                                        {/*METERIAL DO TUBO S*/}
                                        <Col xxl={3}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="mat_tubo_s">METERIAL DO TUBO S:</Label>
                                                <Input className="form-control-sm" type="select" name="mat_tubo_s" id="inputMat_tubo_s"
                                                    value={formValues.mat_tubo_s} onChange={handleInputChange}>
                                                    <option selected>Selecionar</option>
                                                    <option key="PVC" value="PVC">PVC</option>
                                                    <option key="Manilha Cerâmica" value="Manilha Cerâmica">Manilha Cerâmica</option>
                                                    <option key="Amianto" value="Amianto">Amianto</option>
                                                    <option key="Manilha Concreto" value="Manilha Concreto">Manilha Concreto</option>
                                                    <option key="PEAD" value="PEAD">PEAD</option>
                                                    <option key="Outros" value="Outros">Outros</option>
                                                </Input>
                                            </div>
                                        </Col>
                                        {/*TIPO TUBO*/}
                                        <Col xxl={3}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="tipo_tub">TIPO TUBO:</Label>
                                                <Input className="form-control-sm" type="select" name="tipo_tub" id="inputTipo_tub"
                                                    value={formValues.tipo_tub} onChange={handleInputChange}>
                                                    <option selected>Selecionar</option>
                                                    <option key="Circular" value="Circular">Circular</option>
                                                    <option key="Retangular" value="Retangular">Retangular</option>
                                                </Input>
                                            </div>
                                        </Col>
                                        {/*DIAMETRO DO TUBO S1*/}
                                        <Col xxl={3}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="d_tub_s">DIAMETRO DO TUBO S:</Label>
                                                <Input className="form-control-sm" type="select" name="d_tub_s" id="inputD_tubo_s1"
                                                    value={formValues.d_tub_s} onChange={handleInputChange}>
                                                    <option selected>Selecionar</option>
                                                    <option key="100mm" value="100mm">100mm</option>
                                                    <option key="150mm" value="150mm">150mm</option>
                                                    <option key="200mm" value="200mm">200mm</option>
                                                    <option key="250mm" value="250mm">250mm</option>
                                                    <option key="300mm" value="300mm">300mm</option>
                                                    <option key="350mm" value="350mm">350mm</option>
                                                    <option key="400mm" value="400mm">400mm</option>
                                                    <option key="500mm" value="500mm">500mm</option>
                                                    <option key="600mm" value="600mm">600mm</option>
                                                    <option key="Outros" value="Outros">Outros</option>
                                                </Input>
                                            </div>
                                        </Col>
                                        {/*D GAL RET*/}
                                        <Col xxl={3}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="d_gal_ret">D GAL RET:</Label>
                                                <Input type="text" className="form-control form-control-sm" name="d_gal_ret"
                                                    id="tipd_gal_reto_tub" value={formValues.d_gal_ret} onChange={handleInputChange} />
                                            </div>
                                        </Col>
                                        {/*FONTE COMP*/}
                                        <Col xxl={2}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="font_comp">FONTE COMP:</Label>
                                                <Input type="text" className="form-control form-control-sm" name="font_comp"
                                                    id="font_comp" value={formValues.font_comp} onChange={handleInputChange} />
                                            </div>
                                        </Col>
                                        {/*FONTE DA GEOMET*/}
                                        <Col xxl={2}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="font_geom">FONTE DA GEOMET:</Label>
                                                <Input type="text" className="form-control form-control-sm" name="font_geom"
                                                    id="font_geom" value={formValues.font_geom} onChange={handleInputChange} />
                                            </div>
                                        </Col>
                                        {/*ORIGEM DOS ARQUIVOS*/}
                                        <Col xxl={2}>
                                            <div>
                                                <Label className="form-label mt-3 fs-12 mb-0 text-nowrap" for="orig_arq">ORIGEM DOS ARQUIVOS:</Label>
                                                <Input type="text" className="form-control form-control-sm" name="orig_arq"
                                                    id="orig_arq" value={formValues.orig_arq} onChange={handleInputChange} />
                                            </div>
                                        </Col>
                                        <Col xxl={2}>
                                            <div className="text-end">
                                                <Button className="mt-4 shadow-lg p-2 px-5 ms-auto" color='secondary'
                                                    onClick={sendForm}
                                                > Buscar <i className="ri-arrow-right-line ms-1"></i> </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </form>
                    </CardHeader>

                    <div className="ms-auto">
                        <Button className="mt-4 shadow-lg p-2 px-5 ms-auto" color='success' onClick={handleExportExcel}>Exportar para Excel</Button>
                    </div>

                    <CardBody>
                        <div className="tab-pane active" id="border-navs-home" role="tabpanel">
                            <Row>
                                <Col xl={12}>
                                    <div className="table-responsive">
                                        <Table className="table-hover align-middle table-nowrap mb-0 mt-5">
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ "width": "25px" }}>
                                                        <div className="form-check">
                                                            <Input className="form-check-input" type="checkbox" id="checkAll" onChange={handleCheckAll} />
                                                        </div>
                                                    </th>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">LOGRADOURO</th>
                                                    <th scope="col">N. EDIFÍCIO</th>
                                                    <th scope="col">BAIRRO</th>
                                                    <th scope="col">CIDADE</th>
                                                    <th scope="col">ID POÇO VISITA</th>
                                                    <th scope="col">TIPO POÇO VISITA</th>
                                                    <th scope="col">LOCAL POÇO VISITA</th>
                                                    <th scope="col">MATERIAL TAMPA</th>
                                                    <th scope="col">DIAMETRO TAMPA</th>
                                                    <th scope="col">TIPO PAVIMENTAÇÃO</th>
                                                    <th scope="col">PERFIL POÇO VISITA</th>
                                                    <th scope="col">CONT VAZ C</th>
                                                    <th scope="col">PROFUNDIDADE POÇO</th>
                                                    <th scope="col">MATERIAL POÇO</th>
                                                    <th scope="col">FORMATO POÇO</th>
                                                    <th scope="col">DIMENSÕES POÇO CIR</th>
                                                    <th scope="col">DIMENSÕES POÇO R</th>
                                                    <th scope="col">MATERIAL TUBO S</th>
                                                    <th scope="col">TIPO TUBO</th>
                                                    <th scope="col">DIMENSÕES TUBO S</th>
                                                    <th scope="col">DIMENSÕES GAL RET</th>
                                                    <th scope="col">DATA DADO</th>
                                                    <th scope="col">FONTE COMP</th>
                                                    <th scope="col">FONTE GEOM</th>
                                                    <th scope="col">ORIGEM ARQUIVOS</th>
                                                    <th scope="col">PATH IMAGEM</th>
                                                    <th scope="col">LATITUDE</th>
                                                    <th scope="col">LONGITUDE</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paginatedData.map((item) => (
                                                    <tr>
                                                        <th scope="row">
                                                            <div className="form-check">
                                                                <Input className="form-check-input" type="checkbox" id={`inlineCheckbox${item.id}`}
                                                                    checked={checkedItems[item.id] || false} onChange={() => handleCheckboxChange(item.id)}
                                                                />
                                                            </div>
                                                        </th>
                                                        <td>#{item.id}</td>
                                                        <th scope="col">{item.logradouro}</th>
                                                        <th scope="col">{item.n_edifica}</th>
                                                        <th scope="col">{item.bairro}</th>
                                                        <th scope="col">{item.cidade}</th>
                                                        <th scope="col">{item.id_pv}</th>
                                                        <th scope="col">{item.tipo_pv}</th>
                                                        <th scope="col">{item.local_pv}</th>
                                                        <th scope="col">{item.mat_tampa}</th>
                                                        <th scope="col">{item.diam_tampa}</th>
                                                        <th scope="col">{item.tipo_pav}</th>
                                                        <th scope="col">{item.perfil_pv}</th>
                                                        <th scope="col">{item.cont_vaz_c}</th>
                                                        <th scope="col">{item.prof_poco}</th>
                                                        <th scope="col">{item.mat_poco}</th>
                                                        <th scope="col">{item.form_poco}</th>
                                                        <th scope="col">{item.d_poco_cir}</th>
                                                        <th scope="col">{item.d_poco_r}</th>
                                                        <th scope="col">{item.mat_tubo_s}</th>
                                                        <th scope="col">{item.tipo_tub}</th>
                                                        <th scope="col">{item.d_tub_s}</th>
                                                        <th scope="col">{item.d_gal_ret}</th>
                                                        <th scope="col">{item.data_dado}</th>
                                                        <th scope="col">{item.font_comp}</th>
                                                        <th scope="col">{item.font_geom}</th>
                                                        <th scope="col">{item.orig_arq}</th>
                                                        <th scope="col">{item.pathimg}</th>
                                                        <th scope="col">{item.latitude}</th>
                                                        <th scope="col">{item.longitude}</th>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                            <ReactPaginate
                                previousLabel={'Anterior'}
                                nextLabel={'Próximo'}
                                breakLabel={'...'}
                                pageCount={Math.ceil(pvCadastros.length / itemsPerPage)}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            />
                        </div>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    );
};

export default FilterPvCadastro;