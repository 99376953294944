import React from 'react';
import IframeWithLoading from "../IframeWithLoading"


const DashboardCiStatus = () => {
    document.title = "Dashboard Ci - Status | SANAPP";
    return (
        <React.Fragment>
            <IframeWithLoading
                src="https://metabase.apisanapp.com.br/public/dashboard/4117ae34-b4e3-4466-9db4-c39589c2c09c"
                title="Ci - Status"
                width="100%"
                height="1100"
            />
        </React.Fragment>
    );
};

export default DashboardCiStatus;