import axios from "axios";
import { api } from "../config";

// default
axios.defaults.baseURL = api.API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// Token
const token = JSON.parse(sessionStorage.getItem("token")) ? JSON.parse(sessionStorage.getItem("token")) : null;

if(token) {
  axios.defaults.headers.common["Authorization"] = "Token " + token;
}

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.response?.status || error.status) {
      case 400:
        if (error.response.data.error){
          message = error.response.data.error;
        } else if (error.response.data) {
          message = error.response.data;
        } else if (error.response) {
          message = error.response;
        } else if (error.message) {
          message = error.message || error;
        } else {
          message = error;
        }
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      case 500:
        message = "Internal Server Error";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Token " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */

  get = (url, params) => {
    let response;

    let paramKeys = [];

    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = (url, data) => {
    const response =  axios.post(url, data);
    return response;
  };
  /**
   * Updates data
   */
  update = (url, data, id_new) => {
    let dataNew = {...data};
    //console.log(dataNew)
    url = id_new ?  url + id_new + '/' : dataNew.id ? url + dataNew.id + '/' : url;
    //console.log(url)
    const response = axios.patch(url, dataNew);
    return response;
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}
const getLoggedinUser = () => {
  //const user = sessionStorage.getItem("token");
  const user = JSON.parse(sessionStorage.getItem("userdata"));
  if (!user) {
    return null;
  } else {
    return {...user};
  }
};

export { APIClient, setAuthorization, getLoggedinUser };