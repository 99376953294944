import {
    GET_CI_STATUS,
    GET_CI_STATUSFILTER,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    ADD_CI_STATUS_SUCCESS,
    ADD_CI_STATUS_FAIL,
    UPDATE_CI_STATUS_SUCCESS,
    UPDATE_CI_STATUS_FAIL,
    DELETE_CI_STATUS_SUCCESS,
    DELETE_CI_STATUS_FAIL,
} from "./actionType";

const INIT_STATE = {
    ciStatus: [],
    ciStatusFilter: [],
};

const CiStatus = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:

            switch (action.payload.actionType) {
                case GET_CI_STATUS:
                    return {
                        ...state,
                        ciStatus: action.payload.data,
                        isCiStatusCreated: false,
                        isCiStatusSuccess: true
                    };
                
                case GET_CI_STATUSFILTER:
                    return {
                        ...state,
                        ciStatusFilter: action.payload.data,
                        isCiStatusCreated: false,
                        isCiStatusSuccess: true
                    };

                default:
                    return { ...state };
            }

        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_CI_STATUS:
                    return {
                        ...state,
                        error: action.payload.error,
                        isCiStatusCreated: false,
                        isCiStatusSuccess: true
                    };

                case GET_CI_STATUSFILTER:
                    return {
                        ...state,
                        error: action.payload.error,
                        isCiStatusCreated: false,
                        isCiStatusSuccess: true
                    };

                default:
                    return { ...state };
            }

        case GET_CI_STATUS: {
            return {
                ...state,
                isCiStatusCreated: false
            };
        }

        case GET_CI_STATUSFILTER: {
            return {
                ...state,
                isCiStatusCreated: false
            };
        }

        case ADD_CI_STATUS_SUCCESS:
            return {
                ...state,
                isCiStatusCreated: true,
                ciStatus: [...state.ciStatus, action.payload.data],
                isCiStatusAdd: true,
                isCiStatusAddFail: false,
                successMessage: 'Status adicionado com sucesso', // Defina sua mensagem de sucesso
                errorMessage: null, // Limpa qualquer mensagem de erro anterior
            };

        case ADD_CI_STATUS_FAIL:
            return {
                ...state,
                error: action.payload,
                isCiStatusAdd: false,
                isCiStatusAddFail: true,
                successMessage: null, // Limpa qualquer mensagem de sucesso anterior
                errorMessage: 'Erro ao adicionar Status', // Define sua mensagem de erro
            };

        case UPDATE_CI_STATUS_SUCCESS:
            return {
                ...state,
                ciStatus: state.ciStatus.map(cistatus =>
                    cistatus.id.toString() === action.payload.id.toString()
                        ? { ...cistatus, ...action.payload }
                        : cistatus
                ),
                isCiStatusUpdate: true,
                isCiStatusUpdateFail: false,
                successMessage: 'Status atualizado com sucesso', // Defina sua mensagem de sucesso
                errorMessage: null, // Limpa qualquer mensagem de erro anterior
            };

        case UPDATE_CI_STATUS_FAIL:
            return {
                ...state,
                error: action.payload,
                isCiStatusUpdate: false,
                isCiStatusUpdateFail: true,
                successMessage: null, // Limpa qualquer mensagem de sucesso anterior
                errorMessage: 'Erro ao atualizar Status', // Define sua mensagem de erro
            };

        case DELETE_CI_STATUS_SUCCESS:
            return {
                ...state,
                ciStatus: state.ciStatus.filter(
                    cistatus => cistatus.id.toString() !== action.payload.cistatus.toString()
                ),
                isCiStatusDelete: true,
                isCiStatusDeleteFail: false
            };

        case DELETE_CI_STATUS_FAIL:
            return {
                ...state,
                error: action.payload,
                isCiStatusDelete: false,
                isCiStatusDeleteFail: true
            };

        default:
            return { ...state };
    }
};

export default CiStatus;