import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// CI_STATUS Redux States
import {
    GET_CI_STATUS,
    GET_CI_STATUSFILTER,
    ADD_NEW_CI_STATUS,
    DELETE_CI_STATUS,
    UPDATE_CI_STATUS,
} from "./actionType";
import {
    CiStatusApiResponseSuccess, CiStatusApiResponseError,
    CiStatusFilterResponseSuccess, CiStatusFilterResponseError,
    addCiStatusSuccess,
    addCiStatusFail,
    updateCiStatusSuccess,
    updateCiStatusFail,
    deleteCiStatusSuccess,
    deleteCiStatusFail,
} from "./action";

//Include Both Helper File with needed methods
import {
    getCiStatus as getCiStatusApi,
    getCiStatusfilter,
    addNewCiStatus,
    updateCiStatus,
    deleteCiStatus,
} from "../../helpers/backend_helper";

function* getCiStatus({ payload: cistatus }) {
    try {
        let allResults = [];
        let response = yield call(getCiStatusApi, cistatus ? cistatus : {});
        allResults = allResults.concat(response.results);
        while (response.next){
            console.log(response.next);
            // Obtém o número da próxima página
            const nextPage = response.next.split('=').pop();
            // Faz uma nova chamada para a próxima página
            response = yield call(getCiStatusApi, { ...cistatus, page: nextPage });
            // Adicionamos os resultados desta página à lista principal
            allResults = allResults.concat(response.results);
        }
        yield put(CiStatusApiResponseSuccess(GET_CI_STATUS, allResults));
    } catch (error) {
        //console.log(error);
        yield put(CiStatusApiResponseError(GET_CI_STATUS, error));
    }
}

function* getCiStatusFilter({ payload: cistatus }) {
    try {
        const response = yield call(getCiStatusfilter, cistatus);
        yield put(CiStatusFilterResponseSuccess(GET_CI_STATUSFILTER, response.results));
    } catch (error) {
        yield put(CiStatusFilterResponseError(GET_CI_STATUSFILTER, error));
    }
}

function* onAddNewCiStatus({ payload: cistatus }) {
    try {
        const response = yield call(addNewCiStatus, cistatus);
        yield put(addCiStatusSuccess(response));
        toast.success("CiStatus Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(addCiStatusFail(error));
        toast.error("CiStatus Added Failed", { autoClose: 3000 });
    }
}

function* onDeleteCiStatus({ payload: cistatus }) {
    try {
        const response = yield call(deleteCiStatus, cistatus);
        yield put(deleteCiStatusSuccess({ cistatus, ...response }));
        toast.success("CiStatus Delete Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(deleteCiStatusFail(error));
        toast.error("CiStatus Delete Failed", { autoClose: 3000 });
    }
}

function* onUpdateCiStatus({ payload: cistatus }) {
    try {
        const response = yield call(updateCiStatus, cistatus);
        yield put(updateCiStatusSuccess(response));
        toast.success("CiStatus Updated Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(updateCiStatusFail(error));
        toast.error("CiStatus Updated Failed", { autoClose: 3000 });
    }
}

export function* watchGetCiStatus() {
    yield takeEvery(GET_CI_STATUS, getCiStatus);
}

export function* watchGetCiStatusFilter() {
    yield takeEvery(GET_CI_STATUSFILTER, getCiStatusFilter);
}

export function* watchAddNewCiStatus() {
    yield takeEvery(ADD_NEW_CI_STATUS, onAddNewCiStatus);
}

export function* watchUpdateCiStatus() {
    yield takeEvery(UPDATE_CI_STATUS, onUpdateCiStatus);
}

export function* watchDeleteCiStatus() {
    yield takeEvery(DELETE_CI_STATUS, onDeleteCiStatus);
}

function* cistatusSaga() {
    yield all([
        fork(watchGetCiStatus),
        fork(watchGetCiStatusFilter),
        fork(watchAddNewCiStatus),
        fork(watchUpdateCiStatus),
        fork(watchDeleteCiStatus)
    ]
    );
}

export default cistatusSaga;
