import {
    GET_CAMGEO,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    ADD_CAMGEO_SUCCESS,
    ADD_CAMGEO_FAIL,
    UPDATE_CAMGEO_SUCCESS,
    UPDATE_CAMGEO_FAIL,
    DELETE_CAMGEO_SUCCESS,
    DELETE_CAMGEO_FAIL,
} from "./actionType";

const INIT_STATE = {
    camGeo: [],
};

const CamGeo = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:

            switch (action.payload.actionType) {
                case GET_CAMGEO:
                    return {
                        ...state,
                        camGeo: action.payload.data,
                        isCamGeoCreated: false,
                        isCamGeoSuccess: true
                    };

                default:
                    return { ...state };
            }

        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_CAMGEO:
                    return {
                        ...state,
                        error: action.payload.error,
                        isCamGeoCreated: false,
                        isCamGeoSuccess: true
                    };

                default:
                    return { ...state };
            }

        case GET_CAMGEO: {
            return {
                ...state,
                isCamGeoCreated: false
            };
        }

        case ADD_CAMGEO_SUCCESS:
            return {
                ...state,
                isCamGeoCreated: true,
                camGeo: [...state.camGeo, action.payload],
                isCamGeoAdd: true,
                isCamGeoAddFail: false,
            };

        case ADD_CAMGEO_FAIL:
            return {
                ...state,
                error: action.payload,
                isCamGeoAdd: false,
                isCamGeoAddFail: true,
            };

        case UPDATE_CAMGEO_SUCCESS:
            return {
                ...state,
                camGeo: state.camGeo.map(camgeo =>
                    camgeo.id.toString() === action.payload.id.toString()
                        ? { ...camgeo, ...action.payload }
                        : camgeo
                ),
                isCamGeoUpdate: true,
                isCamGeoUpdateFail: false
            };

        case UPDATE_CAMGEO_FAIL:
            return {
                ...state,
                error: action.payload,
                isCamGeoUpdate: false,
                isCamGeoUpdateFail: true
            };

        case DELETE_CAMGEO_SUCCESS:
            return {
                ...state,
                camGeo: state.camGeo.filter(
                    camgeo => camgeo.id.toString() !== action.payload.camgeo.toString()
                ),
                isCamGeoDelete: true,
                isCamGeoDeleteFail: false
            };

        case DELETE_CAMGEO_FAIL:
            return {
                ...state,
                error: action.payload,
                isCamGeoDelete: false,
                isCamGeoDeleteFail: true
            };

        default:
            return { ...state };
    }
};

export default CamGeo;