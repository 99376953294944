import React, { useState } from 'react';

import classnames from "classnames";
import { Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import FilterCiCadastro from "./FilterEsgotoAbas/FilterCiCadastro";
import FilterCiStatus from "./FilterEsgotoAbas/FilterCiStatus";
import FilterPvCadastro from "./FilterEsgotoAbas/FilterPvCadastro";
import FilterPvStatus from "./FilterEsgotoAbas/FilterPvStatus";
import FilterRedeCadastro from "./FilterEsgotoAbas/FilterRedeCadastro";
import FilterRedeStatus from './FilterEsgotoAbas/FilterRedeStatus';

const SearchOption = () => {

    // Custom Nav Tabs
    const [customNav, setcustomNav] = useState("1");
    const customNavtoggle = (tab) => {
        if (customNav !== tab) {
            setcustomNav(tab);
        }
    };

    return (
        <React.Fragment>

            <div className="content">

                <Col xxl={12}>
                    <Card>
                        <CardBody>
                            <Nav pills className="nav-customs nav-danger mb-3">
                                <NavItem>
                                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customNav === "1", })} onClick={() => { customNavtoggle("1"); }} >
                                        Caixa de Inspeção - Cadastro
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customNav === "2", })} onClick={() => { customNavtoggle("2"); }} >
                                        Caixa de Inspeção - Status
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customNav === "3", })} onClick={() => { customNavtoggle("3"); }} >
                                        Poço de Visita - Cadastro
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customNav === "4", })} onClick={() => { customNavtoggle("4"); }} >
                                        Poço de Visita - Status
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customNav === "5", })} onClick={() => { customNavtoggle("5"); }} >
                                        Rede - Cadastro
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customNav === "6", })} onClick={() => { customNavtoggle("6"); }} >
                                        Rede - Status
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent activeTab={customNav} className="text-muted">
                                <TabPane tabId="1" id="border-navs-home">
                                    <div className="tab-pane active" id="border-navs-home" role="tabpanel">
                                        <FilterCiCadastro />
                                    </div>
                                </TabPane>

                                <TabPane tabId="2" id="border-navs-profile">
                                    <div className="tab-pane active" id="border-navs-home" role="tabpanel">
                                        <FilterCiStatus />
                                    </div>
                                </TabPane>

                                <TabPane tabId="3" id="border-navs-messages">
                                    <div className="tab-pane active" id="border-navs-home" role="tabpanel">
                                        <FilterPvCadastro />
                                    </div>
                                </TabPane>

                                <TabPane tabId="4" id="border-navs-settings">
                                    <div className="tab-pane active" id="border-navs-home" role="tabpanel">
                                        <FilterPvStatus />
                                    </div>
                                </TabPane>

                                <TabPane tabId="5" id="border-navs-settings">
                                    <div className="tab-pane active" id="border-navs-home" role="tabpanel">
                                        <FilterRedeCadastro />
                                    </div>
                                </TabPane>

                                <TabPane tabId="6" id="border-navs-settings">
                                    <div className="tab-pane active" id="border-navs-home" role="tabpanel">
                                        <FilterRedeStatus />
                                    </div>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>

            </div>

        </React.Fragment>
    );
};

export default SearchOption;