// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_CAMGEO = "GET_CAMGEO";

/**
* Add CAMGEO
*/
export const ADD_NEW_CAMGEO = "ADD_NEW_CAMGEO";
export const ADD_CAMGEO_SUCCESS = "ADD_CAMGEO_SUCCESS";
export const ADD_CAMGEO_FAIL = "ADD_CAMGEO_FAIL";

/**
 * Edit CAMGEO
 */
export const UPDATE_CAMGEO = "UPDATE_CAMGEO";
export const UPDATE_CAMGEO_SUCCESS = "UPDATE_CAMGEO_SUCCESS";
export const UPDATE_CAMGEO_FAIL = "UPDATE_CAMGEO_FAIL";

/**
 * Delete CAMGEO
 */
export const DELETE_CAMGEO = "DELETE_CAMGEO";
export const DELETE_CAMGEO_SUCCESS = "DELETE_CAMGEO_SUCCESS";
export const DELETE_CAMGEO_FAIL = "DELETE_CAMGEO_FAIL";