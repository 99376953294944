// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_CI_STATUS = "GET_CI_STATUS";
export const GET_CI_STATUSFILTER = "GET_CI_STATUSFILTER";

/**
* Add CI_STATUS
*/
export const ADD_NEW_CI_STATUS = "ADD_NEW_CI_STATUS";
export const ADD_CI_STATUS_SUCCESS = "ADD_CI_STATUS_SUCCESS";
export const ADD_CI_STATUS_FAIL = "ADD_CI_STATUS_FAIL";

/**
 * Edit CI_STATUS
 */
export const UPDATE_CI_STATUS = "UPDATE_CI_STATUS";
export const UPDATE_CI_STATUS_SUCCESS = "UPDATE_CI_STATUS_SUCCESS";
export const UPDATE_CI_STATUS_FAIL = "UPDATE_CI_STATUS_FAIL";

/**
 * Delete CI_STATUS
 */
export const DELETE_CI_STATUS = "DELETE_CI_STATUS";
export const DELETE_CI_STATUS_SUCCESS = "DELETE_CI_STATUS_SUCCESS";
export const DELETE_CI_STATUS_FAIL = "DELETE_CI_STATUS_FAIL";