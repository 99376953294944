import React, { useState, useEffect } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch } from "react-redux";

import { useProfile } from "../Components/Hooks/UserHooks";

import { logoutUser } from "../Store/actions";

const AuthProtected = (props) => {
    const dispatch = useDispatch();
    const { userProfile, loading, token } = useProfile();
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (userProfile && !loading && token) {
            setAuthorization(token);
        } else if (!userProfile && loading && !token) {
            dispatch(logoutUser());
            setRedirectToReferrer(true);
        }
    }, [token, userProfile, loading, dispatch]);

    const allowedRoutes = {

        SanappAdmin:[
            "/login",
            "/register",
            "/forgot-password",
            "/password-reset",
            "/error-404",
            "/error-500",
            "/",
            "/webmap",
            "/dashboards",
            "/dash-casanbomabrigo",
            "/dash-redecad",
            "/dash-redestatus",
            "/dash-pvcad",
            "/dash-pvstatus",
            "/dash-cicad",
            "/dash-cistatus",
            "/filters",
            "/search-result",
            "/password-reset-confirm",
            "/profile",
        ],

        Morador:[
            "/login",
            "/register",
            "/forgot-password",
            "/password-reset",
            "/webmap",
            "/password-reset-confirm",
            "/profile",
        ],

        Gestor:[
            "/login",
            "/profile",
            "/register",
            "/password-reset-confirm",
            "/forgot-password",
            "/password-reset",
            "/error-404",
            "/error-500",
            "/",
            "/webmap",
            "/dashboards",
            "/dash-casanbomabrigo",
            "/dash-redecad",
            "/dash-redestatus",
            "/dash-pvcad",
            "/dash-pvstatus",
            "/dash-cicad",
            "/dash-cistatus",
            "/filters",
            "/search-result",
        ],

        OEM:[
            "/login",
            "/register",
            "/forgot-password",
            "/password-reset",
            "/reset-password",
            "/error-404",
            "/error-500",
            "/",
            "/webmap",
            "/password-reset-confirm",
            "/profile",
        ],

        Atendente:[
            "/login",
            "/register",
            "/forgot-password",
            "/password-reset",
            "/error-404",
            "/webmap",
            "/error-500",
            "/",
            "/filters",
            "/password-reset-confirm",
            "/profile",
        ],

        Public: [
            "/login",
            "/register",
            "/forgot-password",
            "/reset-password",
            "/password-reset",
            "/",
            "/error-404",
            "/error-500",
            "/password-reset-confirm",
            "/password-reset-confirm/:uidb64/:token",
            ], 
        };

        const isPasswordResetConfirmRoute = location.pathname.startsWith('/password-reset-confirm/');
        
    if (isPasswordResetConfirmRoute) {
        const [, uidb64, token] = location.pathname.split('/').filter(Boolean);
        return <Redirect to={`/password-reset-confirm/${uidb64}/${token}`} />;
    }
        
    if (location.pathname === '/password-reset') {
        return <Redirect to={`/password-reset`} />;
    }

    if (redirectToReferrer) {
        return <Redirect to={{ pathname: "/login" }} />;
    }

    if (userProfile && !loading && token) {
        if (allowedRoutes[userProfile.perfil_usuario].includes(props.currentPath)) {
            return <>{props.children}</>;
        } else {
            console.log("Não autorizado.");
            return <Redirect to={{ pathname: "/webmap", state: { from: props.location } }} />;
        }
    } else {
        if (props.currentPath === "/password-reset-confirm/:uidb64/:token") {
            return <>{props.children}</>;
        }
        console.log(`props.location: ${props.location}`)
        return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
    }
};

const AccessRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                return <Component {...props} />;
            }}
        />
    );
};

export { AuthProtected, AccessRoute };