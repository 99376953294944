import {
    GET_PV_CADASTROS,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    ADD_PV_CADASTROS_SUCCESS,
    ADD_PV_CADASTROS_FAIL,
    UPDATE_PV_CADASTROS_SUCCESS,
    UPDATE_PV_CADASTROS_FAIL,
    DELETE_PV_CADASTROS_SUCCESS,
    DELETE_PV_CADASTROS_FAIL,
} from "./actionType";

const INIT_STATE = {
    pvCadastros: [],
};

const PvCadastros = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:

            switch (action.payload.actionType) {
                case GET_PV_CADASTROS:
                    return {
                        ...state,
                        pvCadastros: action.payload.data,
                        isPvCadastrosCreated: false,
                        isPvCadastrosSuccess: true
                    };

                default:
                    return { ...state };
            }

        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_PV_CADASTROS:
                    return {
                        ...state,
                        error: action.payload.error,
                        isPvCadastrosCreated: false,
                        isPvCadastrosSuccess: true
                    };

                default:
                    return { ...state };
            }

        case GET_PV_CADASTROS: {
            return {
                ...state,
                isPvCadastrosCreated: false
            };
        }

        case ADD_PV_CADASTROS_SUCCESS:
            return {
                ...state,
                isPvCadastrosCreated: true,
                pvCadastros: [...state.pvCadastros, action.payload.data],
                isPvCadastrosAdd: true,
                isPvCadastrosAddFail: false,
            };

        case ADD_PV_CADASTROS_FAIL:
            return {
                ...state,
                error: action.payload,
                isPvCadastrosAdd: false,
                isPvCadastrosAddFail: true,
            };

        case UPDATE_PV_CADASTROS_SUCCESS:
            return {
                ...state,
                pvCadastros: state.pvCadastros.map(pvcadastros =>
                    pvcadastros.id.toString() === action.payload.id.toString()
                        ? { ...pvcadastros, ...action.payload }
                        : pvcadastros
                ),
                isPvCadastrosUpdate: true,
                isPvCadastrosUpdateFail: false
            };

        case UPDATE_PV_CADASTROS_FAIL:
            return {
                ...state,
                error: action.payload,
                isPvCadastrosUpdate: false,
                isPvCadastrosUpdateFail: true
            };

        case DELETE_PV_CADASTROS_SUCCESS:
            return {
                ...state,
                pvCadastros: state.pvCadastros.filter(
                    pvcadastros => pvcadastros.id.toString() !== action.payload.pvcadastros.toString()
                ),
                isPvCadastrosDelete: true,
                isPvCadastrosDeleteFail: false
            };

        case DELETE_PV_CADASTROS_FAIL:
            return {
                ...state,
                error: action.payload,
                isPvCadastrosDelete: false,
                isPvCadastrosDeleteFail: true
            };

        default:
            return { ...state };
    }
};

export default PvCadastros;