import {
    GET_REDE_STATUS,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    ADD_REDE_STATUS_SUCCESS,
    ADD_REDE_STATUS_FAIL,
    UPDATE_REDE_STATUS_SUCCESS,
    UPDATE_REDE_STATUS_FAIL,
    DELETE_REDE_STATUS_SUCCESS,
    DELETE_REDE_STATUS_FAIL,
} from "./actionType";

const INIT_STATE = {
    redeStatus: [],
};

const RedeStatus = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:

            switch (action.payload.actionType) {
                case GET_REDE_STATUS:
                    return {
                        ...state,
                        redeStatus: action.payload.data,
                        isRedeStatusCreated: false,
                        isRedeStatusSuccess: true
                    };

                default:
                    return { ...state };
            }

        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_REDE_STATUS:
                    return {
                        ...state,
                        error: action.payload.error,
                        isRedeStatusCreated: false,
                        isRedeStatusSuccess: true
                    };

                default:
                    return { ...state };
            }

        case GET_REDE_STATUS: {
            return {
                ...state,
                isRedeStatusCreated: false
            };
        }

        case ADD_REDE_STATUS_SUCCESS:
            return {
                ...state,
                isRedeStatusCreated: true,
                redeStatus: [...state.redeStatus, action.payload.data],
                isRedeStatusAdd: true,
                isRedeStatusAddFail: false,
            };

        case ADD_REDE_STATUS_FAIL:
            return {
                ...state,
                error: action.payload,
                isRedeStatusAdd: false,
                isRedeStatusAddFail: true,
            };

        case UPDATE_REDE_STATUS_SUCCESS:
            return {
                ...state,
                redeStatus: state.redeStatus.map(redestatus =>
                    redestatus._id.toString() === action.payload.data._id.toString()
                        ? { ...redestatus, ...action.payload.data }
                        : redestatus
                ),
                isRedeStatusUpdate: true,
                isRedeStatusUpdateFail: false
            };

        case UPDATE_REDE_STATUS_FAIL:
            return {
                ...state,
                error: action.payload,
                isRedeStatusUpdate: false,
                isRedeStatusUpdateFail: true
            };

        case DELETE_REDE_STATUS_SUCCESS:
            return {
                ...state,
                redeStatus: state.redeStatus.filter(
                    redestatus => redestatus._id.toString() !== action.payload.redestatus.toString()
                ),
                isRedeStatusDelete: true,
                isRedeStatusDeleteFail: false
            };

        case DELETE_REDE_STATUS_FAIL:
            return {
                ...state,
                error: action.payload,
                isRedeStatusDelete: false,
                isRedeStatusDeleteFail: true
            };

        default:
            return { ...state };
    }
};

export default RedeStatus;