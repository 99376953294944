import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Iframe from 'react-iframe'
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Loader from '../../Components/Common/Loader';
import FeatherIcon from "feather-icons-react";
import { useSelector } from 'react-redux';

const IframeWithLoading = ({ src, title, width, height }) => {
    const [loading, setLoading] = useState(true);
    const [iframeKey, setIframeKey] = useState(0);
    const [iframeSrc, setIframeSrc] = useState("?tab=2-externo#theme=night&bordered=false&titled=false");

    // Load LayoutMode state
    const layoutModeType = useSelector(state => state['Layout']['layoutModeType']);

    useEffect(() => {
        if (layoutModeType === 'dark') {
            setIframeSrc("?tab=2-externo#theme=night&bordered=false&titled=false");
            setIframeKey((prevKey) => prevKey + 1);
            setLoading(true);
        } else {
            setIframeSrc("?tab=2-externo#theme=light&bordered=false&titled=false");
            setIframeKey((prevKey) => prevKey + 1);
            setLoading(true);
        }
    }, [layoutModeType]);

    const handleLoad = () => {
        setLoading(false);
    };

    return (
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col xxl={12} className="w-100 h-100 bg-light">
                        {loading && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '20%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    zIndex: 1,
                                }}
                            >
                                <FeatherIcon
                                    icon="clock"
                                    className="text-info"
                                />
                                {" "}Buscando Informações...{" "}
                                <br />
                                <Loader />
                            </div>
                        )}
                        <Iframe
                            key={iframeKey}
                            src={src + iframeSrc}
                            title={title}
                            width={width}
                            height={height}
                            sandbox=''
                            frameborder="0"
                            onLoad={handleLoad}
                            style={{ display: loading ? 'none' : 'block' }}
                            allowtransparency
                        ></Iframe>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default IframeWithLoading;
