// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_REDE_STATUS = "GET_REDE_STATUS";

/**
* Add REDE_STATUS
*/
export const ADD_NEW_REDE_STATUS = "ADD_NEW_REDE_STATUS";
export const ADD_REDE_STATUS_SUCCESS = "ADD_REDE_STATUS_SUCCESS";
export const ADD_REDE_STATUS_FAIL = "ADD_REDE_STATUS_FAIL";

/**
 * Edit REDE_STATUS
 */
export const UPDATE_REDE_STATUS = "UPDATE_REDE_STATUS";
export const UPDATE_REDE_STATUS_SUCCESS = "UPDATE_REDE_STATUS_SUCCESS";
export const UPDATE_REDE_STATUS_FAIL = "UPDATE_REDE_STATUS_FAIL";

/**
 * Delete REDE_STATUS
 */
export const DELETE_REDE_STATUS = "DELETE_REDE_STATUS";
export const DELETE_REDE_STATUS_SUCCESS = "DELETE_REDE_STATUS_SUCCESS";
export const DELETE_REDE_STATUS_FAIL = "DELETE_REDE_STATUS_FAIL";