export const formMaker = `
<style>
.divpopup {
    height: 380px;
    width: 350px;
}
.divtable {
    height: 250px;
    width: 350px;
    overflow-y: scroll;
}
</style>
<div class="divpopup row gy-5 ">
    <form>
        <div class="divtable col-12 g-xxl-12 mt-2">
            <!-- ID CI -->
            <div class="col-xxl-2">
                <label class="form-label mt-0 fs-12 mb-0" for="id_ci">ID CI:</label>
                <input type="text" class="form-control form-control-sm" name="id_ci" id="id_ci_popup" />
            </div>
            <!-- LOGRADOURO -->
            <div class="col-xxl-12">
                <label class="form-label mt-0 fs-12 mb-0" for="logradouro">LOGRADOURO:</label>
                <input type="text" class="form-control form-control-sm" name="logradouro" id="logradouro_popup" />
            </div>
            <!-- No. EDIFÍCIO -->
            <div class="col-xxl-4">
                <label class="form-label mt-0 fs-12 mb-0" for="n_edificac">No. EDIFÍCIO:</label>
                <input type="text" class="form-control form-control-sm" name="n_edificac" id="n_edificac_popup" />
            </div>
            <!-- BAIRRO -->
            <div class="col-xxl-10">
                <label class="form-label mt-0 fs-12 mb-0" for="bairro">BAIRRO:</label>
                <input type="text" class="form-control form-control-sm" name="bairro" id="bairro_popup" />
            </div>
            <!-- CIDADE -->
            <div class="col-xxl-10">
                <label class="form-label mt-0 fs-12 mb-0" for="cidade">CIDADE:</label>
                <input type="text" class="form-control form-control-sm" name="cidade" id="cidade_popup" />
            </div>
            <!-- DIAM. INT. DO POÇO -->
            <div class="col-xxl-10">
                <label class="form-label mt-3 fs-12 mb-0 text-nowrap" for="d_int_poco">DIAMETRO INTERNO DO POÇO:</label>
                <select class="form-control-sm" name="d_int_poco" id="inputD_int_poco">
                    <option selected>Selecionar</option>
                    <option value="400mm">400mm</option>
                    <option value="600mm">600mm</option>
                    <option value="100mm">100mm</option>
                    <option value="150mm">150mm</option>
                    <option value="800mm">800mm</option>
                    <option value="1000mm">1000mm</option>
                    <option value="Outros">Outros</option>
                </select>
            </div>
            <!-- METERIAL DO TUBO S -->
            <div class="col-12 col-xxl-10">
                <label class="form-label mt-3 fs-12 mb-0 text-nowrap" for="mat_tubo_s">MATERIAL DO TUBO DE SAÍDA:</label>
                <select class="form-control-sm" name="mat_tubo_s" id="inputMat_tubo_s">
                    <option selected>Selecionar</option>
                    <option value="PVC">PVC</option>
                    <option value="Manilha Cerâmica">Manilha Cerâmica</option>
                    <option value="Amianto">Amianto</option>
                    <option value="Manilha Concreto">Manilha Concreto</option>
                    <option value="PEAD">PEAD</option>
                    <option value="Outros">Outros</option>
                </select>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Cadastrar</button>
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Fechar</button>
        </div>
    </form>
</div>
`