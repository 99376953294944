// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_REDE_CADASTROS = "GET_REDE_CADASTROS";

/**
* Add REDE_CADASTROS
*/
export const ADD_NEW_REDE_CADASTROS = "ADD_NEW_REDE_CADASTROS";
export const ADD_REDE_CADASTROS_SUCCESS = "ADD_REDE_CADASTROS_SUCCESS";
export const ADD_REDE_CADASTROS_FAIL = "ADD_REDE_CADASTROS_FAIL";

/**
 * Edit REDE_CADASTROS
 */
export const UPDATE_REDE_CADASTROS = "UPDATE_REDE_CADASTROS";
export const UPDATE_REDE_CADASTROS_SUCCESS = "UPDATE_REDE_CADASTROS_SUCCESS";
export const UPDATE_REDE_CADASTROS_FAIL = "UPDATE_REDE_CADASTROS_FAIL";

/**
 * Delete REDE_CADASTROS
 */
export const DELETE_REDE_CADASTROS = "DELETE_REDE_CADASTROS";
export const DELETE_REDE_CADASTROS_SUCCESS = "DELETE_REDE_CADASTROS_SUCCESS";
export const DELETE_REDE_CADASTROS_FAIL = "DELETE_REDE_CADASTROS_FAIL";