import {
  GET_CAMGEO,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_NEW_CAMGEO,
  ADD_CAMGEO_SUCCESS,
  ADD_CAMGEO_FAIL,
  UPDATE_CAMGEO,
  UPDATE_CAMGEO_SUCCESS,
  UPDATE_CAMGEO_FAIL,
  DELETE_CAMGEO,
  DELETE_CAMGEO_SUCCESS,
  DELETE_CAMGEO_FAIL,
} from "./actionType";

// common success
export const CamGeoApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const CamGeoApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getCamGeo = camgeo => ({
  type: GET_CAMGEO,
  payload: camgeo,
});

export const updateCamGeo = camgeo => ({
  type: UPDATE_CAMGEO,
  payload: camgeo,
});

export const updateCamGeoSuccess = camgeo => ({
  type: UPDATE_CAMGEO_SUCCESS,
  payload: camgeo,
});

export const updateCamGeoFail = error => ({
  type: UPDATE_CAMGEO_FAIL,
  payload: error,
});

export const addNewCamGeo = camgeo => ({
  type: ADD_NEW_CAMGEO,
  payload: camgeo,
});

export const addCamGeoSuccess = camgeo => ({
  type: ADD_CAMGEO_SUCCESS,
  payload: camgeo,
});

export const addCamGeoFail = error => ({
  type: ADD_CAMGEO_FAIL,
  payload: error,
});
export const deleteCamGeo = camgeo => ({
  type: DELETE_CAMGEO,
  payload: camgeo,
});

export const deleteCamGeoSuccess = camgeo => ({
  type: DELETE_CAMGEO_SUCCESS,
  payload: camgeo,
});

export const deleteCamGeoFail = error => ({
  type: DELETE_CAMGEO_FAIL,
  payload: error,
});