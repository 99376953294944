import {
    GET_PV_STATUS,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    ADD_PV_STATUS_SUCCESS,
    ADD_PV_STATUS_FAIL,
    UPDATE_PV_STATUS_SUCCESS,
    UPDATE_PV_STATUS_FAIL,
    DELETE_PV_STATUS_SUCCESS,
    DELETE_PV_STATUS_FAIL,
} from "./actionType";

const INIT_STATE = {
    pvStatus: [],
};

const PvStatus = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:

            switch (action.payload.actionType) {
                case GET_PV_STATUS:
                    return {
                        ...state,
                        pvStatus: action.payload.data,
                        isPvStatusCreated: false,
                        isPvStatusSuccess: true
                    };

                default:
                    return { ...state };
            }

        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_PV_STATUS:
                    return {
                        ...state,
                        error: action.payload.error,
                        isPvStatusCreated: false,
                        isPvStatusSuccess: true
                    };

                default:
                    return { ...state };
            }

        case GET_PV_STATUS: {
            return {
                ...state,
                isPvStatusCreated: false
            };
        }

        case ADD_PV_STATUS_SUCCESS:
            return {
                ...state,
                isPvStatusCreated: true,
                pvStatus: [...state.pvStatus, action.payload],
                isPvStatusAdd: true,
                isPvStatusAddFail: false,
            };

        case ADD_PV_STATUS_FAIL:
            return {
                ...state,
                error: action.payload,
                isPvStatusAdd: false,
                isPvStatusAddFail: true,
            };

        case UPDATE_PV_STATUS_SUCCESS:
            return {
                ...state,
                pvStatus: state.pvStatus.map(pvstatus =>
                    pvstatus.id.toString() === action.payload.id.toString()
                        ? { ...pvstatus, ...action.payload }
                        : pvstatus
                ),
                isPvStatusUpdate: true,
                isPvStatusUpdateFail: false
            };

        case UPDATE_PV_STATUS_FAIL:
            return {
                ...state,
                error: action.payload,
                isPvStatusUpdate: false,
                isPvStatusUpdateFail: true
            };

        case DELETE_PV_STATUS_SUCCESS:
            return {
                ...state,
                pvStatus: state.pvStatus.filter(
                    pvstatus => pvstatus.id.toString() !== action.payload.pvstatus.toString()
                ),
                isPvStatusDelete: true,
                isPvStatusDeleteFail: false
            };

        case DELETE_PV_STATUS_FAIL:
            return {
                ...state,
                error: action.payload,
                isPvStatusDelete: false,
                isPvStatusDeleteFail: true
            };

        default:
            return { ...state };
    }
};

export default PvStatus;