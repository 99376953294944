import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Navdata = () => {
    const history = useHistory();
    //state data
    const [isSANapp, setIsSANapp] = useState(false);
    const [isHome, setIsHome] = useState(false);
    const [isDashboards, setIsDashboards] = useState(false);
    const [isFilter, setIsFilter] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [isService, setIsService] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Home');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Home') {
            setIsHome(false);
        }
        if (iscurrentState === 'Widgets') {
            history.push("/widgets");
            document.body.classList.add('twocolumn-panel');
        }
    }, [
        history,
        iscurrentState,
        isHome,
    ]);

    const menuItems = [
        {
            label: "Gerais",
            isHeader: true,
        },
        {
            id: "home",
            label: "Home",
            icon: 'las la-globe-americas',
            link: "/webmap",
            isChildItem: false,
            stateVariables: isHome,
            click: function (e) {
                e.preventDefault();
                setIsHome(!isHome);
                setIscurrentState('Home');
                updateIconSidebar(e);
            },
        },
        {
            id: "sanappdashboards",
            label: "Dashboards",
            icon: "ri-pie-chart-2-line",
            link: "/dashboards",
            stateVariables: isDashboards,
            click: function (e) {
                e.preventDefault();
                setIsDashboards(!isDashboards);
                setIscurrentState('Dashboards');
                updateIconSidebar(e);
            },

            isChildItem: false,
            /*
            subItems: [
                { id: "dashboards", label: "Dashboards", icon: "bx bx-bar-chart-square", link: "/dashboards", parentId: "sanapp" },
                { id: "redecad", label: "Rede - Cadastro", icon: "bx bx-bar-chart-square", link: "/dash-redecad", parentId: "sanapp" },
                { id: "redestatus", label: "Rede - Status", icon: "bx bx-bar-chart-square", link: "/dash-redestatus", parentId: "sanapp" },
                { id: "pvcad", label: "Pv - Cadastro", icon: "bx bx-bar-chart-square", link: "/dash-pvcad", parentId: "sanapp" },
                { id: "pvstatus", label: "Pv - Status", icon: "bx bx-bar-chart-square", link: "/dash-pvstatus", parentId: "sanapp" },
                { id: "cicad", label: "Ci - Cadastro", icon: "bx bx-bar-chart-square", link: "/dash-cicad", parentId: "sanapp" },
                { id: "cistatus", label: "Ci - Status", icon: "bx bx-bar-chart-square", link: "/dash-cistatus", parentId: "sanapp" },
            ],
            */

        },
        {
            id: "sanappfilterresult",
            label: "Filtros",
            icon: "ri-filter-fill",
            link: "/filters",
            stateVariables: isFilter,
            click: function (e) {
                e.preventDefault();
                setIsFilter(!isFilter);
                setIscurrentState('Filtros');
                updateIconSidebar(e);
            },
        },
        {
            id: "sanappsearchresult",
            label: "Pesquisar",
            icon: "bx bxs-search-alt-2",
            link: "/search-result",
            stateVariables: isSearch,
            click: function (e) {
                e.preventDefault();
                setIsSearch(!isSearch);
                setIscurrentState('Pesquisar');
                updateIconSidebar(e);
            },
        },
        {
            id: "sanapporderservice",
            label: "Ordem de Serviço",
            icon: "ri-customer-service-2-line",
            link: "/ordem-servico",
            stateVariables: isService,
            click: function (e) {
                e.preventDefault();
                setIsService(!isService);
                setIscurrentState('Ordem de Serviço');
                updateIconSidebar(e);
            },
        },
        {
            label: "Administrativo",
            isHeader: true,
        },
        {
            id: "sanapp",
            label: "SANapp",
            icon: "las la-globe-americas",
            link: "/esgoto-maps",
            stateVariables: isSANapp,
            click: function (e) {
                e.preventDefault();
                setIsSANapp(!isSANapp);
                setIscurrentState('SANapp');
                updateIconSidebar(e);
            },
            subItems: [
            ],
        },
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
