import { combineReducers } from "redux";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import NewPassword from "./auth/createnewpwd/reducer";
import Profile from "./auth/profile/reducer";

import CiCadastros from "./cicadesgoto/reducer";
import CiStatus from "./cistatusesgoto/reducer";
import PvCadastros from "./pvcadesgoto/reducer";
import PvStatus from "./pvstatusesgoto/reducer";
import RedeCadastros from "./redecadesgoto/reducer";
import RedeStatus from "./redestatusesgoto/reducer";

//CamGeoserver
import CamGeo from "./camgeoserver/reducer";

// Front
import Layout from "./layouts/reducer";

const rootReducer = combineReducers({
    // public
    Login,
    Account,
    ForgetPassword,
    NewPassword,
    Profile,
    CiCadastros,
    CiStatus,
    PvCadastros,
    PvStatus,
    RedeCadastros,
    RedeStatus,
    CamGeo,
    Layout,
});

export default rootReducer;