import React from 'react';
import IframeWithLoading from "../IframeWithLoading"


const DashboardPvStatus = () => {
    document.title = "Dashboard Pv - Status | SANAPP";
    return (
        <React.Fragment>
            <IframeWithLoading
                src="https://metabase.apisanapp.com.br/public/dashboard/b9aaedf9-1a24-452d-8e72-d5c83333a8d4"
                title="Pv - Status"
                width="100%"
                height="1150"
            />
        </React.Fragment>
    );
};

export default DashboardPvStatus;