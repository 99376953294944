import {
    GET_REDE_CADASTROS,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    ADD_REDE_CADASTROS_SUCCESS,
    ADD_REDE_CADASTROS_FAIL,
    UPDATE_REDE_CADASTROS_SUCCESS,
    UPDATE_REDE_CADASTROS_FAIL,
    DELETE_REDE_CADASTROS_SUCCESS,
    DELETE_REDE_CADASTROS_FAIL,
} from "./actionType";

const INIT_STATE = {
    redeCadastros: [],
    redeCadastrosFilter: [],
};

const RedeCadastros = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:

            switch (action.payload.actionType) {
                case GET_REDE_CADASTROS:
                    return {
                        ...state,
                        redeCadastros: action.payload.data,
                        isRedeCadastrosCreated: false,
                        isRedeCadastrosSuccess: true
                    };

                default:
                    return { ...state };
            }

        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_REDE_CADASTROS:
                    return {
                        ...state,
                        error: action.payload.error,
                        isRedeCadastrosCreated: false,
                        isRedeCadastrosSuccess: true
                    };

                default:
                    return { ...state };
            }

        case GET_REDE_CADASTROS: {
            return {
                ...state,
                isRedeCadastrosCreated: false
            };
        }

        case ADD_REDE_CADASTROS_SUCCESS:
            return {
                ...state,
                isRedeCadastrosCreated: true,
                redeCadastros: [...state.redeCadastros, action.payload.data],
                isRedeCadastrosAdd: true,
                isRedeCadastrosAddFail: false,
            };

        case ADD_REDE_CADASTROS_FAIL:
            return {
                ...state,
                error: action.payload,
                isRedeCadastrosAdd: false,
                isRedeCadastrosAddFail: true,
            };

        case UPDATE_REDE_CADASTROS_SUCCESS:
            return {
                ...state,
                redeCadastros: state.redeCadastros.map(redecadastros =>
                    redecadastros.id.toString() === action.payload.data.id.toString()
                        ? { ...redecadastros, ...action.payload.data }
                        : redecadastros
                ),
                isRedeCadastrosUpdate: true,
                isRedeCadastrosUpdateFail: false
            };

        case UPDATE_REDE_CADASTROS_FAIL:
            return {
                ...state,
                error: action.payload,
                isRedeCadastrosUpdate: false,
                isRedeCadastrosUpdateFail: true
            };

        case DELETE_REDE_CADASTROS_SUCCESS:
            return {
                ...state,
                redeCadastros: state.redeCadastros.filter(
                    redecadastros => redecadastros.id.toString() !== action.payload.redecadastros.toString()
                ),
                isRedeCadastrosDelete: true,
                isRedeCadastrosDeleteFail: false
            };

        case DELETE_REDE_CADASTROS_FAIL:
            return {
                ...state,
                error: action.payload,
                isRedeCadastrosDelete: false,
                isRedeCadastrosDeleteFail: true
            };

        default:
            return { ...state };
    }
};

export default RedeCadastros;