import React from 'react';
import IframeWithLoading from "../IframeWithLoading"

const DashboardRedeCad = () => {
    document.title = "Dashboard Rede - Cadastro | SANAPP";
    return (
        <React.Fragment>
            <IframeWithLoading
                src="https://metabase.apisanapp.com.br/public/dashboard/282965ec-4e67-4f1e-856f-e712b31e11a1"
                title="Redes - Cadastros"
                width="100%"
                height="950"
            />
        </React.Fragment>
    );
};

export default DashboardRedeCad;
