import L from 'leaflet';

export const baseLayers = {
    "Google Maps Street": L.tileLayer('https://{s}.google.com/vt/lyrs=r&x={x}&y={y}&z={z}', {
        attribution: '<a href="https://www.openstreetmap.org/">OpenStreetMap</a>',
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        minZoom: 1,
        maxZoom: 22
    }),
    "Google Maps Satelite": L.tileLayer('https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
        attribution: '<a href="https://www.openstreetmap.org/">OpenStreetMap</a>',
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        minZoom: 1,
        maxZoom: 22,
        detectRetina: true
    })
};
