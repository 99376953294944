import React, { useState, useCallback } from 'react';
import proj4 from 'proj4';
import classnames from "classnames";
import { Button, Row, Col, Label, Table, Modal, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Popup, LayerGroup, Polyline, LayersControl } from 'react-leaflet';
import { useDispatch } from "react-redux";
import SimpleBar from 'simplebar-react';
import EditModalRedeCad from "../../Helpers/Editar/EditModalRedeCad";
import { updateRedeCadastros } from "../../../../Store/redecadesgoto/action";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import { DictRedeCad } from '../../../../Legends/DictRedeCad';
import { DictRedeStatus } from '../../../../Legends/DictRedeStatus';
const { Overlay } = LayersControl;


const PreventClosePopup = ({ children }) => {
    const handleClick = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
    }, []);

    return <div onClick={handleClick}>{children}</div>;
};

function CamadaRedeCadastro({ redeCadastros, redeStatus }) {
    const combinedData = redeCadastros.map(cadastro => {
        const status = redeStatus.filter(statusItem => statusItem.id_cad === cadastro.id_cad);
        return { ...cadastro, status };
    });

    const [modal_center_rede, setmodal_center_rede] = useState(false);
    const tog_center_rede = () => setmodal_center_rede(!modal_center_rede);

    const dispatch = useDispatch();
    const navyOptions = { color: 'navy' }

    const saveEditDataRedeCad = async (editedData) => {
        if (editedData.imagem) { delete editedData.imagem };
        const response = await dispatch(updateRedeCadastros(editedData));
    };

    const transformline = (line, sourceEPSG) => {
        proj4.defs("EPSG:" + sourceEPSG, "+proj=utm +zone=22 +south +ellps=GRS80 +units=m +no_defs");
        const lineArray = line.replace(',', '').replace('((', '(').replace('))', ')').split('(')[1].split(')')[0].split(' ');
        const coord_a = [parseFloat(lineArray[1]), parseFloat(lineArray[0])];
        const coord_b = [parseFloat(lineArray[3]), parseFloat(lineArray[2])];

        return [coord_b, coord_a]
    };

    const [customNav, setcustomNav] = useState("1");
    const customNavtoggle = (tab) => {
        if (customNav !== tab) {
            setcustomNav(tab);
        }
    };

    const [selectedStatus, setSelectedStatus] = useState(null);

    return (
        <Overlay name="REDE - CADASTROS" checked>
            <LayerGroup style={{ zIndex: 1 }}>
                {combinedData.map((item) => (
                    <Polyline
                        pathOptions={navyOptions}
                        positions={transformline(item.geom, item.geom.split(';')[0].split('=')[1])}
                        key={item.id_cad}
                        weight={3.5}
                    >
                        <Popup>
                            <PreventClosePopup>
                                <Row>
                                    <Col lg={12}>
                                        <Nav pills className="nav-pills nav-primary mb-3 table-responsive">
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({ active: customNav === "1" })}
                                                    onClick={() => { customNavtoggle("1"); }}
                                                >
                                                    CADASTRO
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({ active: customNav === "2" })}
                                                    onClick={() => { customNavtoggle("2"); setSelectedStatus(null); }}
                                                >
                                                    STATUS
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({ active: customNav === "3" })}
                                                    onClick={() => { customNavtoggle("3"); }}
                                                >
                                                    ORDEM SERVIÇO
                                                </NavLink>
                                            </NavItem>
                                        </Nav>

                                        <TabContent activeTab={customNav} className="text-muted">
                                            <TabPane tabId="1" id="border-navs-cadastro">
                                                <Label className="text-secondary mt-1 fs-16 mb-0">REDE - CADASTRO</Label>
                                                <hr />
                                                <SimpleBar autoHide={false} className="simplebar-track-secondary" style={{ maxHeight: "250px" }}>
                                                    <div className="table-responsive mt-4 mt-xl-0">
                                                        <Table
                                                            fixedHeaderScrollHeight="50px"
                                                            className="table-sm table-hover table-striped align-middle table-nowrap mb-0 table-responsive"
                                                        >
                                                            <tbody>
                                                                {Object.keys(DictRedeCad).map((key) => (
                                                                    <tr key={key}>
                                                                        <td className="text-secondary fs-12">{DictRedeCad[key].label}:</td>
                                                                        <td className="text-primary fs-12">{item[key]}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </SimpleBar>
                                                <hr />
                                                <div className="hstack align-content-between gap-3">
                                                    <Button
                                                        className="mt-3 shadow-lg p-2 px-4 me-3"
                                                        color='secondary'
                                                        onClick={() => tog_center_rede()}
                                                    >VÍDEO</Button>
                                                    <EditModalRedeCad data={item} onSave={saveEditDataRedeCad} />
                                                    <Button
                                                        className="mt-3 shadow-lg p-2 px-2 ms-3"
                                                        color='success'
                                                    >GERAR PDF</Button>
                                                </div>
                                            </TabPane>

                                            <TabPane tabId="2" id="border-navs-status">
                                                <div className="hstack gap-3 justify-content-between mb-3">
                                                    <Label className="text-secondary mt-1 fs-16 mb-1">REDE - STATUS</Label>

                                                    {selectedStatus ? (
                                                        <Button
                                                            color="link"
                                                            className="p-0 justify-content-end"
                                                            onClick={() => setSelectedStatus(null)}
                                                        >
                                                            Voltar à lista
                                                        </Button>
                                                    ) : (
                                                        null
                                                    )
                                                    }
                                                </div>
                                                <hr />
                                                <SimpleBar autoHide={false} className="simplebar-track-secondary" style={{ maxHeight: "250px" }}>
                                                    <div className="table-responsive mt-4 mt-xl-0 mb-1">
                                                        {item.status.length > 0 ? (
                                                            selectedStatus ? (
                                                                <>
                                                                    <Table
                                                                        fixedHeaderScrollHeight="50px"
                                                                        className="table-sm table-hover table-striped align-middle table-nowrap mb-0 table-responsive"
                                                                    >
                                                                        <tbody>
                                                                            {Object.keys(DictRedeStatus).map((key) => (
                                                                                <tr key={key}>
                                                                                    <td className="text-secondary fs-12">{DictRedeStatus[key].label}:</td>
                                                                                    <td className="text-primary fs-12">{selectedStatus[key]}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </Table>
                                                                </>
                                                            ) : (

                                                                <div className="table-responsive mt-4 mt-xl-0 mb-1" style={{ height: "500px" }}>
                                                                    <Table
                                                                        fixedHeaderScrollHeight="50px"
                                                                        className="table-sm table-hover table-striped align-middle table-nowrap mb-0 table-responsive"
                                                                    >
                                                                        <tbody>

                                                                            {item.status.map((status, index) => (
                                                                                <tr key={index}>
                                                                                    <ul className="list-unstyled">
                                                                                        <li key={index}>
                                                                                            <td className="text-secondary fs-12">
                                                                                                <Button
                                                                                                    color="link"
                                                                                                    className="p-0"
                                                                                                    onClick={() => setSelectedStatus(status)}
                                                                                                >
                                                                                                    ID: {status.id_status}
                                                                                                </Button>
                                                                                            </td>
                                                                                        </li>
                                                                                        <li key={index}>
                                                                                            <td className="text-secondary fs-12">
                                                                                                <Button
                                                                                                    color="link"
                                                                                                    className="p-0"
                                                                                                    onClick={() => setSelectedStatus(status)}
                                                                                                >
                                                                                                    STATUS: {status.status.replace(/_/g, " ")}
                                                                                                </Button>
                                                                                            </td>
                                                                                        </li>
                                                                                    </ul>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>

                                                                    </Table>
                                                                </div>

                                                            )
                                                        ) : (
                                                            <div className="d-flex gap-3" style={{ height: "500px" }}>
                                                                <p className="text-primary fs-20 justify-content-center mt-5">SEM STATUS REGISTRADO PARA ESTA REDE</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </SimpleBar>
                                                <hr />
                                                <div className="hstack gap-3">
                                                    <Button
                                                        className="mt-3 shadow-lg p-2 px-4 me-3"
                                                        color='success'
                                                    >FOTOS</Button>
                                                    <Button
                                                        className="mt-3 shadow-lg p-2 px-4"
                                                        color='primary'
                                                    >EDITAR</Button>
                                                    <Button
                                                        className="mt-3 shadow-lg p-2 px-3 ms-2"
                                                        color='danger'
                                                    >ABRIR O.S</Button>
                                                </div>
                                            </TabPane>

                                            <TabPane tabId="3" id="border-navs-ordens">
                                                <Label className="text-secondary mt-1 fs-16 mb-0">REDE - ORDEM SERVIÇO</Label>
                                                <hr />
                                                <SimpleBar autoHide={false} className="simplebar-track-secondary" style={{ maxHeight: "250px" }}>
                                                    <div className="table-responsive mt-4 mt-xl-0">
                                                        <Table
                                                            fixedHeaderScrollHeight="50px"
                                                            className="table-sm table-hover table-striped align-middle table-nowrap mb-0 table-responsive"
                                                        >
                                                            <tbody>
                                                                <div className="d-flex" style={{ height: "500px" }}>
                                                                    ...
                                                                </div>
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </SimpleBar>
                                                <hr />
                                                <div className="hstack gap-3">
                                                    <Button
                                                        className="mt-3 shadow-lg p-2 px-3 me-1"
                                                        color='success'
                                                    >VISUALIZAR</Button>
                                                    <Button
                                                        className="mt-3 shadow-lg p-2 px-4"
                                                        color='primary'
                                                    >EDITAR</Button>
                                                    <Button
                                                        className="mt-3 shadow-lg p-2 px-3 ms-1"
                                                        color='danger'
                                                    >ENCERRAR</Button>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </Col>
                                </Row>
                                <Modal
                                    isOpen={modal_center_rede}
                                    toggle={() => {
                                        tog_center_rede();
                                    }}
                                    size="lg"
                                    centered
                                >
                                    <Row className="m-3">
                                        <h4 className="card-title mb-0">VIDEO DO TRECHO</h4>
                                        <p className="text-muted">Video disponível da ultima inspeção realizada:</p>
                                        <Swiper className="mySwiper swiper default-swiper rounded">
                                            <Col md='12'>
                                                <div className="swiper-nowrap">
                                                    <SwiperSlide className="ratio ratio-16x9">
                                                        {
                                                            item.id_cad === 278 ?
                                                                <iframe
                                                                    src="https://drive.google.com/file/d/1grTRwkKoPHZRx3HZeEUIRHlLcxZGIIRe/preview"
                                                                    title="video player" frameBorder="0"
                                                                    width="640" height="480" allow="autoplay"
                                                                ></iframe>
                                                                :
                                                                <iframe
                                                                    src="https://drive.google.com/file/d/1TgA82nXYrQ1YZVt1e5jNM82k1ojWzAYc/preview"
                                                                    title="video player" frameBorder="0"
                                                                    width="640" height="480" allow="autoplay"
                                                                ></iframe>
                                                        }
                                                    </SwiperSlide>
                                                </div>
                                            </Col>
                                        </Swiper>
                                    </Row>
                                </Modal>
                            </PreventClosePopup>
                        </Popup>
                    </Polyline>
                ))}
            </LayerGroup>
        </Overlay>
    );
}

export default CamadaRedeCadastro;