import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Input, Col, Row, Label, Table, Card, CardBody, Button } from 'reactstrap';
import ReactPaginate from 'react-paginate';

import proj4 from 'proj4';

import { useSelector, useDispatch } from "react-redux";

// Api Conection
import { getCiCadastros } from "../../../Store/cicadesgoto/action";
import { getCiStatus } from "../../../Store/cistatusesgoto/action";
import { getPvCadastros } from "../../../Store/pvcadesgoto/action";
import { getPvStatus } from "../../../Store/pvstatusesgoto/action";
import { getRedeCadastros } from "../../../Store/redecadesgoto/action";
import { getRedeStatus } from "../../../Store/redestatusesgoto/action";
import * as XLSX from 'xlsx';
import FileSaver from "file-saver";

// Custom hook for fetching data and managing state
function useDataFetch(actionFunction, nameFunction, dataProperty) {
    const dispatch = useDispatch();
    const data = useSelector(state => state[nameFunction][dataProperty]);
    useEffect(() => {
        if (!data.length) {
            dispatch(actionFunction());
        }
    }, [dispatch, data]);
    return data;
};

const SearchOption = () => {

    const [keyword, setKeyword] = useState('');
    const [resultCiCadastro, setResultCiCadastro] = useState([]);
    const [resultCiStatus, setResultCiStatus] = useState([]);
    const [resultPvCadastro, setResultPvCadastro] = useState([]);
    const [resultPvStatus, setResultPvStatus] = useState([]);
    const [resultRedeCadastro, setResultRedeCadastro] = useState([]);
    const [resultRedeStatus, setResultRedeStatus] = useState([]);

    // Lista de itens selecionados
    const [checkedCiCadastros, setCheckedCiCadastros] = useState([]);
    const [checkedCiStatus, setCheckedCiStatus] = useState([]);
    const [checkedPvCadastros, setCheckedPvCadastros] = useState([]);
    const [checkedPvStatus, setCheckedPvStatus] = useState([]);
    const [checkedRedeCadastros, setCheckedRedeCadastros] = useState([]);
    const [checkedRedeStatus, setCheckedRedeStatus] = useState([]);

    // CheckBox select all variavel
    const [selectAllCiCadastros, setSelectAllCiCadastros] = useState(false);
    const [selectAllCiStatus, setSelectAllCiStatus] = useState(false);
    const [selectAllPvCadastros, setSelectAllPvCadastros] = useState(false);
    const [selectAllPvStatus, setSelectAllPvStatus] = useState(false);
    const [selectAllRedeCadastros, setSelectAllRedeCadastros] = useState(false);
    const [selectAllRedeStatus, setSelectAllRedeStatus] = useState(false);

    // Carregar lista de itens completa da API
    const ciCadastros = useDataFetch(getCiCadastros, 'CiCadastros', 'ciCadastros');
    const ciStatus = useDataFetch(getCiStatus, 'CiStatus', 'ciStatus');
    const pvCadastros = useDataFetch(getPvCadastros, 'PvCadastros', 'pvCadastros');
    const pvStatus = useDataFetch(getPvStatus, 'PvStatus', 'pvStatus');
    const redeCadastros = useDataFetch(getRedeCadastros, 'RedeCadastros', 'redeCadastros');
    const redeStatus = useDataFetch(getRedeStatus, 'RedeStatus', 'redeStatus');

    const handleInputChange = (event) => { setKeyword(event.target.value) };

    const handleSearch = () => {
        //console.log(ciCadastros)
        if (keyword) {
            const filteredResultCiCadastros = ciCadastros.filter(item => {
                // Verifica se a keyword está presente em algum valor das chaves
                return Object.keys(item).some(key => {
                    const value = item[key];
                    return typeof value === 'string' && value.toLowerCase().includes(keyword.toLowerCase());
                });
            });
            setResultCiCadastro(filteredResultCiCadastros)

            const filteredResultCiStatus = ciStatus.filter(item => {
                // Verifica se a keyword está presente em algum valor das chaves
                return Object.keys(item).some(key => {
                    const value = item[key];
                    return typeof value === 'string' && value.toLowerCase().includes(keyword.toLowerCase());
                });
            });
            setResultCiStatus(filteredResultCiStatus)

            const filteredResultPvCadastros = pvCadastros.filter(item => {
                // Verifica se a keyword está presente em algum valor das chaves
                return Object.keys(item).some(key => {
                    const value = item[key];
                    return typeof value === 'string' && value.toLowerCase().includes(keyword.toLowerCase());
                });
            });
            setResultPvCadastro(filteredResultPvCadastros)

            const filteredResultPvStatus = pvStatus.filter(item => {
                // Verifica se a keyword está presente em algum valor das chaves
                return Object.keys(item).some(key => {
                    const value = item[key];
                    return typeof value === 'string' && value.toLowerCase().includes(keyword.toLowerCase());
                });
            });
            setResultPvStatus(filteredResultPvStatus)

            const filteredResultRedeCadastros = redeCadastros.filter(item => {
                // Verifica se a keyword está presente em algum valor das chaves
                return Object.keys(item).some(key => {
                    const value = item[key];
                    return typeof value === 'string' && value.toLowerCase().includes(keyword.toLowerCase());
                });
            });
            setResultRedeCadastro(filteredResultRedeCadastros)

            const filteredResultRedeStatus = ciStatus.filter(item => {
                // Verifica se a keyword está presente em algum valor das chaves
                return Object.keys(item).some(key => {
                    const value = item[key];
                    return typeof value === 'string' && value.toLowerCase().includes(keyword.toLowerCase());
                });
            });
            setResultRedeStatus(filteredResultRedeStatus)
        }
    };

    const handleInputKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const handleCheckboxChange = (listupdate, id) => {
        if (listupdate === "CiCadastros") {
            const newCheckedItems = { ...checkedCiCadastros, [id]: !checkedCiCadastros[id] };
            setCheckedCiCadastros(newCheckedItems);
        }
    };

    const handleCheckAll = (listupdate) => {
        setSelectAllCiCadastros(!selectAllCiCadastros);
        const newCheckedItems = {};
        if (listupdate === "CiCadastros") {
            resultCiCadastro.forEach((item) => {
                newCheckedItems[item.id] = !selectAllCiCadastros;
            });
            setCheckedCiCadastros(newCheckedItems);
            //console.log(checkedCiCadastros);
        }
    };

    const handleExportExcel = () => {
        // Filtra os itens marcados
        const selectedItems = ciCadastros.filter((item) => checkedCiCadastros[item.id]);
        // Cria um array de dados para a planilha
        const dataForExcel = selectedItems.map((item) => ({
            ID: item.id,
            ID_CI_STATUS: item.id_cistatu,
            ID_CI: item.id_ci,
            LOGRADOURO: item.logradouro,
            N_EDIF: item.n_edificac,
            CIDADE: item.cidade,
            BAIRRO: item.bairro,
            DATA_DADO: item.data_dado,
            ABRAS_TAMPA: item.abras_tamp,
            ESTADO_TAMPA: item.est_tampa,
            ESTADO_PAVIMENTAÇÂO: item.est_pav,
            NIVEL_GREIDE: item.nivel_grei,
            ESTADO_LAJE: item.est_laje,
            ESTADO_ATENÇÃO: item.est_atenca,
            IMG_ACAB_E: item.img_acab_e,
            STATUS_ACESSO: item.stat_acess,
            C_ACAB_EXTERNO: item.c_acab_ext,
            C_ACAB_INTERNA: item.c_acab_int,
            JUNT_EDIF_CI: item.jun_edi_ci,
            JUNT_CI_RAM: item.jun_ci_ram,
            INFILTRAÇÃO: item.infiltra,
            INFIL_PARAS: item.inf_paras,
            STATUS_ESCOAMENTO: item.stat_escoa,
            STATUS_DISP: item.stat_disp,
            IMG_ACAB_I: item.img_acab_i,
            FONT_COMP: item.font_comp,
            FONT_GEOM: item.font_geom,
            ORIGEM_ARQ: item.orig_arq,
            RESOLVIDA: item.resolvida,
            DATA_RESOLUÇÃO: item.data_resol,
            PRIORIDADE: item.prioridade,
            RESPONSAVEL: item.responsave,
        }));
        const ws = XLSX.utils.json_to_sheet(dataForExcel);
        //const wb = XLSX.utils.book_new();
        const wb = XLSX.utils.book_new({ Sheets: { data: ws }, SheetsNames: ["data"] });
        XLSX.utils.book_append_sheet(wb, ws, 'Lista CiStatus');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: "xlsx" })
        FileSaver.saveAs(data, "Selecionados_Ci_Status" + ".xlsx")
    };

    // PAGINAÇÂO
    const itemsPerPage = 10;
    const [currentPageCiCadastro, setCurrentPageCiCadastro] = useState(0);
    const [currentPageCiStatus, setCurrentPageCiStatus] = useState(0);
    const [currentPagePvCadastro, setCurrentPagePvCadastro] = useState(0);
    const [currentPagePvStatus, setCurrentPagePvStatus] = useState(0);
    const [currentPageRedeCadastro, setCurrentPageRedeCadastro] = useState(0);
    const [currentPageRedeStatus, setCurrentPageRedeStatus] = useState(0);

    const handlePageClickCiCadastro = ({ selected }) => { setCurrentPageCiCadastro(selected) };
    const handlePageClickCiStatus = ({ selected }) => { setCurrentPageCiStatus(selected) };
    const handlePageClickPvCadastro = ({ selected }) => { setCurrentPagePvCadastro(selected) };
    const handlePageClickPvStatus = ({ selected }) => { setCurrentPagePvStatus(selected) };
    const handlePageClickRedeCadastro = ({ selected }) => { setCurrentPageRedeCadastro(selected) };
    const handlePageClickRedeStatus = ({ selected }) => { setCurrentPageRedeStatus(selected) };

    const paginatedDataCiCadastro = resultCiCadastro.slice(
        currentPageCiCadastro * itemsPerPage,
        (currentPageCiCadastro + 1) * itemsPerPage
    );

    const paginatedDataCiStatus = resultCiStatus.slice(
        currentPageCiStatus * itemsPerPage,
        (currentPageCiStatus + 1) * itemsPerPage
    );

    const paginatedDataPvCadastro = resultPvCadastro.slice(
        currentPagePvCadastro * itemsPerPage,
        (currentPagePvCadastro + 1) * itemsPerPage
    );

    const paginatedDataPvStatus = resultPvStatus.slice(
        currentPagePvStatus * itemsPerPage,
        (currentPagePvStatus + 1) * itemsPerPage
    );

    const paginatedDataRedeCadastro = resultRedeCadastro.slice(
        currentPageRedeCadastro * itemsPerPage,
        (currentPageRedeCadastro + 1) * itemsPerPage
    );

    const paginatedDataRedeStatus = resultRedeStatus.slice(
        currentPageRedeStatus * itemsPerPage,
        (currentPageRedeStatus + 1) * itemsPerPage
    );

    return (
        <React.Fragment>

            <div className="content">
                <Row className="gy-0" sm={12}>
                    <Col sm={12}>
                        <Row className="justify-content-center">
                            <Col sm={3}>
                                <Input type="text" value={keyword} onChange={handleInputChange} onKeyPress={handleInputKeyPress} />
                            </Col>
                            <Col sm={1}>
                                <Button onClick={handleSearch}>Pesquisar</Button>
                            </Col>

                            <Col sm={3} className="ms-auto">
                                <div className="ms-auto">
                                    <Button className="mt-0 shadow-lg p-2 px-5 ms-auto" color='success' onClick={handleExportExcel}>Exportar para Excel</Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col xl={12}>
                        <div className="table-responsive">

                            {/* Tabela de CI Cadstros */}
                            {resultCiCadastro && resultCiCadastro.length > 0 ? (
                                <>
                                    <h1 className="card-title color_title mt-5">Resultados em Caixas de Inspeção - Cadastro</h1>
                                    <Table className="table-hover align-middle table-nowrap mb-3">
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{ "width": "25px" }}>
                                                    <div className="form-check">
                                                        <Input className="form-check-input" type="checkbox" id="checkAllCiCadastros" onChange={() => handleCheckAll("CiCadastros")} />
                                                    </div>
                                                </th>
                                                {paginatedDataCiCadastro.length > 0 && Object.keys(paginatedDataCiCadastro[0]).map((key) => (
                                                    <th scope="col" key={key}>{key}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paginatedDataCiCadastro.map((item, index) => (
                                                <tr key={index}>
                                                    <th scope="row">
                                                        <div className="form-check">
                                                            <Input className="form-check-input" type="checkbox" id={`inlineCheckbox${item.id}`}
                                                                checked={checkedCiCadastros[item.id] || false} onChange={() => handleCheckboxChange("CiCadastros", item.id)}
                                                            />
                                                        </div>
                                                    </th>
                                                    {Object.keys(item).map((key) => (
                                                        <td key={key}>{item[key]}</td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <ReactPaginate
                                        previousLabel={'Anterior'}
                                        nextLabel={'Próximo'}
                                        breakLabel={'...'}
                                        pageCount={Math.ceil(resultCiCadastro.length / itemsPerPage)}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClickCiCadastro}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                    />
                                </>
                            ) : null}

                            {/* Tabela de CI Status */}
                            {resultCiStatus && resultCiStatus.length > 0 ? (
                                <>
                                    <h1 className="card-title color_title mt-5">Resultados em Caixas de Inspeção - Status</h1>
                                    <Table className="table-hover align-middle table-nowrap mb-3">
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{ "width": "25px" }}>
                                                    <div className="form-check">
                                                        <Input className="form-check-input" type="checkbox" id="checkAll" defaultValue="option1" />
                                                    </div>
                                                </th>
                                                {paginatedDataCiStatus.length > 0 && Object.keys(paginatedDataCiStatus[0]).map((key) => (
                                                    <th scope="col" key={key}>{key}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paginatedDataCiStatus.map((item, index) => (
                                                <tr key={index}>
                                                    <th scope="row">
                                                        <div className="form-check">
                                                            <Input className="form-check-input" type="checkbox" id={`inlineCheckbox${index}`} value="option1" />
                                                        </div>
                                                    </th>
                                                    {Object.keys(item).map((key) => (
                                                        <td key={key}>{item[key]}</td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <ReactPaginate
                                        previousLabel={'Anterior'}
                                        nextLabel={'Próximo'}
                                        breakLabel={'...'}
                                        pageCount={Math.ceil(resultCiStatus.length / itemsPerPage)}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClickCiStatus}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                    />
                                </>
                            ) : null}

                            {/* Tabela de PV Cadstros */}
                            {resultPvCadastro && resultPvCadastro.length > 0 ? (
                                <>
                                    <h1 className="card-title color_title mt-5">Resultados em Poço de Visita - Cadastro</h1>
                                    <Table className="table-hover align-middle table-nowrap mb-3">
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{ "width": "25px" }}>
                                                    <div className="form-check">
                                                        <Input className="form-check-input" type="checkbox" id="checkAll" defaultValue="option1" />
                                                    </div>
                                                </th>
                                                {paginatedDataPvCadastro.length > 0 && Object.keys(paginatedDataPvCadastro[0]).map((key) => (
                                                    <th scope="col" key={key}>{key}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paginatedDataPvCadastro.map((item, index) => (
                                                <tr key={index}>
                                                    <th scope="row">
                                                        <div className="form-check">
                                                            <Input className="form-check-input" type="checkbox" id={`inlineCheckbox${index}`} value="option1" />
                                                        </div>
                                                    </th>
                                                    {Object.keys(item).map((key) => (
                                                        <td key={key}>{item[key]}</td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <ReactPaginate
                                        previousLabel={'Anterior'}
                                        nextLabel={'Próximo'}
                                        breakLabel={'...'}
                                        pageCount={Math.ceil(resultPvCadastro.length / itemsPerPage)}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClickPvCadastro}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                    />
                                </>
                            ) : null}

                            {/* Tabela de PV Status */}
                            {resultPvStatus && resultPvStatus.length > 0 ? (
                                <>
                                    <h1 className="card-title color_title mt-5">Resultados em Poço de Visita - Status</h1>
                                    <Table className="table-hover align-middle table-nowrap mb-3">
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{ "width": "25px" }}>
                                                    <div className="form-check">
                                                        <Input className="form-check-input" type="checkbox" id="checkAll" defaultValue="option1" />
                                                    </div>
                                                </th>
                                                {paginatedDataPvStatus.length > 0 && Object.keys(paginatedDataPvStatus[0]).map((key) => (
                                                    <th scope="col" key={key}>{key}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paginatedDataPvStatus.map((item, index) => (
                                                <tr key={index}>
                                                    <th scope="row">
                                                        <div className="form-check">
                                                            <Input className="form-check-input" type="checkbox" id={`inlineCheckbox${index}`} value="option1" />
                                                        </div>
                                                    </th>
                                                    {Object.keys(item).map((key) => (
                                                        <td key={key}>{item[key]}</td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <ReactPaginate
                                        previousLabel={'Anterior'}
                                        nextLabel={'Próximo'}
                                        breakLabel={'...'}
                                        pageCount={Math.ceil(resultPvStatus.length / itemsPerPage)}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClickPvStatus}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                    />
                                </>
                            ) : null}

                            {/* Tabela de REDE Cadstros */}
                            {resultRedeCadastro && resultRedeCadastro.length > 0 ? (
                                <>
                                    <h1 className="card-title color_title mt-5">Resultados em Rede - Cadastro</h1>
                                    <Table className="table-hover align-middle table-nowrap mb-3">
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{ "width": "25px" }}>
                                                    <div className="form-check">
                                                        <Input className="form-check-input" type="checkbox" id="checkAll" defaultValue="option1" />
                                                    </div>
                                                </th>
                                                {paginatedDataRedeCadastro.length > 0 && Object.keys(paginatedDataRedeCadastro[0]).map((key) => (
                                                    <th scope="col" key={key}>{key}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paginatedDataRedeCadastro.map((item, index) => (
                                                <tr key={index}>
                                                    <th scope="row">
                                                        <div className="form-check">
                                                            <Input className="form-check-input" type="checkbox" id={`inlineCheckbox${index}`} value="option1" />
                                                        </div>
                                                    </th>
                                                    {Object.keys(item).map((key) => (
                                                        <td key={key}>{item[key]}</td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <ReactPaginate
                                        previousLabel={'Anterior'}
                                        nextLabel={'Próximo'}
                                        breakLabel={'...'}
                                        pageCount={Math.ceil(resultRedeCadastro.length / itemsPerPage)}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClickRedeCadastro}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                    />
                                </>
                            ) : null}

                            {/* Tabela de REDE Status */}
                            {resultRedeStatus && resultRedeStatus.length > 0 ? (
                                <>
                                    <h1 className="card-title color_title mt-5">Resultados em Rede - Status</h1>
                                    <Table className="table-hover align-middle table-nowrap mb-3">
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{ "width": "25px" }}>
                                                    <div className="form-check">
                                                        <Input className="form-check-input" type="checkbox" id="checkAll" defaultValue="option1" />
                                                    </div>
                                                </th>
                                                {paginatedDataRedeStatus.length > 0 && Object.keys(paginatedDataRedeStatus[0]).map((key) => (
                                                    <th scope="col" key={key}>{key}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paginatedDataRedeStatus.map((item, index) => (
                                                <tr key={index}>
                                                    <th scope="row">
                                                        <div className="form-check">
                                                            <Input className="form-check-input" type="checkbox" id={`inlineCheckbox${index}`} value="option1" />
                                                        </div>
                                                    </th>
                                                    {Object.keys(item).map((key) => (
                                                        <td key={key}>{item[key]}</td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <ReactPaginate
                                        previousLabel={'Anterior'}
                                        nextLabel={'Próximo'}
                                        breakLabel={'...'}
                                        pageCount={Math.ceil(resultRedeStatus.length / itemsPerPage)}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClickRedeStatus}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                    />
                                </>
                            ) : null}

                        </div>
                    </Col>
                </Row>

            </div>

        </React.Fragment>
    );
};

export default SearchOption;