import React from 'react';
import IframeWithLoading from "../IframeWithLoading"


const DashboardCiCadastro = () => {
    document.title = "Dashboard Ci - Cadastro | SANAPP";

    return (
        <React.Fragment>
            <IframeWithLoading
                src="https://metabase.apisanapp.com.br/public/dashboard/0581b93e-293b-407c-9248-292d48dd623c"
                title="Ci - Cadastro"
                width="100%"
                height="1410"
            />
        </React.Fragment>
    );
};

export default DashboardCiCadastro;