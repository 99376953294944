import {
  GET_REDE_STATUS,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_NEW_REDE_STATUS,
  ADD_REDE_STATUS_SUCCESS,
  ADD_REDE_STATUS_FAIL,
  UPDATE_REDE_STATUS,
  UPDATE_REDE_STATUS_SUCCESS,
  UPDATE_REDE_STATUS_FAIL,
  DELETE_REDE_STATUS,
  DELETE_REDE_STATUS_SUCCESS,
  DELETE_REDE_STATUS_FAIL,
} from "./actionType";

// common success
export const RedeStatusApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const RedeStatusApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getRedeStatus = () => ({
  type: GET_REDE_STATUS,
});

export const updateRedeStatus = redestatus => ({
  type: UPDATE_REDE_STATUS,
  payload: redestatus,
});

export const updateRedeStatusSuccess = redestatus => ({
  type: UPDATE_REDE_STATUS_SUCCESS,
  payload: redestatus,
});

export const updateRedeStatusFail = error => ({
  type: UPDATE_REDE_STATUS_FAIL,
  payload: error,
});

export const addNewRedeStatus = redestatus => ({
  type: ADD_NEW_REDE_STATUS,
  payload: redestatus,
});

export const addRedeStatusSuccess = redestatus => ({
  type: ADD_REDE_STATUS_SUCCESS,
  payload: redestatus,
});

export const addRedeStatusFail = error => ({
  type: ADD_REDE_STATUS_FAIL,
  payload: error,
});
export const deleteRedeStatus = redestatus => ({
  type: DELETE_REDE_STATUS,
  payload: redestatus,
});

export const deleteRedeStatusSuccess = redestatus => ({
  type: DELETE_REDE_STATUS_SUCCESS,
  payload: redestatus,
});

export const deleteRedeStatusFail = error => ({
  type: DELETE_REDE_STATUS_FAIL,
  payload: error,
});