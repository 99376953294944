// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_PV_STATUS = "GET_PV_STATUS";

/**
* Add PV_STATUS
*/
export const ADD_NEW_PV_STATUS = "ADD_NEW_PV_STATUS";
export const ADD_PV_STATUS_SUCCESS = "ADD_PV_STATUS_SUCCESS";
export const ADD_PV_STATUS_FAIL = "ADD_PV_STATUS_FAIL";

/**
 * Edit PV_STATUS
 */
export const UPDATE_PV_STATUS = "UPDATE_PV_STATUS";
export const UPDATE_PV_STATUS_SUCCESS = "UPDATE_PV_STATUS_SUCCESS";
export const UPDATE_PV_STATUS_FAIL = "UPDATE_PV_STATUS_FAIL";

/**
 * Delete PV_STATUS
 */
export const DELETE_PV_STATUS = "DELETE_PV_STATUS";
export const DELETE_PV_STATUS_SUCCESS = "DELETE_PV_STATUS_SUCCESS";
export const DELETE_PV_STATUS_FAIL = "DELETE_PV_STATUS_FAIL";