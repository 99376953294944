import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// REDE_CADASTROS Redux States
import {
    GET_REDE_CADASTROS,
    ADD_NEW_REDE_CADASTROS,
    DELETE_REDE_CADASTROS,
    UPDATE_REDE_CADASTROS,
} from "./actionType";
import {
    RedeCadastrosApiResponseSuccess, RedeCadastrosApiResponseError,
    addRedeCadastrosSuccess,
    addRedeCadastrosFail,
    updateRedeCadastrosSuccess,
    updateRedeCadastrosFail,
    deleteRedeCadastrosSuccess,
    deleteRedeCadastrosFail,
} from "./action";

//Include Both Helper File with needed methods
import {
    getRedeCadastros as getRedeCadastrosApi,
    addNewRedeCadastros,
    updateRedeCadastros,
    deleteRedeCadastros,
} from "../../helpers/backend_helper";

function* getRedeCadastros({ payload: redecadastros }) {
    try {
        let allResults = [];
        let response = yield call(getRedeCadastrosApi, redecadastros);
        allResults = allResults.concat(response.results);
        while (response.next){
            const nextPage = response.next.split('=').pop();
            response = yield call(getRedeCadastrosApi, { ...redecadastros, page: nextPage });
            allResults = allResults.concat(response.results);
            //console.log(allResults)
        }
        yield put(RedeCadastrosApiResponseSuccess(GET_REDE_CADASTROS, allResults));
    } catch (error) {
        //console.log(error);
        yield put(RedeCadastrosApiResponseError(GET_REDE_CADASTROS, error));
    }
}

function* onAddNewRedeCadastros({ payload: redecadastros }) {
    try {
        const response = yield call(addNewRedeCadastros, redecadastros);
        yield put(addRedeCadastrosSuccess(response));
        toast.success("RedeCadastros Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(addRedeCadastrosFail(error));
        toast.error("RedeCadastros Added Failed", { autoClose: 3000 });
    }
}

function* onDeleteRedeCadastros({ payload: redecadastros }) {
    try {
        const response = yield call(deleteRedeCadastros, redecadastros);
        yield put(deleteRedeCadastrosSuccess({ redecadastros, ...response }));
        toast.success("RedeCadastros Delete Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(deleteRedeCadastrosFail(error));
        toast.error("RedeCadastros Delete Failed", { autoClose: 3000 });
    }
}

function* onUpdateRedeCadastros({ payload: redecadastros }) {
    try {
        const response = yield call(updateRedeCadastros, redecadastros);
        yield put(updateRedeCadastrosSuccess(response));
        toast.success("RedeCadastros Updated Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(updateRedeCadastrosFail(error));
        toast.error("RedeCadastros Updated Failed", { autoClose: 3000 });
    }
}

export function* watchGetRedeCadastros() {
    yield takeEvery(GET_REDE_CADASTROS, getRedeCadastros);
}

export function* watchAddNewRedeCadastros() {
    yield takeEvery(ADD_NEW_REDE_CADASTROS, onAddNewRedeCadastros);
}

export function* watchUpdateRedeCadastros() {
    yield takeEvery(UPDATE_REDE_CADASTROS, onUpdateRedeCadastros);
}

export function* watchDeleteRedeCadastros() {
    yield takeEvery(DELETE_REDE_CADASTROS, onDeleteRedeCadastros);
}

function* redecadastrosSaga() {
    yield all([
        fork(watchGetRedeCadastros),
        fork(watchAddNewRedeCadastros),
        fork(watchUpdateRedeCadastros),
        fork(watchDeleteRedeCadastros)
    ]
    );
}

export default redecadastrosSaga;
