import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

//import images
import avatar1 from "../../Assets/images/users/perfil-de-avatar-homem.avif";

const ProfileDropdown = () => {
    const [userName, setUserName] = useState("Usuário");
    const [userPrivilegio, setUserPrivilegio] = useState("Visitante");
    useEffect(() => {
        if (sessionStorage.getItem("userdata")) {
            const userdata = { ...JSON.parse(sessionStorage.getItem("userdata")) };
            setUserName(userdata?.username.toUpperCase() || "Usuário");
            setUserPrivilegio(userdata?.company || "Visitante");
            //console.log(userdata);
        }
    }, [userName, userPrivilegio]);

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user" src={avatar1}
                            alt="Header Avatar" />
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">

                    <span className="text-start ms-xl-2">
                        <h6 className="dropdown-header">Bem Vindo {userName}!</h6>
                    </span>



                    <DropdownItem href="/profile"><i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                        <span className="align-middle">Perfil</span></DropdownItem>

                    <DropdownItem href="/"><i
                        className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Mensagens</span></DropdownItem>

                    <DropdownItem href="/"><span
                        className="badge bg-soft-success text-success mt-1 float-end">Novo</span><i
                            className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i> <span
                                className="align-middle">Tarefas</span></DropdownItem>

                    <DropdownItem href="/"><i
                        className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Ajuda</span></DropdownItem>

                    <div className="dropdown-divider"></div>
                    <DropdownItem href="/"><i
                        className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Configurações</span></DropdownItem>

                    <DropdownItem href="/auth-lockscreen"><i
                        className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Bloquear</span></DropdownItem>

                    <DropdownItem href="/logout"><i
                        className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle" data-key="t-logout">Sair</span></DropdownItem>

                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;