//REGISTER
export const POST_REGISTER = "/auth/signup";

//LOGIN
export const POST_JWT_LOGIN = "/api/token/";
export const POST_LOGIN = "/auth/signin";
export const POST_PASSWORD_FORGET = "/auth/forgot-password";
export const POST_JWT_PASSWORD_FORGET = "/password-reset/";
export const POST_JWT_NEW_PASSWORD = "/password-reset-confirm/";
export const POST_NEW_PASSWORD = "/password-reset-confirm/";
export const SOCIAL_LOGIN = "/social-login";


//CICADASTROS
export const CI_CADASTROS = "/cicadastroesgoto/";
export const CI_CADASTROSFILTER = "/cicadastroesgoto/";
export const ADD_NEW_CI_CADASTROS = "/cicadastroesgoto/";
export const UPDATE_CI_CADASTROS = "/cicadastroesgoto/";
export const DELETE_CI_CADASTROS = "/cicadastroesgoto/";

// CISTATUS
export const CI_STATUS = "/cistatusesgoto/";
export const CI_STATUSFILTER = "/cistatusesgoto/";
export const ADD_CI_STATUS = "/cistatusesgoto/";
export const UPDATE_CI_STATUS = "/cistatusesgoto/";
export const DELETE_CI_STATUS = "/cistatusesgoto/";

// CI_SERVICES
export const CI_SERVICES = "/ciservicoesgoto/";
export const CI_SERVICESFILTER = "/ciservicoesgoto/";
export const ADD_CI_SERVICES = "/ciservicoesgoto/";
export const UPDATE_CI_SERVICES = "/ciservicoesgoto/";
export const DELETE_CI_SERVICES = "/ciservicoesgoto/";


//PVCADASTROS
export const PV_CADASTROS = "/pvcadastroesgoto/";
export const ADD_NEW_PV_CADASTROS = "/pvcadastroesgoto/";
export const UPDATE_PV_CADASTROS = "/pvcadastroesgoto/";
export const DELETE_PV_CADASTROS = "/pvcadastroesgoto/";

// PVSTATUS
export const PV_STATUS = "/pvstatusesgoto/";
export const ADD_PV_STATUS = "/pvstatusesgoto/";
export const UPDATE_PV_STATUS = "/pvstatusesgoto/";
export const DELETE_PV_STATUS = "/pvstatusesgoto/";

// PV_SERVICES
export const PV_SERVICES = "/pvservicoesgoto/";
export const PV_SERVICESFILTER = "/pvservicoesgoto/";
export const ADD_PV_SERVICES = "/pvservicoesgoto/";
export const UPDATE_PV_SERVICES = "/pvservicoesgoto/";
export const DELETE_PV_SERVICES = "/pvservicoesgoto/";


//REDECADASTROS
export const REDE_CADASTROS = "/redecadastroesgoto/";
export const ADD_NEW_REDE_CADASTROS = "/redecadastroesgoto/";
export const UPDATE_REDE_CADASTROS = "/redecadastroesgoto/";
export const DELETE_REDE_CADASTROS = "/redecadastroesgoto/";

// REDESTATUS
export const REDE_STATUS = "/redestatusesgoto/";
export const ADD_REDE_STATUS = "/redestatusesgoto/";
export const UPDATE_REDE_STATUS = "/redestatusesgoto/";
export const DELETE_REDE_STATUS = "/redestatusesgoto/";

// REDE_SERVICES
export const REDE_SERVICES = "/redeservicoesgoto/";
export const ADD_REDE_SERVICES = "/redeservicoesgoto/";
export const UPDATE_REDE_SERVICES = "/redeservicoesgoto/";
export const DELETE_REDE_SERVICES = "/redeservicoesgoto/";

// REDE_INSPECAO
export const REDE_INSPECAO = "/redeinspecaoesgoto/";
export const ADD_REDE_INSPECAO = "/redeinspecaoesgoto/";
export const UPDATE_REDE_INSPECAO = "/redeinspecaoesgoto/";
export const DELETE_REDE_INSPECAO = "/redeinspecaoesgoto/";


// EST_ELEV_CAD
export const EST_ELEV_CAD = "/estelevcadastroesgoto/";
export const ADD_EST_ELEV_CAD = "/estelevcadastroesgoto/";
export const UPDATE_EST_ELEV_CAD = "/estelevcadastroesgoto/";
export const DELETE_EST_ELEV_CAD = "/estelevcadastroesgoto/";

// EST_ELEV_STATUS
export const EST_ELEV_STATUS = "/estelevstatusesgoto/";
export const ADD_EST_ELEV_STATUS = "/estelevstatusesgoto/";
export const UPDATE_EST_ELEV_STATUS = "/estelevstatusesgoto/";
export const DELETE_EST_ELEV_STATUS = "/estelevstatusesgoto/";

// EST_ELEV_SERVICES
export const EST_ELEV_SERVICES = "/estelevservicoesgoto/";
export const ADD_EST_ELEV_SERVICES = "/estelevservicoesgoto/";
export const UPDATE_EST_ELEV_SERVICES = "/estelevservicoesgoto/";
export const DELETE_EST_ELEV_SERVICES = "/estelevservicoesgoto/";

// FOSSA_CAD
export const FOSSA_CAD = "/fossacadastroesgoto/";
export const ADD_FOSSA_CAD = "/fossacadastroesgoto/";
export const UPDATE_FOSSA_CAD = "/fossacadastroesgoto/";
export const DELETE_FOSSA_CAD = "/fossacadastroesgoto/";

// FOSSA_STATUS
export const FOSSA_STATUS = "/fossastatusesgoto/";
export const ADD_FOSSA_STATUS = "/fossastatusesgoto/";
export const UPDATE_FOSSA_STATUS = "/fossastatusesgoto/";
export const DELETE_FOSSA_STATUS = "/fossastatusesgoto/";

// NOTIFICATIONS
export const NOTIFICATIONS = "/notifications/";
export const ADD_NOTIFICATIONS = "/notifications/";
export const UPDATE_NOTIFICATIONS = "/notifications/";
export const DELETE_NOTIFICATIONS = "/notifications/";

// APONTAMENTOS
export const APONTAMENTOS = "/apontamentos/";
export const ADD_APONTAMENTOS = "/apontamentos/";
export const UPDATE_APONTAMENTOS = "/apontamentos/";
export const DELETE_APONTAMENTOS = "/apontamentos/";

// CAMGEO
export const CAMGEO = "/camadasgeo/";
export const ADD_CAMGEO = "/camadasgeo/";
export const UPDATE_CAMGEO = "/camadasgeo/";
export const DELETE_CAMGEO = "/camadasgeo/";

// ORDEM DE SERVIÇO
export const ORDEM_SERVICO = "/orderm-servico/";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/user";
